import '../utils/style/index.scss'
import '../utils/style/_utils.scss'
import {
    FaWindows,
    FaFacebook,
    FaTwitter,
    FaGooglePlus,
    FaLinkedin,
    FaYoutube,
    FaHotel,
    FaGraduationCap,
    FaHardHat,
    FaUserGraduate,
    FaBookReader,
} from 'react-icons/fa'
import {BsPersonCircle} from 'react-icons/bs'
import { MdWork } from 'react-icons/md'
import { Col, Row } from 'react-bootstrap'
import A2GP1 from '../../src/assets/Epreuves/A2GP/Mathematiques 1.pdf'
import A2GP2 from '../../src/assets/Epreuves/A2GP/Mathematiques 2.pdf'
import A2GP3 from '../../src/assets/Epreuves/A2GP/Informatique 1 et 2.pdf'
import A2GP4 from '../../src/assets/Epreuves/A2GP/Français et Philosophie.pdf'
import A2GP5 from '../../src/assets/Epreuves/A2GP/Physique Chimie 1.pdf'
import A2GP6 from '../../src/assets/Epreuves/A2GP/Physique Chimie 2.pdf'
import A2GP7 from '../../src/assets/Epreuves/A2GP/Biologie1.pdf'
import A2GP8 from '../../src/assets/Epreuves/A2GP/Biologie2.pdf'
import GIC1 from '../../src/assets/Epreuves/GIC/MP-ProgrammemathMP.pdf'
import GIC2 from '../../src/assets/Epreuves/GIC/MPSI-Programme de mathematiques.pdf'
import GIC3 from '../../src/assets/Epreuves/GIC/MPSI&MP-informatique_tronc_commun.pdf'
import GIC4 from '../../src/assets/Epreuves/GIC/MPSI_physique-chimie.pdf'
import GIC4b from '../../src/assets/Epreuves/GIC/MP-Programme Physique-Chimie.pdf'

import GIC5 from '../../src/assets/Epreuves/GIC/MPSI-ProgrammedeSciencesIndustrielles.pdf'
import GIC6 from '../../src/assets/Epreuves/GIC/MP-ProgrammedeSciencesIndustrielles.pdf'
import CAE1 from '../../src/assets/Epreuves/CAE/ProgrammedeMathématiquesappliquéesECG1.pdf'
import CAE2 from '../../src/assets/Epreuves/CAE/ProgrammedeMathématiquesapprofondiesECG1.pdf'
import CAE3 from '../../src/assets/Epreuves/CAE/ProgrrammedeMathematiquesappliqueesECG2.pdf'
import CAE4 from '../../src/assets/Epreuves/CAE/ProgrrammedeMathematiquesapprofondiesECG2.pdf'
import CAE5 from '../../src/assets/Epreuves/CAE/ProgrammedelettresetphilosophieECG1.pdf'
import CAE6 from '../../src/assets/Epreuves/CAE/ProgrammedelettresetphilosophieECG2.pdf'
import CAE7 from '../../src/assets/Epreuves/CAE/Programme_d_economieSociologieetHistoiredumondecontemporainECG1&2.pdf'
import CAE8 from '../../src/assets/Epreuves/CAE/ProgrammeProgrammesdhistoiregéographieECG1.pdf'
import CAE9 from '../../src/assets/Epreuves/CAE/ProgrammeProgrammesdhistoiregeographieECG2.pdf'
import CAE10 from '../../src/assets/Epreuves/CAE/ProgrammeInformatiqueECG1.pdf'
import CAE11 from '../../src/assets/Epreuves/CAE/ProgrammeInformatiqueECG2.pdf'
import ANG from '../../src/assets/Epreuves/CAE/Programmeanglais.pdf'



function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const Concours = importAll(
    require.context('../assets/Concours', false, /\.(mp4|png|jpe?g|svg)$/)
)
const Placeholder = importAll(
    require.context('../assets/Placeholder', false, /\.(png|jpe?g|svg)$/)
)
const portail = importAll(
    require.context('../assets/Portail', false, /\.(png|jpe?g|svg)$/)
)
const Ecoles = importAll(
    require.context('../assets/Ecoles', false, /\.(png|jpe?g|svg|pdf)$/)
)
const Cycle = importAll(
    require.context('../assets/Ecoles/cycle', false, /\.(png|jpe?g|svg)$/)
)
const Debouches = importAll(
    require.context('../assets/Ecoles/debouches', false, /\.(png|jpe?g|svg)$/)
)
const Directeur = importAll(
    require.context('../assets/Ecoles/directeur', false, /\.(png|jpe?g|svg)$/)
)
const Logo = importAll(
    require.context('../assets/Ecoles/logo', false, /\.(png|jpe?g|svg)$/)
)
const esmg = importAll(
    require.context(`../assets/ESMG`, false, /\.(png|jpe?g|svg)$/)
)
const partenairesESCAE = importAll(
    require.context('../assets/Ecoles/PartenairesESCAE', false, /\.(png|jpe?g|svg)$/)
)
const partenairesESA = importAll(
    require.context('../assets/Ecoles/PartenairesESA', false, /\.(png|jpe?g|svg)$/)
)

const partenairesCPGE = importAll(
    require.context('../assets/Ecoles/PartenairesCPGE', false, /\.(png|jpe?g|svg)$/)
)
const partenairesESI = importAll(
    require.context('../assets/Ecoles/PartenairesESI', false, /\.(png|jpe?g|svg)$/)
)
const partenairesESMG = importAll(
    require.context('../assets/Ecoles/partenairesESMG', false, /\.(png|jpe?g|svg)$/)
)
const partenairesESPE = importAll(
    require.context('../assets/Ecoles/PartenairesESPE', false, /\.(png|jpe?g|svg)$/)
)
const partenairesESTP = importAll(
    require.context('../assets/Ecoles/PartenairesESTP', false, /\.(png|jpe?g|svg)$/)
)

// const S2013 = importAll(
//     require.context('../assets/Epreuves/2013', false, /\.(pdf)$/)
// )
// const S2014 = importAll(
//     require.context('../assets/Epreuves/2014', false, /\.(pdf)$/)
// )
// const S2015 = importAll(
//     require.context('../assets/Epreuves/2015', false, /\.(pdf)$/)
// )
// const S2016 = importAll(
//     require.context('../assets/Epreuves/2016', false, /\.(pdf)$/)
// )
// const S2017 = importAll(
//     require.context('../assets/Epreuves/2017', false, /\.(pdf)$/)
// )
// const S2018 = importAll(
//     require.context('../assets/Epreuves/2018', false, /\.(pdf)$/)
// )
const S2019 = importAll(
    require.context('../assets/Epreuves/2019', false, /\.(pdf)$/)
)
const S2022 = importAll(
    require.context('../assets/Epreuves/2022', false, /\.(pdf)$/)
)
const S2022B = importAll(
    require.context('../assets/Epreuves/2022B', false, /\.(pdf)$/)
)

export const Anglais = [
    {
        page: 'portal',
        accesportail: 'Access main portal',
        accespicture: Placeholder['portail1.png'],
        visite: 'Visit our schools',
        visitepicture: Placeholder['ecoles1.png'],
        voir: 'Study at INP-HB',
        voirpicture: Placeholder['concours1.png'],
        research: 'Research innovation',
        researchpicture: Placeholder['recherche1.png'],
    },
]
export const France = [
    {
        page: 'portail',
        accesportail: 'Accéder au portail',
        accespicture: Placeholder['portail.png'],
        visite: 'Visiter nos écoles',
        visitepicture: Placeholder['ecoles.png'],
        voir: "Concours INP-HB",
        voirpicture: Placeholder['concours.png'],
        research: 'innovations',
        researchpicture: Placeholder['recherche.png'],
    },
]
export const Arabe = [
    {
        page: 'بوابة',
        accesportail: 'الوصول إلى البوابة',
        accespicture: Placeholder['portail2.png'],
        visite: 'قم بزيارة مدارسنا',
        visitepicture: Placeholder['ecoles2.png'],
        voir: 'ادرس هنا',
        voirpicture: Placeholder['concours2.png'],
        research: 'البحث والابتكار',
        researchpicture: Placeholder['recherche2.png'],
    },
]
export const Icons = [
    // {
    //     title: 'Espace étudiant',
    //     icon: <BsPersonCircle />,
    //     link: 'https://etudiant.inphb.app',
        
    // },
    {
        title: 'Windows',
        icon: <FaWindows />,
        link: 'https://login.microsoftonline.com/',
    },
    {
        title: 'Facebook',
        icon: <FaFacebook />,
        link: 'https://www.facebook.com/inphb.polytech/',
    },
    {
        title: 'Twitter',
        icon: <FaTwitter />,
        link: 'https://twitter.com/inphbpolytech',
    },
    // {
    //     title: 'Google+',
    //     icon: <FaGooglePlus />,
    //     link: 'https://plus.google.com/u/0/b/116610803549675586570/?pageId=116610803549675586570',
    // },
    {
        title: 'LinkedIn',
        icon: <FaLinkedin />,
        link: 'https://www.linkedin.com/company/institut-national-polytechnique-f%C3%A9lix-houphou%C3%ABt-boigny',
    },
    {
        title: 'Youtube',
        icon: <FaYoutube />,
        link: 'https://www.youtube.com/channel/UCVDiYsYzaLxTgn8JZQ7vkyg',
    },
    { title: 'GESAPED', icon: <FaHotel />, link: 'https://gesaped.inphb.app/' },
]

export const Interets = [
    {
        icon: <FaUserGraduate />,
        text: 'CONCOURS BACHELIER',
        link: 'concours/bachelier',
    },
    {
        icon: <FaHardHat />,
        text: 'CONCOURS INGÉNIEUR',
        link: 'concours/ingenieur',
    },
    {
        icon: <MdWork />,
        text: 'MASTER PROFESSIONNEL',
        link: 'concours/master_professionnel',
    },
    {
        icon: <FaBookReader />,
        text: 'FORMATION CONTINUE',
        link: '',
    },
    {
        icon: <FaGraduationCap />,
        text: 'ECOLE DOCTORALE',
        link: '',
    },
]

export const ecoles = [
    {
        logo: Logo['CPGE.png'],
        image: Ecoles['CPGEB.jpg'],
        alt: 'CPGE',
        nom: 'CLASSES PRÉPARATOIRES AUX GRANDES ÉCOLES',
        couleur: '#096678',
        link: 'https://google.com',
        // lien: 'cpge',
        aos: 'flip-left',
    },
    {
        logo: Logo['ESCAE.png'],
        image: Ecoles['ESCAEB.jpg'],
        alt: 'ESCAE',
        nom: "ECOLE SUPERIEURE DU COMMERCE ET D'ADMINISTRATION DES ENTREPRISES",
        couleur: '#234d9d',
        lien: 'escae',
        aos: 'flip-down',
    },
    {
        logo: Logo['ESA.png'],
        image: Ecoles['ESAB.jpg'],
        alt: 'ESA',
        nom: "ECOLE SUPERIEURE D'AGRONOMIE",
        couleur: '#00a651',
        lien: 'esa',
        aos: 'flip-up',
    },
    {
        logo: Logo['ESI.png'],
        image: Ecoles['ESIB.jpg'],
        alt: 'ESI',
        nom: "ECOLE SUPERIEURE DE L'INDUSTRIE",
        couleur: '#74294b',
        lien: 'esi',
        aos: 'fade-right',
    },
    {
        logo: Logo['ESTP.png'],
        image: Ecoles['ESTPB.jpg'],
        alt: 'ESTP',
        nom: 'ECOLE SUPERIEURE DES TRAVAUX PUBLICS',
        couleur: '#612c85',
        lien: 'estp',
        aos: 'fade-up',
    },
    {
        logo: Logo['ESMG.png'],
        image: Ecoles['ESMGB.jpg'],
        alt: 'ESMG',
        nom: 'ECOLE SUPERIEURE DE MINE ET GÉOLOGIE',
        couleur: '#cc580a',
        lien: 'esmg',
        aos: 'fade-up',
    },
    {
        logo: Logo['ESPE.png'],
        image: Ecoles['ESPEB.jpg'],
        alt: 'ESPE',
        nom: "ECOLE SUPERIEURE DU PÉTROLE ET DE L'ÉNERGIE",
        couleur: '#1d4851',
        lien: 'espe',
        aos: 'fade-left',
    }, 
    {
        logo: Logo['EDP.png'],
        image: Ecoles['EDPB.jpg'],
        alt: 'EDP',
        nom: 'ECOLE DOCTORALE POLYTECHNIQUE',
        couleur: '#f7a600',
        lien: 'edp',
        aos: 'fade-up',
    },
    {
        logo: Logo['EFCPC.png'],
        image: Ecoles['EFCPCB.jpg'],
        alt: 'EFCPC',
        nom: 'ECOLE DE FORMATION CONTINUE ET DE PERFECTIONNEMENT DES CADRES',
        couleur: '#bf0538',
        lien: 'https://efcpc.inphb.ci',
        aos: 'fade-up',
    },
]

export const cycleLong = [
    {
        id: 'MP',
        name: 'Mathématique Physique et Sciences Industrielles',
        picture: Concours['MP.jpg'],
    },
    {
        id: 'ECS',
        name: 'Économie et Commerce option Scientifique',
        picture: Concours['ECS.jpg'],
    },
    {
        id: 'BCPST',
        name: 'Biologie Chimie Physique - Science de la Terre',
        picture: Concours['BCPST.jpg'],
    },
]
export const cycleCourt = [
    {
        id: 'ESCAE',
        name: "Ecole Supérieure de Commerce et d'Administration des Entreprises",
        picture: Concours['ESCAE.jpg'],
    },
    {
        id: 'ESA',
        name: "Ecole Supérieure d'Agronomie",
        picture: Concours['ESA.jpg'],
    },
    {
        id: 'ESI',
        name: "Ecole Supérieure d'Industrie",
        picture: Concours['ESI.jpg'],
    },
    {
        id: 'ESTP',
        name: 'Ecole Supérieure des Travaux Publics',
        picture: Concours['ESTP.jpg'],
    },
    {
        id: 'ESMG',
        name: 'Ecole Supérieure de Mine et Géologie',
        picture: Concours['ESMG.jpg'],
    },
    {
        id: 'ESPE',
        name: "Ecole Supérieure du Pétrole et de l'Énergie",
        picture: Concours['ESPE.jpg'],
    },
]

export const filiereIngenieur = {
    ESCAE: [
        {
            filiere: 'ÉTUDES COMPTABLES SUPÉRIEURES',
            sigle: 'ECS',
            introduction: '',
            volume: [
                {
                    title: 'Objectif',
                    contenu: [
                        {
                            subtitle: '',
                            subtitleText: (
                                <p>
                                    Exerçant à 90 % en libéral, les comptables
                                    sont des professionnels polyvalents, soumis
                                    à la rigoureuse déontologie de l'Ordre des
                                    comptables.
                                    <br /> Leurs missions vont de l'audit à
                                    l'établissement des comptes des entreprises
                                    et la consolidation des comptes annuels, en
                                    passant par un rôle de conseil et
                                    d'assistance. <br />
                                    La comptabilité générale enregistre, classe
                                    et regroupe les informations relatives aux
                                    mouvements de valeurs ayant pour origine les
                                    activités de l'entreprise. Il s'agit avant
                                    tout d'un instrument de mesure : celui des
                                    résultats de l'entreprise sur un exercice
                                    (une période donnée qui est le plus souvent
                                    l'année) ; <br /> celui de la situation
                                    patrimoniale à un instant donné (quels sont
                                    les avoirs de l'entreprise, à combien
                                    s'élèvent ses dettes, etc.). <br />
                                    <br />
                                    Il existe des « principes comptables
                                    généralement admis » qui sont les suivants :{' '}
                                    <br />
                                    • la continuité de l'exploitation
                                    (l'entreprise continuera dans l'avenir) ;
                                    <br />
                                    • la permanence des méthodes (afin de
                                    permettre des comparaisons), la prudence
                                    (prise en compte des risques de pertes mais
                                    pas de gains, afin de protéger l'épargnant)
                                    ;<br />
                                    • l'indépendance ou la spécialisation des
                                    exercices ;<br />
                                    • la non-compensation entre différents
                                    postes ;<br />
                                    • le coût historique, l'entité et la
                                    sincérité.
                                    <br />
                                    <br />A partir de ces principes, on élabore
                                    des documents comptables. La première
                                    manœuvre consiste à récolter les données. Ce
                                    n'est pas toujours le plus simple et cette
                                    partie exige une grande rigueur. Chèques,
                                    factures qu'il faut obtenir des salariés en
                                    déplacements, traites avec les différents
                                    fournisseurs, bulletins de paie... : les
                                    pièces à collecter sont nombreuses. Une fois
                                    les documents recueillis, la seconde étape
                                    consiste à enregistrer ces données
                                    numériques dans le « journal » ou le « grand
                                    livre ». Enfin, les comptes annuels de
                                    synthèse s'effectuent par regroupement,
                                    classement et présentation des données.
                                    Langue favorisant la communication, la
                                    comptabilité détient très peu de signes à
                                    son actif et la réalité de l'activité de
                                    l'entreprise peut difficilement être
                                    appréhendée par son seul biais. La nature et
                                    la qualité des produits, les techniques
                                    (matérielles ou humaines) employées pour
                                    produire, les relations salariales qui
                                    conditionnent les performances futures, etc.
                                    : autant d'aspects qui ne sont pas
                                    réductibles à des données comptables{' '}
                                </p>
                            ),
                        },
                    ],
                },
                {
                    title: 'Formation',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 116 H',
                            subtitleText:
                                'Anglais ; Méthodologie de la recherche ; EPS.',
                        },
                        {
                            subtitle: 'Mathématique, informatique 314 H',
                            subtitleText:
                                'Mathématique appliquée ; Recherche opérationnelle ; Utilisation de logiciel ; Statistique-Probabilité ; Informatique.',
                        },
                        {
                            subtitle: 'Finance, comptabilité, droit 1460 H',
                            subtitleText: `Finance, comptabilité, droit 1460 H
                                Droit du travail ; Anglais financier ; Droit des sociétés ; Procédure ; Comptabilité des sociétés ; Droit de crédit ; Droit pénal des affaires ; Fiscalité ; Techniques comptables approfondies ; Contentieux et expertise ; Finance ; Comptabilité analytique ; Révision/audit ; Contrôle de gestion ; Finance d'entreprise ; Relations juridiques.`,
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 480 H',
                            subtitleText:
                                'Marketing ; Finance des marchés ; Théorie des organisations ; Stratégie et politique générale ; Etude approfondie des fonctions ; Marchés financiers.',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Manager esca',
            sigle: 'ESCA',
            introduction: '',
            volume: [
                {
                    title: 'Objectif',
                    contenu: [
                        {
                            subtitle:
                                "Renforcer l'efficacité de son management",
                            subtitleText: (
                                <p>
                                    • Passer d'une culture d'ingénieur à une
                                    culture managériale <br />• Approfondir ses
                                    techniques managériales <br />• Animer son
                                    équipe et la faire progresser <br />
                                </p>
                            ),
                        },
                        {
                            subtitle: 'Débouchés',
                            subtitleText:
                                'Responsable ou Directeur Marketing, Responsable ou Directeur Communication, Responsable ou Directeur financier, Responsable de trésorerie, Contrôleur financier, Supply chain manager, Responsable ou Directeur import-export, Responsable de filiales étrangères…',
                        },
                    ],
                },
                {
                    title: 'Formation',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 324 H',
                            subtitleText:
                                'Psychologie de la communication ; Méthodologie de la Recherche ; Anglais ; Conduite de réunion ; Leadership ; Psychologie sociologique des organisations ; EPS. ',
                        },
                        {
                            subtitle: 'Mathématique, informatique 170 H',
                            subtitleText:
                                'Statistique-Probabilité ; Recherche opérationnelle ; Mathématique appliquée ; Utilisation de logiciel ; Informatique.',
                        },
                        {
                            subtitle: 'Finance, comptabilité, droit 870 H',
                            subtitleText:
                                'CAE budget ; Finance ; Fiscalité ; Comptabilité des sociétés ; Droit des sociétés ; Audit ; Droit du Travail ; Contrôle de Gestion ; Droit Pénal.',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 818 H',
                            subtitleText: `Marketing international ; Vente ; Marketing stratégie ; Environnement ; Etude de marché ; 
                                Economie Monétaire Internationale ; Economie Industrielle ; Marketing ; Commerce International ; Organismes économiques régionaux et internationaux ; Actualité Economie Ivoirienne ; Vente ; P G E ; Production ; Gestion des projets. 
                                `,
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Haute Études en Assurance',
            sigle: 'HEA',
            introduction:
                "L'objectif de la licence professionnelle Assurance est de former des professionnels qui soient capables de diriger une PME, d'assumer des responsabilités dans les services d'assurance. Les diplômés peuvent être chargés de l'organisation et du contrôle des opérations d'assurance des entreprises. Ils peuvent poursuivre leurs études au sein d'un Institut de hautes études en Assurance. Les licences professionnelles, maîtrise d'Assurance sont aussi à leur portée.",
            volume: [
                {
                    title: 'Objectif',
                    contenu: [
                        {
                            subtitle: '',
                            subtitleText: `L'ingénieur assurance produit met en œuvre les actions qualité dans un projet ou une unité. Il assure la bonne exécution des procédures définies dans le document qualité du projet ou de l'unité. La gestion par projet, les collaborations internationales et les exigences des donneurs d'ordre requièrent la mise en place de procédures qualité pour assurer une confiance entre les partenaires et garantir la qualité des données scientifiques. `,
                        },
                        {
                            subtitle: 'Activités principales',
                            subtitleText: `Les assurances de personnes ; Les assurances de Biens et de Responsabilités ; La gestion d'actifs ; La distribution de l'assurance ; Les Business Models des entreprises d'assurances ; L'activité internationale des entreprises d'assurances ; Les sujets juridiques d'actualité et les évolutions de l'environnement juridique de l'assurance ; Le management humain ; Les systèmes d'information et les nouvelles technologies ; La conduite du changement ; L'innovation dans les produits et les services ; L'éthique.`,
                        },
                    ],
                },
                {
                    title: 'Formation',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 254 H',
                            subtitleText:
                                'Méthodologie de recherche ; Communication ; Anglais ; EPS.',
                        },
                        {
                            subtitle: 'Mathématique, informatique 490 H',
                            subtitleText:
                                "Statistique-Probabilité ; Recherche opérationnelle ; Mathématique financière ; Analyse algèbre ; Informatique d'aide à la décision ; Théorie du risque et de l'assurance.",
                        },
                        {
                            subtitle: 'Finance, comptabilité, droit 1037 H',
                            subtitleText:
                                'Production ; Marché des assurances ; Droit des sociétés ; Droit civil ; Responsabilité civile ; Droit de la santé ; Procédures pénales ;  Actuariat; Assurance Vie ; Contentieux ; Incendie et Production ; Réassurance ; Gestion des Sinistres ; Assurance Auto ; Comptabilité des Assurances ; Risque Technique.',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 420 H',
                            subtitleText:
                                'Marketing des services ; Démographie ; Gestion du Portefeuille ; Risque Management.',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Ingénieur Logistique et transport',
            sigle: 'ILT',
            introduction: '',
            volume: [
                {
                    title: 'Objectif',
                    contenu: [
                        {
                            subtitle:
                                "Être Responsable d'une Cellule dégroupage/Assistant import",
                            subtitleText: `Coordination, planification et suivi des opérations de transit des conteneurs et colis de dégroupage ;  Suivi et gestion du stock des colis dans le magasin de dégroupage ; Assistance aux clients et aux correspondants extérieurs pour toutes les opérations nécessaires avant l'embarquement des conteneurs ; Suivi de la clientèle ; Suivi des conteneurs depuis le port d'embarquement jusqu'au magasin de dégroupage. `,
                        },
                        {
                            subtitle: 'Être Assistant export',
                            subtitleText: `Coordination et suivi des opérations de transit ; 
                                Réception et contrôle des documents du client ; Edition du budget, des certificats (certificat d'origine, etc.), des documents du crédit documentaire (engagement de change, attestation d'exportation) ; Suivi de la messagerie interne ; Transports. 
                                `,
                        },
                        {
                            subtitle: 'Fournir des données sur le secteur',
                            subtitleText:
                                'taille du parc auto, âge des véhicules, marques des véhicules, informations sur les différents acteurs du secteur, etc.',
                        },
                        {
                            subtitle: "Analyser l'activité des opérateurs",
                            subtitleText:
                                "estimer les charges et coûts d'exploitation, estimer les gains journaliers, évaluer la rentabilité de l'activité.",
                        },
                        {
                            subtitle: 'faire des propositions adéquates',
                            subtitleText:
                                "pour une amélioration de l'activité des opérateurs et un meilleur suivi.",
                        },
                    ],
                },
                {
                    title: 'Formation',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 254 H',
                            subtitleText:
                                'Méthodologie de recherche ; Communication recherche opérationnelle ; Anglais ; EPS.',
                        },
                        {
                            subtitle: 'Mathématique, informatique 440 H',
                            subtitleText:
                                'Mathématique appliquée ; Algèbre ; Analyse ; Statistique-Probabilité ; Informatique ; Recherche Opérationnelle ; Contrôle Statistique de la Qualité.',
                        },
                        {
                            subtitle: 'Finance, comptabilité, droit 350 H',
                            subtitleText:
                                'Géographie économique ; Production ; Economie ivoirienne ; Gestion des stocks ; Logistique ; Contentieux ; Droit du travail ; Assurance des Transports ; Transit et Douane.',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 878 H',
                            subtitleText: `Marketing ; Finance des marchés ; Théorie des organisations ; Stratégie et politique générale ; 
                                Etude approfondie des fonctions ; Marchés financiers ; Transport maritime ; Techniques des Transports ; Transport terrestre ; Institutions internationales ; P G E ; Manutention et Emballage ; Economie des Transports ; Transport Aérien ; Commerce International. 
                                `,
                        },
                    ],
                },
            ],
        },
    ],
    ESA: [
        {
            filiere: 'Cycle des Techniciens Supérieurs en Agronomie',
            introduction:
                "Être technicien en agronomie, c'est pouvoir résoudre des problèmes complexes de la production agricole, de sa transformation et de sa mise en marché. On s'inscrit donc dans des logiques de développement durable, de filière et de territoires ruraux, en prenant en compte les aspects environnementaux, économiques et sociaux. En gros, la formation se résume en 9 rubriques : 1.la production végétale, 2.les productions animales, 3. environnement-agriculture, 4. agroalimentaire, 5. équipement-machinisme, 6. informatique, 7. économie-filière, 8. économie-territoire et 9. communication. En sortant de cette école, beaucoup de fonctions et métiers sont accessibles : technicien dans l'environnement, chef d'exploitation, responsable de production, technicien qualité, chargé d'études économiques, recherche/développement, technico/commercial, conseiller de gestion, etc",
            volume: [
                {
                    title: 'Tronc commun (1ère et 2ème année)',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 85 H ',
                            subtitleText:
                                "Rédaction de rapports d'études, Comptes rendus ; Méthodologie de la recherche documentaire ; Initiation à la technologie audiovisuelle ; Anglais technique.",
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 536 H',
                            subtitleText:
                                "Méthodologie de l'exposé oral ; Méthodologie d'élaboration d'un plan d'action ; Initiation à l'outil informatique ; Diagnostic en milieu rural. Étude de la plante : classification, anatomie, biologie ; Présentation et description du règne animal ; Anatomie-Physiologie de la Digestion ; Anatomie-physiologie de la Reproduction ; Anatomie-physiologie de la lactation ; Zootechnie générale Fertilisation; Entomologie ; Phytopathologie ; Récolte-Conditionnement, Conservation des produits végétaux (denrées stockées) ; Conditionnement-conservation des produits animaux ; Principes d'alimentation des animaux domestiques (élevages) ; Principes de construction des habitats des animaux domestiques ; Principes d'amélioration du cheptel ; Informatique ; Système d'élevage ; Facteurs de production et itinéraires techniques ; Système agraires ; Vulgarisation agricole.",
                        },
                        {
                            subtitle: 'Finance, comptabilité 50 H',
                            subtitleText:
                                'Comptabilité générale ; Comptabilité analytique.',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 235 H',
                            subtitleText:
                                "Monde rural, métiers ruraux et organisations professionnelles agricoles ; Statistiques descriptives (changement d'intitulé) ; Enquêtes et analyse statistiques (changement d'intitulé) ; Socio-démographie ; Flux Migrations; Economie nationale et internationale ; Prix de revient-Commercialisation-Distribution ; Notion d'entreprise et d'exploitation ;  Construction d'un programme de formation agricole ; Gestion des exploitations agricoles.",
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 83 H',
                            subtitleText:
                                "Agroclimatologie ; Pédologie ; Récolte Conditionnement, Conservation des produits forestiers ; Gestion de l'environnement.",
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 150 H',
                            subtitleText:
                                'Topographie ; Hydrologie, hydrographie agricole ; Énergie et travail en agriculture (défrichement) ; Lutte antiérosive; Hydraulique agricole ; Irrigation.',
                        },
                        {
                            subtitle: 'Génie chimique et agroalimentaire 74 H',
                            subtitleText: 'Monde microscopique.',
                        },
                        {
                            subtitle: 'Exploitations villageoises 60 jours',
                            subtitleText:
                                'Mise en place et entretien des productions animales ; Analyse des composantes du milieu rural.',
                        },
                        {
                            subtitle: 'Journées carrières 2 jours',
                            subtitleText: 'Conférences-débats.',
                        },
                        {
                            subtitle: 'Exploitations 40 jours',
                            subtitleText: "Analyse d'exploitation.",
                        },
                        {
                            subtitle: 'Stage 40 jours',
                            subtitleText: "Stage d'orientation.",
                        },
                    ],
                },
                {
                    title: '3ème année Technique des Eaux et Forêts',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 20 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 120 H',
                            subtitleText:
                                'Pêche et Pisciculture ; Pathologies forestières et ennemis des plantes ; Analyse des projets.',
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 480 H',
                            subtitleText:
                                "Pyrologie forestière ; Aménagement des parcs nationaux ; Écologie forestière ; Gestion de l'environnement ; Connaissance de la faune ; Xylologie et séchage du bois ; Pédologie forestière ; Sylviculture générale ; Législation et politique forestière ; Economie forestière et des ressources nationales ; Exploitation forestière ; Reboisement ; Inventaire forestier ; Dendrométrie ; Dendrologie ; Technologie du bois ; Agroforesterie ; Aménagement forestier ; Biométrie.",
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 38 H',
                            subtitleText: 'Topographie.',
                        },
                        {
                            subtitle:
                                'Sciences de la terre et des ressources minières 60 H',
                            subtitleText: 'Télédétection et Cartographie.',
                        },
                        {
                            subtitle: "Stage 40 jours (Stage d'orientation)",
                            subtitleText:
                                "Voyage d'étude 1 sem ; PFE 90 jours.",
                        },
                    ],
                },
                {
                    title: '3ème année Machinisme agricole et aménagement rural',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 20 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 325 H',
                            subtitleText:
                                "Construction Rurale ; Calcul de réseaux par aspersion ; Dessin technique ; Entretien et réparation du matériel-Exploitation d'un prospectus ; Gestion et coûts des opérations mécanisées ; Hydraulique agricole ; Matériels d'entretien Post récolte ; Matériels et travaux agro-mécaniques ; Moteurs et transmissions mécaniques ; Informatique (autocad) ; Marketing et actions commerciales ; Petits barrages en terre ; Organisation et gestion d'un garage ; Planification des irrigations ; Projets d'irrigation ; Relation eau-sol-plantes ; Résolutions numériques ; Riziculture irriguée.",
                        },
                        {
                            subtitle: 'Génie électrique 10 H',
                            subtitleText: 'Électricité automobile',
                        },
                        {
                            subtitle:
                                'Sciences de la terre et des ressources minières 20 H',
                            subtitleText: 'Mécanique des sols',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 70 H',
                            subtitleText:
                                'Analyse économique des projets agricoles ; Programmation linéaire',
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 12 H',
                            subtitleText: 'Résistance des matériaux.',
                        },
                        {
                            subtitle: 'Stage 40 jours',
                            subtitleText:
                                'Stage FS2 + FS3 : maîtrise Comp. ; Gestion Com.',
                        },
                        {
                            subtitle: 'PFE 90 jours',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: '3ème année Machinisme agricole et aménagement rural',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 20 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 325 H',
                            subtitleText:
                                "Construction Rurale ; Calcul de réseaux par aspersion ; Dessin technique ; Entretien et réparation du matériel-Exploitation d'un prospectus ; Gestion et coûts des opérations mécanisées ; Hydraulique agricole ; Matériels d'entretien Post récolte ; Matériels et travaux agro-mécaniques ; Moteurs et transmissions mécaniques ; Informatique (autocad) ; Marketing et actions commerciales ; Petits barrages en terre ; Organisation et gestion d'un garage ; Planification des irrigations ; Projets d'irrigation ; Relation eau-sol-plantes ; Résolutions numériques ; Riziculture irriguée.",
                        },
                        {
                            subtitle: 'Génie électrique 10 H',
                            subtitleText: 'Électricité automobile',
                        },
                        {
                            subtitle:
                                'Sciences de la terre et des ressources minières 20 H',
                            subtitleText: 'Mécanique des sols',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 70 H',
                            subtitleText:
                                'Analyse économique des projets agricoles ; Programmation linéaire',
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 12 H',
                            subtitleText: 'Résistance des matériaux.',
                        },
                        {
                            subtitle: 'Stage 40 jours',
                            subtitleText:
                                'Stage FS2 + FS3 : maîtrise Comp. ; Gestion Com.',
                        },
                        {
                            subtitle: 'PFE 90 jours',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: '3ème année Agriculture générale',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 21 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 448 H',
                            subtitleText:
                                "Amélioration des plantes ; Analyse économique des projets agricoles ; Biométrie agricoles ; Caractérisation des parcours naturels et fourrages cultivés : Cultures de l'anacardier ; Cultures spécifiques (maraichers) ; Entomologie ; Écologie générale ; Fertilisation, formulation des engrais ; Programmation linéaire (cours comm.) ; Lutte intégrée contre les ravageurs ; Les outils de prise décision ; Informatique (cours comm) ;  Malherbologie ; Phytopathologie ; Sériciculture (vers à soie) ; Législation foncière ; Topographie appliquée.",
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 63 H',
                            subtitleText:
                                'Formulation des produits phytosanitaires ; Horticulture et aménagement urbain ; Pyrologie.',
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 42 H',
                            subtitleText:
                                'Planification en Irrigation ; Coût du matériel et opérations agricoles ; Matériels et travaux agricoles mécanisés.',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 13 H',
                            subtitleText: 'Marketing (cours comm).',
                        },
                        {
                            subtitle: 'Stage 70 jours',
                            subtitleText:
                                'FS2 : maîtrise des Compétences techniques-travaux Pratiques ; FS3 : maîtrise Gestion com.',
                        },
                        {
                            subtitle: 'PFE 90 jours',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: '3ème année Eau, forêt et environnement',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 20 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 504 H',
                            subtitleText:
                                "Dendrométrie ; Dendrologie ; Écologie forestière ; Aménagement des parcs nationaux ; Contrôle des feux ; Connaissance de la faune ; Xylologie et séchage du bois ; Agroforesterie ; Aménagement forestier ; Gestion de l'environnement ; Biométrie forestière ; Economie des ressources naturelles ; Pédologie forestière ; Pyrologie forestière ; Reboisement ; Sylviculture générale ; Exploitation forestière ; Cartographie et Télédétection ; Technologie du bois.",
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 190 H',
                            subtitleText:
                                'Micologie pathologie des plantes forestières ; Ennemis des plantes ; Législation et politique forestière ; Inventaire forestier ; Pêche, Aquaculture et Pisciculture ; Informatique.',
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 30 H',
                            subtitleText: 'Topographie.',
                        },
                        {
                            subtitle: 'Visite de chantiers 4 jours',
                            subtitleText: '',
                        },
                        {
                            subtitle: 'Stage 30 jours',
                            subtitleText:
                                "Stage d'entreprise et Communication.",
                        },
                        {
                            subtitle: 'PFE 90 jours.',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: '3ème année Élevage',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 21 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 545 H',
                            subtitleText:
                                "Biométrie Agricole ; Programmation linéaire (cours comm) ; Analyse bromatologique des aliments TP ; Caractérisation des formations végétales pâturables ; Parcours naturels et cultures fourragères ; Élevage des abeilles (apiculture) ; Élevage avicole ; Élevage Bovin/viande ; Élevage d'aulacodes ; Élevage de lapin ; Élevage d'escargots ; Élevage du vers à soie ; Élevage ovin/caprin ; Informatique (cours comm) ; Filière Porcine ; Législation foncière ; Législation Zoosanitaire ; Marketing (cours comm) ; Étude de projets ; Génie Piscicole ; Valeur nutritive des aliments du bétail ; Systématique et reconnaissance des plantes fourragères ; Production laitière ; Système d'élevage.",
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 28 H',
                            subtitleText:
                                'Connaissance et Protection de la faune.',
                        },
                        {
                            subtitle: 'Stage 55 jours',
                            subtitleText:
                                'Visite en entreprise ; Stage FS3 : maîtrise Gestion Communication.',
                        },
                        {
                            subtitle: 'PFE 90 jours.',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
    ],
    ESI: [
        {
            filiere:
                "Sciences et Technologies de l'Information et de la Communication",
            sigle: 'STIC',
            introduction: 'Former un ingénieur ESI, spécialité STIC.',
            volume: [
                {
                    title: 'Objectifs',
                    contenu: [
                        {
                            subtitle: 'Débouchés',
                            subtitleText:
                                'Equipementiers (Constructeurs), Opérateurs de services, Autorité de normalisation, Fournisseurs de service, Opérateurs réseaux télécoms et informatiques, Enseignement, Recherche et développement, Multimédia, Autorité de régulation, Intégrateurs de solutions.',
                        },
                        {
                            subtitle: 'Fonctions Visées',
                            subtitleText:
                                'Conception, Administration, Planification, Marketing, Commercial, Consultance, Audit.',
                        },
                        {
                            subtitle: 'Tâches spécifiques',
                            subtitleText: (
                                <p>
                                    • Administre et Gère des réseaux télécoms et
                                    informatiques ; <br />
                                    • Chef de projet ; <br />
                                    • Assure la maintenance électronique et
                                    informatique ; <br />• Administre des
                                    systèmes informatiques ; <br />
                                    • Conçoit des logiciels ; <br />
                                    • Conçoit les normes ; <br />
                                    • Conçoit des sites web (webmaster) ; <br />
                                    • Promotion, vente de matériels et de
                                    logiciels (ingénieur technico) ; <br />•
                                    Veille technologique ;{' '}
                                </p>
                            ),
                        },
                        {
                            subtitle: 'Interagir avec les autres services ',
                            subtitleText: (
                                <p>
                                    Achats, Commercial, Sécurité, Qualité,
                                    Soustraitance, Direction générale ; <br />
                                    Gère une régie de production ;<br />
                                    Gère les stocks ; <br />
                                    Réalise des audits informatiques et télécom
                                    ;<br />
                                    Gère un système d'information.{' '}
                                </p>
                            ),
                        },
                    ],
                },
                {
                    title: 'CONTENU DE LA FORMATION',
                    contenu: [
                        {
                            subtitle: 'Semestre 5 ING STIC',
                            subtitleText: `Socle Fondamentale des Connaissance (Mesure Intégration et Distribution, Ondes et Support Electromagnétique, Probabilités et Statistiques); Les Outils de l'Ingénieur (Codage Logique et Langage C,  Base de la programmation Objet, Maitrise de l'Environnement Numérique de Travail, TopologieMéthodes Hilbertiennes et  Applications) ; Electronique (Théorie des Signaux et Filtrage, Principe des Réseaux informatiques, Système de Transmission, de la Micro à la Nano Technologie, Les Réseaux des Services aux Technologies); Bases de l'informatique ( Système 
                                d'Exploitation, Algorithme Python, Algorithme) ; Professionnelle (Projet Découverte, EPS) ; Langues Vivantes (Anglais) ; Economie et Gestion  (Management d'Entreprise, Simulation de Gestion d'Entreprise, Maitrise de la Langue Française Expression Ecrite et Orale).    
                                `,
                        },
                        {
                            subtitle: 'Semestre 6 ING STIC',
                            subtitleText: `Mathématiques appliquées (Analyse Numérique et Optimisation, Traitement du Signal, Recherche opérationnelle, Files d'attente); Communication   
                            Numérique et Multimédia (Codage de l'Information, Communication Numérique); Physique et Electronique (Eléments de Traitement Numérique du Signal, Electronique Numérique, Pratique des Signaux Aléatoires et communication Numériques) ; Modélisation et Ingénierie du logiciel (Ingénierie du Logiciel et des Données, Modélisation et Simulation Stochastique); Professionnelle (Projet découverte, EPS); Langues Vivantes (Anglais) ; Multimédia (Technologie du Multimédia, Maitrise de la Langue Française Expression Ecrite et Orale).    
                            `,
                        },
                        {
                            subtitle: 'Semestre 7 ING STIC',
                            subtitleText: `Réseaux (Les Réseaux locaux, Réseaux sans Fil à Haut Débit 1, Les Réseaux Mobiles du GSM à l'UMTS, Principe des Réseaux IP) ; Electronique et    
                            Transmission (Composants de l'Electronique, TP Electronique Numérique, Dispositifs et Fonctions Analogiques, Introduction à l'ingénierie des Systèmes) ; Système d'information (Base de Données, Logique et Langage) ; Algorithme et Graphes (Algorithme avancé, Système distribué, Graphes) ; Professionnelle (EPS, Ecrit Professionnel et Administratif, Visite d'Entreprise, Projet Ingénieur) ; Langues Vivantes (Anglais).    
                            `,
                        },
                        {
                            subtitle: 'Semestre 8 ING E2I',
                            subtitleText: `Réseaux Télécom (Réseaux sans fil à haut débit 2, Dispositif  et Fonctions Optiques, TP Réseaux Télécom, TP Réseaux Informatique) ; Réseaux et   
                            Architecture des Systèmes (Architecture des Systèmes Numériques de Traitement, Système et Réseaux de Transmission, TP Télécom Transmission Analogique, TP Electronique) ; Transmission et Traitement d'Image (Traitement Numérique d'Images, Transmission Numérique, Transmission    
                            Analogique) ; Optionnelle Ingénierie des Réseaux (Technologie pour les Réseaux d'Opérateur, Evolution des Performances de Fiabilités  des Réseaux, Méthode et Concepts pour la sécurité dans les réseaux) ; Professionnelle (Projet Ingénieur, EPS, Ecrit Professionnel et Administratif) ; Langues Vivantes (Anglais) ; Ouverture socio économique et culturel 3 et Projet (Economie Industrielle et Stratégie d'entreprise, Comptabilité Générale, Conjoncture et politique Economique, Droit et Régulation en Côte d'Ivoire).    
                             `,
                        },
                        {
                            subtitle: 'Semestre 9 ING E2I',
                            subtitleText: `Electronique et Electrotechnique (Electrotechnique Appliquée, Circuit Imprimé, Capteurs et Instrumentation en Electronique) ; Automatismes    
                            Industrielles (Co&TP Microcontrôleur, GRAFCET&TP API) ; Electronique Industrielle (Co&TP Electronique de puissance, Co&TP Asservissement) ; Robotique et Antenne (TP Antenne, Robotique) ; Communication Mobile (Communication  Mobile, Architecture des Réseaux Mobiles 2G) ; Optionnelle Réseaux (Administration et Sécurité Réseau  sous LINUX, IPV6, Réseaux Optiques) ; Langues Vivantes (Anglais) ; Professionnelle (EPS, Méthodologie de Rédaction de Mémoire).    
                            `,
                        },
                        {
                            subtitle: 'Semestre 8 ING INFO',
                            subtitleText: `Réseaux Télécom (Intelligence Artificielle, Réseaux Informatique, Compilation) ; Génie 
                            Electrique (Systèmes Repartis, Systèmes d'exploitation, TP Réseaux    
                            Informatique, Electrotechnique Appliquée) ; Informatique (Base de Données, JAVA Avancé) ; Optionnelle Ingénierie des Réseaux (Technologie pour les    
                            Réseaux d'Opérateur, Evolution des Performances de Fiabilités  des Réseaux, Méthode et Concepts pour la sécurité dans les réseaux) ; Professionnelle (Projet Ingénieur, EPS, Ecrit Professionnel et Administratif) ; Langues Vivantes (Anglais) ; Ouverture socio économique et culturel 3 et Projet (Economie Industrielle et Stratégie d'entreprise, Comptabilité Générale, Conjoncture et politique Economique, Droit et Régulation en Côte d'Ivoire).    
                             `,
                        },
                        {
                            subtitle: 'Semestre 9 ING INFO',
                            subtitleText: `WEB et IA (Intelligence Artificielle, Web Services) ; Base de données et services réseaux (Base de Données, Services Réseaux) ; Logiciel et XML (Génie  
                                Logiciel, XML) ; Merise et Théorie des Langages (Merise, Théorie des Langages et Compilation) ; Informatique décisionnel, Webmaster et PU/UML (Informatique Décisionnel, PU/UML, Webmaster) ; Optionnelle Réseaux (Administration et Sécurité Réseau sous LINUX, IPV6, Réseaux Optiques) ; Langues Vivantes (Anglais) ; Professionnelle (EPS, Méthodologie de Rédaction de Mémoire).    
                                `,
                        },
                        {
                            subtitle: 'Semestre 8 ING TR',
                            subtitleText: `Réseaux Télécom (Réseaux sans fil à haut débit 2, Transmission Analogique, TP  Télécom Transmission Analogique, TP Réseaux Informatique) ; Réseaux et Architecture des Systèmes (Architecture des Systèmes Numériques de Traitement, Système et Réseaux de Transmission, TP Electronique, Transmission  
                                Numérique) ; Ingénierie des Réseaux (Technologie pour les Réseaux d'Opérateur, Evolution des Performances de Fiabilités  des Réseaux, Méthode et  Concepts pour la sécurité dans les réseaux) ; Optionnelle Electronique et Informatique (Dispositif  et Fonctions Optiques, JAVA Avancé, Web Services) ;  Professionnelle (Projet Ingénieur, EPS, Ecrit Professionnel et Administratif) ; Langues Vivantes (Anglais) ; Ouverture socio économique et culturel 3 et Projet (Economie Industrielle et Stratégie d'entreprise, Comptabilité Générale, Conjoncture et politique Economique, Droit et Régulation en Côte d'Ivoire).    
                                `,
                        },
                        {
                            subtitle: 'Semestre 9 ING TR',
                            subtitleText: `Administration et Sécurité Réseaux (Administration et Sécurité Réseaux sous Linux, TP Réseaux Télécom) ; Système Communication (WIMAX,    
                                Système Large Bande) ; Réseaux et Télécom (Capteur et Instrumentation, Signalisation et Réseaux  Intelligents, Electrotechnique Appliquée, TP Télécom & TP Antenne) ; Dimensionnement et Protocole Réseaux (Protocole IPV6, Réseau LTE et 4G, 
                                Dimensionnsement d'un Réseau Télécom avec un logiciel) ; Optionnelle Informatique (Merise, Informatique Décisionnel, Webmaster) ; Langues Vivante (Anglais) ; Professionnelle (EPS, Français).    
                                `,
                        },
                        {
                            subtitle:
                                'Semestre 10 Commun à toutes les formations',
                            subtitleText: `Ouverture socio économique et culturel 7 (Comportement des Acteurs et Structure des Marchés, Management et politique d'Entreprise, Comportement  rganisationnel, Politique Marketing, Gestion de projet) ; Conférence, séminaire ou 
                            formation (Autres Séminaires) ; Stages en milieu professionnel (Stage) 
                            `,
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Sciences et Technologies du Génie Industriel',
            introduction: 'Former un ingénieur ESI, spécialité STGI.',
            volume: [
                {
                    title: 'Objectifs',
                    contenu: [
                        {
                            subtitle: 'Débouchés',
                            subtitleText:
                                'Production, Maintenance, Achats, Commercial, QSE (Qualité, Sécurité, Environnement), Expertise technique, Enseignement.',
                        },
                        {
                            subtitle: 'Tâches spécifiques',
                            subtitleText: (
                                <p>
                                    {' '}
                                    <strong>
                                        {' '}
                                        Assurer la continuité de la production :
                                    </strong>{' '}
                                    <br />
                                    • Gestion des stocks, Fonctionnement des
                                    machines (fiabilité, maintenance), Gestion
                                    des équipes; <br />
                                    • Planification et programmation de la
                                    production, Mise en place de systèmes
                                    qualité; <br /> <br />
                                    <strong>
                                        Prévoir les évolutions de production :{' '}
                                    </strong>{' '}
                                    <br />
                                    • Conception et réalisation de postes de
                                    travail, d'équipements; <br />
                                    • Optimisation de la production,
                                    Optimisation des ressources (matières
                                    premières, énergie), Gestion des ressources
                                    humaines nécessaires;
                                    <br />
                                </p>
                            ),
                        },
                        {
                            subtitle: 'Interagir avec les autres services ',
                            subtitleText:
                                'Achats, Commercial, Sécurité, Qualité,  Soustraitance, Direction générale.',
                        },
                    ],
                },
                {
                    title: 'CONTENU DE LA FORMATION',
                    contenu: [
                        {
                            subtitle: 'Semestre 5 ING STGI',
                            subtitleText: `Achats, Commercial, Sécurité, Qualité,  Soustraitance, Direction générale.`,
                        },
                        {
                            subtitle: 'Semestre 6 ING STGI',
                            subtitleText: `Sciences des matériaux (Atomistique et liaison chimique, Thermostatistique chimique, Transfert de masse) ; Systèmes électriques (Régime Polyphasé, Circuits Analogiques d'Electronique de Commande, Circuit Magnétique et Transformateur) ; Fluides et énergies (Thermodynamique générale, Mécanique des fluides élémentaire, Thermique fondamentale) ; Génie mécanique (Construction mécanique, Fabrication mécanique, Mécanique DES et résistance des matériaux) ; Professionnelle (Projet découverte, Education Physique et Sportive) ; Langues vivantes (Anglais) ; Gestion d'entreprise (Simulation et gestion d'Entreprise, Maitrise de la langue française et Expression écrite et orale).`,
                        },
                        {
                            subtitle: 'Semestre 7 ING STGI',
                            subtitleText: `Mathématiques appliquées (Analyse numérique et optimisation, Mathématique appliquée à la physique, Recherche opérationnelle) ; Outils informatiques (Génie Logiciel, Base de données, Logiciels de Production et Gest. de l'énergie) ; Ingénierie des Matériaux (Métallurgie, Plasturgie, TP Matériaux) ; Production et Conversion d'Energie (Technique de Production d'Energie, Machines Tournantes Electriques Industrielles, Principe de Conversion Statique d'Energie) ; Professionnelle (Visite d'entreprise, Projet d'ingénieur) ; Langues vivantes (Anglais) ; Ressources humaines (Ecrits professionnels et Administratifs, Education Physique et sportive).`,
                        },
                        {
                            subtitle: 'Semestre 8 ING EAI',
                            subtitleText: `Electrotechnique (Compléments d'Electrotechnique, TP d'Electrotechnique) ; Installations électriques basse tension (Technologie et Schémas Electriques, Distribution Electrique, TP Installations Electriques) ; Energie et Commande des systèmes (Automatismes, Automatique) ; Optionnelle ;  Professionnelle (Projet Ingénieur, Education Physique et sportive, Ecrits professionnels et Administratifs) ; Langues vivantes (Anglais). `,
                        },
                        {
                            subtitle: 'Semestre 9 ING EAI',
                            subtitleText: `Application de l'électricité (Electrothermie, Simulation des Phénomènes Electriques,  
                                    Energie Solaire, Installations Electriques dans l'Habitat) ; Automatique industrielle (Régulation et Réglages Industriels, Réseaux Locaux Industriels, TP Automates Programmables Industriels, Technique de Supervision et de Télé Conduite) ; Réseaux Haute Tension (Ingénierie des Réseaux Haute Tension, Gestion des Réseaux Haute Tension) ; Instrumentation (Chaines de Mesure, Capteurs, LabVIEW, Microcontrôleur) ; Professionnelle (Gestion de Projet, Ecrits professionnels et Administratifs) ; Langues vivantes (Anglais).    
                                    `,
                        },
                        {
                            subtitle: 'Semestre 8 ING PGE',
                            subtitleText: `Fluides et Energies 2 (Mécanique des fluides appliquée, Thermodynamique appliquée, Thermique des échangeurs) ; Génie Mécanique2    
                                (Construction mécanique CAO/DAO, Fabrication Mécanique2 -Usinage, Fabrication Mécanique Numérique) ; Optionnelle ; Professionnelle (Projet  Ingénieur, EPS, Ecrit Professionnel et Administratif) ; Langues Vivantes (Anglais) ; Ouverture socio économique et culturel 3 et Projet (Economie Industrielle et Stratégie d'entreprise, Comptabilité Générale et analytique, Conjoncture et politique Economique, Droit et Régulation en Côte d'Ivoire).    
                                `,
                        },
                        {
                            subtitle: 'Semestre 9 ING PGE',
                            subtitleText: `Machines thermiques à conversion d'énergie (Moteurs thermiques à pistons alternatifs, Turbines à gaz terrestres, Turbines à vapeur) ; Energies Nouvelles et renouvelables (Energie solaire, Energie éolienne et biomasse) ; Optionnelle ; Génie Frigorifique et climatisation (Physique du froid, Technologies en froid et climatisation, Climatisation et conditionnement d'air) ; Professionnelle (Gestion de Projet, Méthodologie de rédaction de mémoire) ; Langues Vivantes (Anglais).`,
                        },
                        {
                            subtitle: 'Semestre 8 ING MIP',
                            subtitleText: `Fluides et Energies 2 (Mécanique des fluides appliquée, Thermodynamique appliquée, Thermique des échangeurs) ; Génie Mécanique2    
                                (Construction mécanique CAO/DAO, Fabrication Mécanique2 – Usinage, Fabrication Mécanique Numérique) ; Optionnelle ; Professionnelle (Projet Ingénieur, EPS, Ecrit Professionnel et Administratif) ; Langues Vivantes (Anglais) ; Ouverture socio économique et culturel 3 et Projet (Economie Industrielle et Stratégie d'entreprise, Comptabilité Générale et analytique, Conjoncture et politique Economique, Droit et Régulation en Côte d'Ivoire).    
                                `,
                        },
                        {
                            subtitle: 'Semestre 9 ING MIP',
                            subtitleText: `Machines thermiques à conversion d'énergie (Moteurs thermiques à pistons alternatifs, Turbines à gaz terrestres, Turbines à vapeur) ; Optionnelle ; Hydraulique et production (Hydraulique de puissance, Machines de production, Organisation et gestion de la production) ; Professionnelle (Gestion de projet, Méthodologie de rédaction de mémoire) ; Langues Vivantes (Anglais).`,
                        },
                        {
                            subtitle:
                                'Semestre 10 Commun à toutes les formations',
                            subtitleText: `Ouverture socio économique et culturel 7 (Comportement des Acteurs et Structure des Marchés, Management et politique d'Entreprise, Comportement  
                                    Comportement Organisationnel, Politique Marketing) ; Conférence, séminaire ou formation (Autres Séminaires) ; Stages en milieu professionnel (Stage de fin du semestre 8 ou Immersion, Projet de fin d'études).    
                                    `,
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Sciences et Technologies du Génie des Procédés',
            introduction: 'Former un ingénieur ESI, spécialité STGP',
            volume: [
                {
                    title: 'Objectifs',
                    contenu: [
                        {
                            subtitle: 'Débouchés',
                            subtitleText:
                                "Agro-alimentaire, Pétrole, Sociétés minières, Industrie chimique, Cosmétologie, Savonnerie, Sociétés commerciales, Energie, Traitement de l'eau, Environnement, Traitement des déchets, Santé, Enseignement, Recherche.",
                        },
                        {
                            subtitle: 'Tâches spécifiques',
                            subtitleText: (
                                <p>
                                    • Gérer des stocks ; <br />• Participer aux
                                    ressources humaines ; <br />
                                    • Gérer un laboratoire ; <br />
                                    • Planifier la production ; <br />
                                    • Formuler et concevoir de nouveaux produits
                                    ; <br />• Contrôler le fonctionnement
                                    d'unités de production ;{' '}
                                </p>
                            ),
                        },
                        {
                            subtitle: 'Interagir avec les autres services ',
                            subtitleText: (
                                <p>
                                    • Achats, Commercial, Sécurité, Qualité,
                                    Sous-traitance, Direction générale ; <br />
                                    • Mettre en place les normes et les vérifier
                                    jusqu'à la certification ; <br />• Mettre en
                                    œuvre le système de qualité ; <br />
                                    • Concevoir, et mettre en œuvre, et suivre
                                    de processus ; <br />
                                    • Veille technologique ; <br />
                                    • Concevoir, mettre en œuvre et suivre de
                                    systèmes de sécurité ; <br />• Assurer la
                                    maintenance des équipements ; <br />•
                                    Concevoir, mettre en œuvre et suivre de
                                    systèmes de métrologie.
                                </p>
                            ),
                        },
                    ],
                },
                {
                    title: 'CONTENU DE LA FORMATION',
                    contenu: [
                        {
                            subtitle: 'Semestre 5 ING STGp',
                            subtitleText: `Mathématiques (Mesures et intégration, Transformée de Fourier, Analyse numérique ou Topologie et Méthodes hilbertiennes, Statistique et probabilités/Recherche  opérationnelle) ; Thermodynamique  et Energétique (Thermodynamique appliquée et Energétique, Thermostatique et chimique, Froid industriel, Pompes et compresseurs) ; Phénomène de transfert 1 et Physique (Physique, Traitement de signal, Mécanique des fluides  appliquée au génie des procédés) ; Informatique  et méthodes numériques (Informatique, Méthodes numériques, projet informatique) ; Professionnelle 1 (Projet Découverte, EPS) ; Langues Vivantes 1 (Anglais) ; Ouverture socio-économique et culturelle 1 (Maitrise de la Langue Française Expression Ecrite et Orale, Comptabilité Générale et Analytique).`,
                        },
                        {
                            subtitle: 'Semestre 6 ING STGP',
                            subtitleText: `Phénomène de transfert 2 (Transfert de masse et de chaleur et échangeur de chaleur, Phénomène de Transfert, TP  Phénomène de transfert) ; Systèmes réactifs et procédés 1 (Cinétique Chimique homogène et hétérogène, Génie de la Réaction chimique, Catalyse et catalyseurs) ; Chimie Organique  1 et structurale (Chimie Organique 1, Spectroscopie de masse, TP  Chimie Organique) ; Chimie Minérale (Chimie minérale, TP Chimie minérale, Chimie des ions en solutions) ; Professionnelle 2 (Projet Découverte, EPS) ; Langues Vivantes 2 (Anglais) ; Ouverture socio économique et culturelle 2 (Technique d'expression française, Economie).`,
                        },
                        {
                            subtitle: 'Semestre 7 ING STGP',
                            subtitleText: `Ingénierie des matériaux (Corrosion et Electrochimie, Matériaux et Résistance des matériaux, Chimie industrielle Cours et TP) ; Chimie Organique 2 et Génie analytique (Chimie Organique 2, Génie analytique, TP Génie analytique) ; Physico chimie des interfaces et des milieux dispersés (Physico chimie des interfaces, Physico chimie des milieux dispersés, TP Physico chimie) ; Systèmes réactifs et procédés 2 (Dynamique des systèmes, Conception de procédés industriels, TP Systèmes réactifs et procédés) ; Professionnelle 3 (Visite d'entreprise, Projet ingénieur, EPS) ; Langues Vivantes 3 (Anglais) ; Ouverture socio économique et culturelle 3 (Communication en entreprise 1, Gestion des entreprises).`,
                        },
                        {
                            subtitle: 'Semestre 8 ING GBP',
                            subtitleText: `Procédés de séparation (Thermodynamique des équilibres entre phases, Distillation binaire air humide et séchage, Procédés membranaires) ; Génie des procédés discontinus (Simulation des réactions discontinues, Cristallisation industrielle, Conduite et conception d'installation multi produits) ; Sécurité et développement durable (Hygiène-sécuritéenvironnement, Traitement des eaux, Traitement des gaz) ; Optimisation dynamique et commande (Statistique et Optimisation Dynamique, CPAO et Commande & modélisation, Plans d'expériences et méthodes d'optimisation) ; Optionnelle  1 ; Professionnelle (EPS, Projet Ingénieur) ; Langues Vivantes 4 (Anglais) ; Ouverture socio économique et culturelle et Professionnelle 1 (Visite d'entreprise, Management de la qualité, Droit civil et droit du travail).`,
                        },
                        {
                            subtitle: 'Semestre 9 ING GPI',
                            subtitleText: `Propriétés et qualité des produits ou Produits de spécialité 2 (Produits cosmétiques Cours et TP, Produits colorants  et Peintures Cours et TP, Produits phytosanitaires Cours et TP) ; Produits fonctionnels et Bioplasturgie (Bio plastiques et Bioplasturgie, Gestion des systèmes industriels, Produits biosourcés et biodégradables) ; Etude de cas – projet de conception de produits innovants (Projets innovants et méthode d'analyse de brevets, Projet de conception de produits innovants, Procédés de fabrication de solide inorganiques) ; Optionnelle  Génie Chimique (Simulation des réactions discontinues, Cristallisation industrielle, Conduite et conception d'installation multi produits, Procédés membranaires) ; Langues Vivantes 5 (Anglais) ; Ouverture socio économique, culturelle et   Professionnelle 2 (Entreprenariat, Technique de rédaction de mémoire).`,
                        },
                        {
                            subtitle: 'Semestre 9 ING GBP',
                            subtitleText: `Sciences pétrochimiques (Base du raffinage et schémas de fabrication, TP  Pétrochimie, 
                                Pétrochimie : prospection - géochimie - raffinage) ; Produits fonctionnels et plasturgie (Matières plastiques et plasturgie, Gestion des systèmes industriels, Produits fonctionnels) ; Etude de cas – projet de conception de produits innovants (Projets innovants et méthode d'analyse de brevets) ; Systèmes réactifs et procédés 3 (Réacteurs poly phasiques, Procédés durables, Procédés de séparation) ; Optionnelle  Génie Produits (Introduction au génie des produits, Génie de la polymérisation  et Copolymères, Formulation matières plastiques, Procédés pour les systèmes fluides dispersés) ; Langues Vivantes 5 (Anglais) ; Ouverture socio économique, culturelle et   Professionnelle 2 (Entreprenariat, Technique de rédaction de mémoire).    
                                `,
                        },
                        {
                            subtitle:
                                'Semestre 10 Commun à toutes les formations',
                            subtitleText: `Ouverture socio économique et culturel et Professionnelle 3 (Comportement des Acteurs et Structure des Marchés, Management et politique d'Entreprise, Comportement Organisationnel, Politique Marketing) ; Conférence, séminaire ou formation (Autres Séminaires) ; Stages en milieu professionnel (Stage de fin du semestre 8 ou Immersion, Projet de fin d'études).`,
                        },
                    ],
                },
            ],
        },
    ],
    ESTP: [
        {
            filiere: 'Bâtiment et ubarnisme (BU)',
            introduction:
                "L'urbanisme est à la fois un champ disciplinaire et un champ professionnel recouvrant l'étude du phénomène urbain, l'action d'urbanisation et l'organisation de la ville et de ses territoires. Les personnes qui exercent ce métier sont des urbanistes.",
            volume: [
                {
                    title: '',
                    contenu: [
                        {
                            subtitle: '',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Hydraulique et Environnement (HE)',
            introduction:
                "L'hydraulique désigne la branche de la physique qui étudie la circulation des liquides, principalement l'eau et par extension de tout autre liquide tel que l'huile.",
            volume: [
                {
                    title: '',
                    contenu: [
                        {
                            subtitle: '',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Infrastructure et Transport (IT)',
            introduction:
                "L'infrastructure est un ensemble d'éléments interconnectés qui fournissent le cadre pour supporter la totalité de la structure. Le terme est souvent utilisé d'une façon très abstraite.",
            volume: [
                {
                    title: '',
                    contenu: [
                        {
                            subtitle: '',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Géomètre',
            introduction:
                "Le géomètre-topographe ou topographe, professionnel qui pratique la topographie. Le géomètre-expert, personne exerçant un métier qui consiste à établir différentes mesures touchant les propriétés foncières.",
            volume: [
                {
                    title: '',
                    contenu: [
                        {
                            subtitle: '',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
    ],
    ESMG: [
        {
            filiere: 'Cycle ingénieur',
            introduction:
                "Les objectifs de cette formation sont de former des Ingénieurs des domaines des mines et de la géologie et de l'eau. La formation est sanctionnée par le diplôme d'Ingénieur des Mines et Géologie.",
            volume: [
                {
                    title: '1ère ANNEE INGENIEUR (IC1)',
                    contenu: [
                        {
                            subtitle:
                                'Consolidation des connaissances scientifiques de base',
                            subtitleText:
                                'mathématiques, physique, chimie, informatique, traitement de signal, mécanique des milieux continus.',
                        },
                        {
                            subtitle:
                                "Renforcement de la pratique de l'Anglais",
                            subtitleText: '',
                        },
                        {
                            subtitle: "Sortie d'éveil à la géologie",
                            subtitleText: '',
                        },
                        {
                            subtitle:
                                'Introduction des disciplines de base de la géologie',
                            subtitleText:
                                'géologie endogène et exogène, géomorphologie, géologie structurale, géophysique, photo-interprétation, hydrologie…',
                        },
                    ],
                },
                {
                    title: '2ème ANNEE INGENIEUR (IC2)',
                    contenu: [
                        {
                            subtitle:
                                'Formation dans les disciplines et techniques de base des sciences de la terre',
                            subtitleText:
                                'géochimie, géologie du pétrole, géologie sédimentaire, géophysique de prospection, géotechnique, gîtologie, métallogénie, hydrogéologie, mécanique des roches, minéralurgie, pédologie, télédétection...',
                        },
                        {
                            subtitle:
                                "Renforcement de l'outil informatique et mathématique",
                            subtitleText:
                                "Informatique, modélisation, recherche opérationnelle, analyse et traitement d'image, télédétection…",
                        },
                        {
                            subtitle:
                                'Stage pratique sur le terrain (30 à 45 jours)',
                            subtitleText:
                                ' Pétrographie, cartographie, géologie structurale, géophysique appliquée, hydrogéologie, géotechnique, sédimentologie…',
                        },
                        {
                            subtitle: 'Stage de production en entreprise',
                            subtitleText: '2 à 3 mois',
                        },
                    ],
                },
                {
                    title: '3ème ANNEE INGENIEUR (Spécialisations)',
                    contenu: [
                        {
                            subtitle: 'Complément sur les techniques de base',
                            subtitleText:
                                'instruments de géophysique, informatique appliquée, organisation et gestion de chantiers.',
                        },
                        {
                            subtitle: 'Aspects administratifs',
                            subtitleText:
                                'économie minière, législation minière, rédaction administrative.',
                        },
                        {
                            subtitle: "Projet de fin d'études",
                            subtitleText:
                                "45 à 60 jours en entreprise ou dans un département de recherche et de formation de l'INP-HB.",
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'MINES ET CARRIERES (IC3-M)',
            introduction:
                "L'objectif de la formation repose sur les piliers suivants.",
            volume: [
                {
                    title: 'Pilier 1',
                    contenu: [
                        {
                            subtitle:
                                "l'entreprise minière en mutation (techniques, économie et gestion)",
                            subtitleText: '',
                        },
                        {
                            subtitle: "L'entreprise dans son contexte",
                            subtitleText: '',
                        },
                        {
                            subtitle:
                                "La gestion et l'organisation de l'entreprise",
                            subtitleText: '',
                        },
                        {
                            subtitle: 'Les techniques.',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Pilier 2',
                    contenu: [
                        {
                            subtitle:
                                "Gestion de la réduction d'activité, restructuration ; Gestion des ressources minérales.",
                            subtitleText: '',
                        },
                        {
                            subtitle:
                                'Stratégie financière et investissements ; Exploitation et environnement.',
                            subtitleText: '',
                        },
                        {
                            subtitle:
                                'Stratégie sécurité-prévention-santé des travailleurs ; La reconversion des hommes (mobilité interne et externe).',
                            subtitleText: '',
                        },
                        {
                            subtitle: 'Gestion du climat social.',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Pilier 3',
                    contenu: [
                        {
                            subtitle:
                                'Reconversion régionale et développement durable.',
                            subtitleText: '',
                        },
                        {
                            subtitle:
                                "Exemples historiques ; La reconversion régionale (les acteurs, leur rôle et leurs modes d'intervention)",
                            subtitleText: '',
                        },
                        {
                            subtitle:
                                'La reconversion de bassins mono-industriels.',
                            subtitleText: '',
                        },
                        {
                            subtitle: 'Le développement durable.',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'EXPLOITATION ET TRAITEMENT DES EAUX (IC3 ETE)',
            introduction:
                "Cette formation permet d'acquérir une formation supérieure dans les domaines liés à la production et à la distribution d'eau potable et à l'assainissement.",
            volume: [
                {
                    title: 'Former des cadres intermédiaires',
                    contenu: [
                        {
                            subtitle:
                                "Dans le développement d'études techniques",
                            subtitleText: '',
                        },
                        {
                            subtitle:
                                "Dans la maîtrise d'œuvre de conception (réseaux, pompages, stations de traitement)",
                            subtitleText: '',
                        },
                        {
                            subtitle: 'Dans le diagnostic',
                            subtitleText: '',
                        },
                        {
                            subtitle:
                                "Dans la gestion d'installation de production d'eau potable et d'assainissement.",
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Développer des compétences',
                    contenu: [
                        {
                            subtitle: 'Mises en situation concrètes.',
                            subtitleText: '',
                        },
                        {
                            subtitle: 'Rencontres avec des professionnels.',
                            subtitleText: '',
                        },
                        {
                            subtitle:
                                'la réalisation de missions lors de stages pratiques.',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Les débouchés',
                    contenu: [
                        {
                            subtitle:
                                "Responsable d'exploitation traitement des eaux et gestion des réseaux",
                            subtitleText:
                                "Coordinateur de projet d'études en traitement des eaux.",
                        },
                        {
                            subtitle:
                                "Chargé de projet d'études et de conception de filières de traitement et de réseaux (bureau d'études)",
                            subtitleText:
                                "Assistant ingénieur en maîtrise d'œuvre et suivi de chantier d'ouvrages en eau potable et assainissement.",
                        },
                        {
                            subtitle:
                                "Technicien territorial service d'eau et d'assainissement en collectivité territoriale (syndicat d'eau, communauté de communes et d'agglomération...)",
                            subtitleText:
                                "Chargé de clientèle dans une entreprise de distribution d'eau.",
                        },
                        {
                            subtitle:
                                "Chargé de mission à l'étranger dans les pays en développement",
                            subtitleText:
                                'diagnostic eau, forage, pompage, assainissement...',
                        },
                    ],
                },
            ],
        },
    ], 
}

export const filiereBachelier = {
    CPGE: [
        {
            filiere: 'Prépa BCPST',
            introduction:
                "La voie BCPST (biologie, chimie, physique et sciences de la Terre) est la prépa des sciences du vivant et des géosciences. Cette CPGE (classe préparatoire aux grandes écoles) offre une formation équilibrée en mathématiques, physique-chimie et sciences de la vie et de la Terre. La voie BCPST s'adresse aux bacheliers C & D, qui ont le goût des sciences de la vie et de la Terre (SVT), mais sont à l'aise dans l'ensemble des disciplines scientifiques (maths, physique-chimie et SVT). Des compétences rédactionnelles sont également attendues. La classe préparatoire BCPST ouvre à l'École supérieure d'agronomie (ESA), à l'École supérieure d'industrie (ESI) et l'ESMG (Ecole Supérieure des Mines et Géologie).",
            volume: [
                {
                    title: 'PRÉPA BCPST 1',
                    contenu: [
                        {
                            subtitle: 'Mathématiques',
                            subtitleText:
                                'Vocabulaire de la logique et des ensembles ; Nombres ; Trigonométrie ; Méthodes de calcul ; Vocabulaire des  applications ; Dénombrement ; Suites usuelles ; Fonctions usuelles ; Dérivées et primitives ; Équations différentielles linéaires a coefficients  constants ; Systèmes  linéaires ; Matrices ; Géométrie 1 ; Polynômes ; Statistique  descriptive ; Suites réelles ; Concepts de base des   probabilités ; Limites,  continuité ; Dérivation ; Développements limités et études de fonctions ; Espaces vectoriels et sous-espaces  vectoriels ; Applications linéaires et matrices ; Intégration ; Équations  différentielles ; Fonctions réelles de deux variables réelles ; Variables aléatoires finies.',
                        },
                        {
                            subtitle: 'Physique',
                            subtitleText:
                                'Analyse dimensionnelle ; Signaux physiques, bilans et transports ; Structure de la matière ; Optique géométrique ; Thermodynamique physique 1 ; Thermodynamique physique 2 ; Mécanique.',
                        },
                        {
                            subtitle: 'Chimie',
                            subtitleText:
                                'Analyse dimensionnelle ; Thermodynamique chimique ; Structure de la matière ; Introduction à la chimie organique ; Cinétique chimique ; Chimie organique réactionnelle.',
                        },
                        {
                            subtitle: 'Biologie',
                            subtitleText:
                                "Des molécules du vivant à la cellule : organisation fonctionnelle ; L'organisme : un système en interaction avec son environnement ; Populations, écosystèmes, biosphère ; La biodiversité et sa dynamique ; La terre, planète active ; Risques et ressources : les géosciences et l'homme ; La géologie, une science historique ; La carte géologique ; Le magmatisme ; Le phénomènes sédimentaire.",
                        },
                        {
                            subtitle: 'Informatique',
                            subtitleText: 'Algorithmique et programmation.',
                        },
                        {
                            subtitle: 'Français-Philo',
                            subtitleText:
                                "L'enseignement de français et de philosophie dans les classes préparatoires scientifiques durant l'année scolaire s'appuie notamment sur des thèmes étudiés à travers les œuvres littéraires et philosophiques. Exemples : BLAISE PASCAL (Pensées) ; LOUIS LAFUMA LIASSE (Vanité ; Raisons des effets ; Misère ; Grandeur ; Contrariétés ; Le Souverain Bien ; Soumission et usage de la raison) ; DANIEL LOAYZA (« Les Choéphores » et « Les Euménides » ; L'Orestie, Eschyle) ; JOHN STEINBECK (Les Raisins de la colère).",
                        },
                        {
                            subtitle: 'Langues Vivantes 1 & 2',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'PRÉPA BCPST 2',
                    contenu: [
                        {
                            subtitle: 'Mathématiques',
                            subtitleText:
                                'Concepts de base des probabilités et des variables aléatoires ; Espaces vectoriels ; Variables aléatoires à densité ; Applications linéaires et matrices ; Variables aléatoires réelles discrètes ; Couples de variables aléatoires discrètes ; Valeurs propres, vecteurs   propres ; Produit scalaire dans Rn ; Théorèmes limites et statistique inferentielle.',
                        },
                        {
                            subtitle: 'Physique',
                            subtitleText:
                                "Thermodynamique ; Phénomènes de transport ; Signal et rayonnement ; Mécanique (forces conservatives, énergie potentielle, conditions d'équilibre d'un solide) ; Mécanique des fluides.",
                        },
                        {
                            subtitle: 'Chimie',
                            subtitleText:
                                'Thermodynamique chimique ; Réactions en solution aqueuse ; Chimie organique.',
                        },
                        {
                            subtitle: 'Biologie',
                            subtitleText:
                                "Exemple d'une fonction en interaction directe avec l'environnement : la respiration ; Un exemple d'intégration d'une fonction à l'échelle de l'organisme ; Diversité morpho-fonctionnelle des Angiospermes ; Diversité morpho-fonctionnelle des organismes ; Les populations et leur dynamique ; Les écosystèmes, leur structure et leur fonctionnement ;  Flux et cycles biogéochimiques : l'exemple du carbone ; Les mécanismes de l'évolution ; Une approche phylogénétique de la biodiversité ; Les déformations de la lithosphère et les transformations minérales associées ; Étude de grands ensembles géologiques.",
                        },
                        {
                            subtitle: 'Informatique',
                            subtitleText:
                                "Compléments d'algorithmique ; Réalisation d'un projet.",
                        },
                        {
                            subtitle: 'Français-Philo',
                            subtitleText:
                                "L'enseignement de français et de philosophie dans les classes préparatoires scientifiques durant l'année scolaire s'appuie notamment sur des thèmes étudiés à travers les œuvres littéraires et philosophiques. Exemples : GEORG SIMMEL (La philosophie de l'argent) ; ÉMILE ZOLA (L'argent) ; MOLIERE (L'avare) ; Saint Augustin (Confessions) ; Musset (Lorenzaccio) ; Michel Leiris (L'Âge d'homme) ; CHATEAUBRIAND (Les mémoires d'outre-tombe) ; MALEBRANCHE (Recherche de la vérité).",
                        },
                        {
                            subtitle: 'Langues Vivantes 1 & 2',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Prépa ECS',
            introduction: `Les classes préparatoires économiques et commerciales option Scientifiques préparent en 2 ans aux concours des écoles supérieures de management, de commerce et de gestion ou encore des Écoles d'Actuariat et Statistique.
                    L'accent est mis sur les mathématiques, ainsi que sur les langues, l'Histoire et la culture générale. Un bon dossier scolaire est requis. Il faut un bon niveau en mathématiques, Il s'agit d'avoir à la fois une certaine curiosité intellectuelle associée à une grande capacité de travail.`,
            volume: [
                {
                    title: 'PRÉPA ECS 1',
                    contenu: [
                        {
                            subtitle: 'Mathématiques',
                            subtitleText:
                                "Raisonnement Ensembliste ; Nombres complexes et Polynômes ; Algèbre linéaire 1 & 2 ; Suites de nombres réels ; Fonctions réelles d'une variable réelle ; Probabilités sur un univers fini ; Compléments d'analyse ; Probabilités sur un univers quelconque.",
                        },
                        {
                            subtitle: 'Histoire et Géographie Économiques',
                            subtitleText:
                                'Module I (Les grandes Mutations du monde 20eme siècle (de 1913 au début des années 1990)) ; Module 2 (La mondialisation contemporaine : rapports de force et enjeux).',
                        },
                        {
                            subtitle: 'Culture générale',
                            subtitleText:
                                "L'héritage de la pensée grecque et latine ; les apports du judaïsme, du christianisme et de l'islam à la pensée occidentale ; les étapes de la constitution des sciences exactes et des sciences de l'homme ; L'essor technologique et l'idée de progrès ; La société, le droit et l'état moderne ; Les figures du moi et la question du sujet depuis la renaissance ; L'esprit des lumières et leur destin ; Quelques grands courants artistiques et esthétiques depuis la renaissance ; Les principaux courants idéologiques contemporains.",
                        },
                        {
                            subtitle: 'Economie',
                            subtitleText:
                                "Module I : comprendre l'analyse économique : Éléments d'histoire de la pensée économique, l'économie de marché, la monnaie. Module II : comprendre les enjeux européens ou africains dans le cadre de la mondialisation : le commerce international ; l'intégration européenne, le système monétaire et financier international.",
                        },
                        {
                            subtitle: 'Informatique et Algorithmique',
                            subtitleText:
                                "Éléments  d'informatique et d'algorithme :environnement logiciel Scilab, graphisme en deux dimensions, programmation d'algorithme et de fonctions ; Liste de savoir faires exigibles en première année : calcul des termes d'une suite, calcul des valeurs approchées de la limite d'une suite ou de la somme d'une série, calcul approche de la racine d'une équation du type, calcul des valeurs approchées d'une intégrale par la méthode des rectangles, utilisation de la fonction rand des expériences aléatoires élémentaires conduisant à une loi usuelle, simulation de phénomènes aléatoires, résolution de systèmes.",
                        },
                        {
                            subtitle: 'Langues Vivantes 1 & 2',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'PRÉPA ECS 2',
                    contenu: [
                        {
                            subtitle: 'Mathématiques',
                            subtitleText:
                                "Algèbre linéaire ; Algèbre bilinéaire ; Intégrales sur un intervalle quelconque ; Fonctions numériques de plusieurs variables ; Statistique descriptive bivariée ; Probabilités ; Éléments d'algorithmique.",
                        },
                        {
                            subtitle: 'Histoire et Géographie Économiques',
                            subtitleText:
                                "Module III (Géodynamique continentale de l'Europe, de l'Afrique, du proche et moyen Orient) ; Module IV (Géodynamique continentale de l'Amérique et de l'Asie).",
                        },
                        {
                            subtitle: 'Culture générale',
                            subtitleText:
                                "Étude d'un thème, renouvelé chaque année pour le concours. Possibilité de sujets hors <<thème>> lors de l'épreuve de culture générale : La vie ; Le corps ; Le bonheur ; La sensibilité ; Autrui ; Le mal ; La représentation ; L'échange ; La croyance ; La passion ; La justice ; La science ; L'action ; La beauté.",
                        },
                        {
                            subtitle: 'Economie',
                            subtitleText:
                                'Module III : comprendre la croissance et les crises : la croissance, les crises.  Module IV : comprendre les politiques économiques : les politiques économiques (enjeux et modalités), les politiques économiques en action.',
                        },
                        {
                            subtitle: 'Informatique et Algorithmique',
                            subtitleText:
                                'Travaux pratiques de mathématiques avec Scilab : Savoir-faire et compétences, nouvelles commandes, statistiques descriptives univariées, statistiques descriptives bivariées, chaînes de Markov, fonctions de plusieurs variables, simulation de lois, estimation ponctuelle et par intervalle de confiance.',
                        },
                        {
                            subtitle: 'Langues Vivantes 1 & 2',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Prépa MPSI',
            introduction: `La prépa MPSI (mathématiques, physique et sciences de l'ingénieur) s'adresse aux élèves qui aiment les maths et la physique, à l'aise avec l'abstraction. À privilégier aussi pour ceux qui sont intéressés par l'informatique. La formation est conçue en fonction de quatre objectifs essentiels :
    
                    - Développer conjointement l'intuition, l'imagination, le raisonnement, et la rigueur.
                    
                    - Promouvoir la réflexion personnelle des étudiants sur les problèmes et les phénomènes, sur la portée des concepts, des hypothèses, des résultats et des méthodes, au moyen d'exemples et de contre-exemples ; développer ainsi une attitude de questionnement et de recherche.
                    
                    - Exploiter toute la richesse de la démarche scientifique : analyser un problème, expérimenter sur des exemples, formuler une conjecture, élaborer et mettre en œuvre des concepts et des résultats théoriques, rédiger une solution rigoureuse, contrôler les résultats obtenus et évaluer la pertinence des concepts et des résultats au regard du problème posé, sont des éléments indissociables de cette démarche; valoriser ainsi l'interaction entre d'une part l'étude de phénomènes et de problèmes scientifiques, et d'autre part l'élaboration et la mise en œuvre des concepts théoriques, les phases d'abstraction et de mise en théorie interagissant donc constamment avec celles de passage aux exemples et aux applications.
                    
                    - Privilégier les problèmes scientifiques susceptibles de développer la réflexion personnelle des étudiants et les capacités de synthèse. En particulier, on ne saurait en aucun cas se limiter à l'étude de problèmes dont les énoncés sont fermés et d'exercices mettant en œuvre des techniques bien répertoriées. Il est nécessaire d'entraîner les étudiants à se poser eux–mêmes des questions, c'est–à–dire à prendre en compte une problématique scientifique ; l'effort de synthèse doit constituer l'aboutissement de cette démarche. Les travaux d'initiative personnelle encadrés permettent de renforcer cette attitude, essentielle pour la formation scientifique, laquelle est par nature d'abord un questionnement.`,
            volume: [
                {
                    title: 'PRÉPA MPSI',
                    contenu: [
                        {
                            subtitle: 'Mathématiques',
                            subtitleText:
                                "Raisonnement et vocabulaire ensembliste ; Calculs algébriques ; Nombres complexes et trigonométrie ; Techniques fondamentales de calcul en analyse ; Nombres réels et suites numériques ; Limites, continuité, dérivabilité ; Analyse asymptotique ; Arithmétique dans l'ensemble des entiers relatifs ; Structures algébriques usuelles ; Polynômes et fractions rationnelles ; Espaces vectoriels et applications linéaires ; Matrices ; Groupe symétrique et déterminants ; Espaces préhilbertiens réels ; Intégration ; Séries numériques ; Dénombrement ; Probabilités.",
                        },
                        {
                            subtitle: 'Physique',
                            subtitleText:
                                "Circuits électriques dans l'ARQS ; Théorèmes de bases d'analyse de circuits électriques ; Circuits linéaires du premier ordre ; Circuit linéaire du second ordre ; Régime sinusoïdal ; Filtrage linéaire ; Amplificateur Opérationnel ; Oscillateur harmonique ; Propagation d'un signal ; Superpositions de deux signaux sinusoïdaux ; Introduction au monde quantique. Cinématique du point et du solide indéformable ; Dynamique du point matériel ; Particule chargées dans les champs électrique et magnétique, uniformes et stationnaires ; Moment cinétique et applications ; Mouvements dans un champ de force centrale conservatif ; Description macroscopique d'un système à l'équilibre ; Échanges d'énergie au cours d'une transformation ; Premier principe, Bilans d'énergie ; Deuxième principe, Bilans d'entropie ; Machines thermiques ; Champ magnétique ; Actions d'un champ magnétique ; Lois de l'induction ; Circuit fixe dans un champ magnétique dépendant du temps ; Circuit mobile dans un champ magnétique stationnaire. Travaux pratiques.",
                        },
                        {
                            subtitle: 'Chimie',
                            subtitleText:
                                "Transformation de la matière : description d'un système et évolution vers un état final, évolution temporelle d'un système chimique et mécanismes réactionnels ; Architecture de la matière : classification périodique des éléments et électronégativité, molécules et solvants ; Architecture de la matière condensée : solides cristallins ; Transformations chimiques en solution aqueuse : réactions d'oxydo-réduction, réactions acide-base et de précipitation, diagrammes potentiel-pH. Travaux pratiques.",
                        },
                        {
                            subtitle: 'Sciences Industrielles',
                            subtitleText:
                                "Identifier le besoin et les exigences ; Appréhender les analyses fonctionnelle et structurelle ; Caractériser des écarts ; Apprécier la pertinence et la validité des résultats ; Proposer un modèle de connaissance et de comportement ; Procéder à la mise en œuvre d'une démarche de résolution analytique ; Définir les frontières de l'analyse ; Appréhender les analyses fonctionnelle et structurelle ; Identifier et caractériser les grandeurs physiques ; Mettre en œuvre une communication ; Rechercher et traiter des informations ; Travaux  pratiques.",
                        },
                        {
                            subtitle: 'Français et Philosophie',
                            subtitleText:
                                "L'enseignement de français et de philosophie dans les classes préparatoires scientifiques durant l'année scolaire s'appuie notamment sur les thèmes suivants, étudiés à travers les œuvres littéraires et philosophiques.",
                        },
                        {
                            subtitle: 'Informatique',
                            subtitleText:
                                "Présentation du système informatique utilise et éléments d'architecture des ordinateurs ; Représentation des nombres et conséquences ; Algorithmique et programmation I (langage Python) ; Simulation numérique ; Initiation aux bases de données.",
                        },
                        {
                            subtitle: 'Langues Vivantes 1 & 2',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'PRÉPA MP',
                    contenu: [
                        {
                            subtitle: 'Mathématiques',
                            subtitleText:
                                'Structures algébriques usuelles ; Réduction des endomorphismes et des matrices carrées ; Fonctions convexes ; Espaces préhilbertiens réels. Endomorphismes des espaces euclidiens ; Séries et familles sommables ; Suites et séries de fonctions, Séries entières ; Fonctions vectorielles, arcs paramétrés ; Intégration sur un intervalle quelconque ; Variables aléatoires discrètes ; Équations différentielles linéaires ; Calcul différentiel.',
                        },
                        {
                            subtitle: 'Physique',
                            subtitleText:
                                'Mécanique ; Éléments de traitement du signal ; optique ; électromagnétisme : électrostatique, magnétostatique, équations de maxwell, énergie du champ électromagnétique, propagation et rayonnement ; Thermodynamique ; Physique quantique ; Éléments de thermodynamique statistique.',
                        },
                        {
                            subtitle: 'Chimie',
                            subtitleText:
                                'Thermodynamique de la transformation chimique ; Électrochimie.',
                        },
                        {
                            subtitle: 'Sciences Industrielles',
                            subtitleText:
                                "Identifier et caractériser les grandeurs physiques ; Proposer un modèle de connaissance et de comportement. Valider un modèle ; Proposer une démarche de résolution ; Procéder à la mise en œuvre d'une démarche de résolution analytique ; Appréhender les analyses fonctionnelle et structurelle ; Caractériser des écarts ; Apprécier la pertinence et la validité des résultats ; Rechercher et traiter des informations ; Mettre en œuvre une communication.",
                        },
                        {
                            subtitle: 'Français et Philosophie',
                            subtitleText:
                                "L'enseignement de français et de philosophie dans les classes préparatoires scientifiques durant l'année scolaire s'appuie notamment sur les thèmes suivants, étudiés à travers les œuvres littéraires et philosophiques.",
                        },
                        {
                            subtitle: 'Informatique',
                            subtitleText:
                                'Algorithmique et programmation II (langage Python).',
                        },
                        {
                            subtitle: 'Langues Vivantes 1 & 2',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Prépa PCSI',
            introduction: (
                        <>
                            Ouverte à la rentrée académique 2022-2023, la prépa PCSI est destinée aux bacheliers généraux (BAC C, D et E). Elle s’adresse aux élèves intéressés par une approche des sciences fondamentales fondée sur l’expérimentation et la modélisation, désirant comprendre le fonctionnement des différents objets et ayant des facilités pour la manipulation. C’est aussi la voie à privilégier pour les élèves attirés par la chimie. L’objectif de cette filière est de préparer en 02 ans, les meilleurs élèves pour intégrer 
                            une grande école d’ingénieur dans le domaine industriel
            
            
                        </>
            ),
            volume: [
                {
                    title: 'Les compétences techniques et scientifiques des élèves en PCSI sont de :',
                    contenu: [
                        {
                            subtitle: 'Observer et s’approprier une problématique complexe ;',
                            // subtitleText:
                            //     "Raisonnement et vocabulaire ensembliste ; Calculs algébriques ; Nombres complexes et trigonométrie ; Techniques fondamentales de calcul en analyse ; Nombres réels et suites numériques ; Limites, continuité, dérivabilité ; Analyse asymptotique ; Arithmétique dans l'ensemble des entiers relatifs ; Structures algébriques usuelles ; Polynômes et fractions rationnelles ; Espaces vectoriels et applications linéaires ; Matrices ; Groupe symétrique et déterminants ; Espaces préhilbertiens réels ; Intégration ; Séries numériques ; Dénombrement ; Probabilités.",
                        },
                        {
                            subtitle: 'Analyser et modéliser ;',
                            // subtitleText:
                            //     "Circuits électriques dans l'ARQS ; Théorèmes de bases d'analyse de circuits électriques ; Circuits linéaires du premier ordre ; Circuit linéaire du second ordre ; Régime sinusoïdal ; Filtrage linéaire ; Amplificateur Opérationnel ; Oscillateur harmonique ; Propagation d'un signal ; Superpositions de deux signaux sinusoïdaux ; Introduction au monde quantique. Cinématique du point et du solide indéformable ; Dynamique du point matériel ; Particule chargées dans les champs électrique et magnétique, uniformes et stationnaires ; Moment cinétique et applications ; Mouvements dans un champ de force centrale conservatif ; Description macroscopique d'un système à l'équilibre ; Échanges d'énergie au cours d'une transformation ; Premier principe, Bilans d'énergie ; Deuxième principe, Bilans d'entropie ; Machines thermiques ; Champ magnétique ; Actions d'un champ magnétique ; Lois de l'induction ; Circuit fixe dans un champ magnétique dépendant du temps ; Circuit mobile dans un champ magnétique stationnaire. Travaux pratiques.",
                        },
                        {
                            subtitle: 'Valider ;',
                            // subtitleText:
                            //     "Transformation de la matière : description d'un système et évolution vers un état final, évolution temporelle d'un système chimique et mécanismes réactionnels ; Architecture de la matière : classification périodique des éléments et électronégativité, molécules et solvants ; Architecture de la matière condensée : solides cristallins ; Transformations chimiques en solution aqueuse : réactions d'oxydo-réduction, réactions acide-base et de précipitation, diagrammes potentiel-pH. Travaux pratiques.",
                        },
                        {
                            subtitle: 'Réaliser et créer.',
                            // subtitleText:
                            //     "Identifier le besoin et les exigences ; Appréhender les analyses fonctionnelle et structurelle ; Caractériser des écarts ; Apprécier la pertinence et la validité des résultats ; Proposer un modèle de connaissance et de comportement ; Procéder à la mise en œuvre d'une démarche de résolution analytique ; Définir les frontières de l'analyse ; Appréhender les analyses fonctionnelle et structurelle ; Identifier et caractériser les grandeurs physiques ; Mettre en œuvre une communication ; Rechercher et traiter des informations ; Travaux  pratiques.",
                        },

                    ],
                },
                {
                    title: 'Les élèves sont appelés également à développer d’autres compétences telles que :',
                    contenu: [
                        {
                            subtitle: 'Communiquer à l’écrit et à l’oral en anglais,',
                            // subtitleText:
                            //     'Structures algébriques usuelles ; Réduction des endomorphismes et des matrices carrées ; Fonctions convexes ; Espaces préhilbertiens réels. Endomorphismes des espaces euclidiens ; Séries et familles sommables ; Suites et séries de fonctions, Séries entières ; Fonctions vectorielles, arcs paramétrés ; Intégration sur un intervalle quelconque ; Variables aléatoires discrètes ; Équations différentielles linéaires ; Calcul différentiel.',
                        },
                        {
                            subtitle: 'Etre autonome et faire preuve d’initiative ;',
                            // subtitleText:
                            //     'Mécanique ; Éléments de traitement du signal ; optique ; électromagnétisme : électrostatique, magnétostatique, équations de maxwell, énergie du champ électromagnétique, propagation et rayonnement ; Thermodynamique ; Physique quantique ; Éléments de thermodynamique statistique.',
                        },
                        {
                            subtitle: 'Développer sa personnalité, son esprit du collectif ;',
                            // subtitleText:
                            //     'Thermodynamique de la transformation chimique ; Électrochimie.',
                        },
                        {
                            subtitle: 'Cultiver sa persévérance, son adaptabilité, son organisation, son gout du challenge, sa créativité et sa patience.',
                            // subtitleText:
                            //     "Identifier et caractériser les grandeurs physiques ; Proposer un modèle de connaissance et de comportement. Valider un modèle ; Proposer une démarche de résolution ; Procéder à la mise en œuvre d'une démarche de résolution analytique ; Appréhender les analyses fonctionnelle et structurelle ; Caractériser des écarts ; Apprécier la pertinence et la validité des résultats ; Rechercher et traiter des informations ; Mettre en œuvre une communication.",
                        },

                    ],
                },
                {
                    title: 'Matières enseignées :',
                    contenu: [
                        {
                            subtitle: 'Physique, chimie, Mathématiques, sciences de l’Ingénieur, informatique, Sport, français, anglais, TIPE (Travaux d’Initiative Personnel Encadré). Il est prévu également des Cours Magistraux (CM), des Travaux Dirigés (TD), Travaux Pratiques (TP), des colles et des devoirs surveillés.',
                            // subtitleText:
                            //     'Structures algébriques usuelles ; Réduction des endomorphismes et des matrices carrées ; Fonctions convexes ; Espaces préhilbertiens réels. Endomorphismes des espaces euclidiens ; Séries et familles sommables ; Suites et séries de fonctions, Séries entières ; Fonctions vectorielles, arcs paramétrés ; Intégration sur un intervalle quelconque ; Variables aléatoires discrètes ; Équations différentielles linéaires ; Calcul différentiel.',
                        },


                    ],
                },
                {
                    title: 'Partenaires académiques :',
                    contenu: [
                        {
                            subtitle: ( <>
                                            Lycée Louis Le Grand, <br />
                                             Lycée Français Blaise Pascal d’Abidjan, <br />
                                              Ecole Polytechnique de Paris

                                        </>
                                        ),
                            // subtitleText:
                            //     'Structures algébriques usuelles ; Réduction des endomorphismes et des matrices carrées ; Fonctions convexes ; Espaces préhilbertiens réels. Endomorphismes des espaces euclidiens ; Séries et familles sommables ; Suites et séries de fonctions, Séries entières ; Fonctions vectorielles, arcs paramétrés ; Intégration sur un intervalle quelconque ; Variables aléatoires discrètes ; Équations différentielles linéaires ; Calcul différentiel.',
                        },


                    ],
                },
            ],
        },

        
    ],
    ESCAE: [
        {
            filiere: 'Gestion et Administration des Entreprises - GAE',
            introduction:
                "BAC A1 - BAC A2 - BAC B - BAC C - BAC D ",
            volume: [
                {
                    title: 'Gestion Commerce (GC)',
                    contenu: [
                        // {
                        //     subtitle: 'Langue et sciences humaines 180 H',
                        //     subtitleText:
                        //         'Gestion des ressources humaines ; EPS ; TEF ; Psychologie industrielle.',
                        // },
                        // {
                        //     subtitle: 'Mathématique, informatique 120 H',
                        //     subtitleText:
                        //         'Mathématique ; Statistique-Probabilité ; Informatique.',
                        // },
                        // {
                        //     subtitle: 'Finance, comptabilité, droit 140 H',
                        //     subtitleText:
                        //         'Droit civil ; Comptabilité analytique ; Comptabilité générale.',
                        // },
                        // {
                        //     subtitle:
                        //         'Gestion commerce, économie appliquée 330 H',
                        //     subtitleText:
                        //         "Évolution des structures économiques ; Économie générale ; Étude du marché ; Technique commerciale ; Gestion des stocks ; Gestion des productions ; Économie d'entreprise ; Logistique.",
                        // },
                    ],
                },
                {
                    title: 'Marketing et Communication (MC) ',
                    contenu: [
                        // {
                        //     subtitle: 'Langue et sciences humaines 170 H',
                        //     subtitleText:
                        //         "Technique d'expression ; Méthodologie de la recherche ; Anglais ; Communication d'entreprise ; EPS.",
                        // },
                        // {
                        //     subtitle: 'Mathématique, informatique 164 H',
                        //     subtitleText:
                        //         'Mathématique financière ; TAD ; Recherche opérationnelle ; Informatique ; Utilisation des pro-logiciels.',
                        // },
                        // {
                        //     subtitle: 'Finance, comptabilité, droit 220 H',
                        //     subtitleText:
                        //         'Droit des affaires ; Droit du travail ; Comptabilité des sociétés ; Fiscalité ; Contrôle commercial ; Analyse financière.',
                        // },
                        // {
                        //     subtitle:
                        //         'Gestion commerce, économie appliquée 260 H',
                        //     subtitleText:
                        //         'Marketing ; Vente et force de vente ; Statistique appliquée ; Économie de développement ; Économie interne.',
                        // },
                    ],
                },
                {
                    title: 'Gestion des Ressources Humaines (GRH)',
                    contenu: [
                        // {
                        //     subtitle: 'Langue et sciences humaines 100 H',
                        //     subtitleText:
                        //         'Sociologie de travail ; EPS ; Anglais.',
                        // },
                        // {
                        //     subtitle:
                        //         'Gestion commerce, économie appliquée 350 H',
                        //     subtitleText:
                        //         "Commerce international ; Transit douane ; Gestion des points de vente ; Étude du marché ; Audit marketing ; Économie ivoirienne ; Marché financier ; Création d'entreprise.",
                        // },
                        // {
                        //     subtitle: 'Stage 10 semaines minimum',
                        //     subtitleText: '',
                        // },
                    ],
                },
                {
                    title: 'Gestion de Supply Chain (GSC)',
                    contenu: [
                        // {
                        //     subtitle: 'Langue et sciences humaines 100 H',
                        //     subtitleText:
                        //         'Sociologie de travail ; EPS ; Anglais.',
                        // },
                        // {
                        //     subtitle:
                        //         'Gestion commerce, économie appliquée 350 H',
                        //     subtitleText:
                        //         "Commerce international ; Transit douane ; Gestion des points de vente ; Étude du marché ; Audit marketing ; Économie ivoirienne ; Marché financier ; Création d'entreprise.",
                        // },
                        // {
                        //     subtitle: 'Stage 10 semaines minimum',
                        //     subtitleText: '',
                        // },
                    ],
                },
            ],
        },
        {
            filiere: 'Finance, Comptabilité et Assurance',
            introduction:
                "BAC G2 - BT COMPTA - BAC A1 - BAC A2 - BAC B - BAC C - BAC D",
            volume: [
                {
                    title: 'Audit, Comptabilité et Contrôle de Gestion (ACCG) ',
                    contenu: [
                        // {
                        //     subtitle: 'Langue et sciences humaines 180 H',
                        //     subtitleText:
                        //         'Gestion des ressources humaines ; EPS ; TEF ; Psychologie industrielle.',
                        // },
                        // {
                        //     subtitle: 'Mathématique, informatique 120 H',
                        //     subtitleText:
                        //         'Mathématique ; Statistique-Probabilité ; Informatique.',
                        // },
                        // {
                        //     subtitle: 'Finance, comptabilité, droit 140 H',
                        //     subtitleText:
                        //         'Droit civil ; Comptabilité analytique ; Comptabilité générale.',
                        // },
                        // {
                        //     subtitle:
                        //         'Gestion commerce, économie appliquée 330 H',
                        //     subtitleText:
                        //         "Évolution des structures économiques ; Économie générale ; Étude du marché ; Technique commerciale ; Gestion des stocks ; Gestion des productions ; Économie d'entreprise ; Logistique.",
                        // },
                    ],
                },
                {
                    title: 'Banque, Finances et Assurance (BFA)',
                    contenu: [
                        // {
                        //     subtitle: 'Langue et sciences humaines 130 H',
                        //     subtitleText:
                        //         "Technique d'expression ; Méthodologie de la recherche ; Anglais ; EPS.",
                        // },
                        // {
                        //     subtitle: 'Mathématique, informatique 130 H',
                        //     subtitleText:
                        //         'TAD ; Recherche opérationnelle ; Informatique.',
                        // },
                        // {
                        //     subtitle: 'Finance, comptabilité, droit 180 H',
                        //     subtitleText:
                        //         'Droit commercial ; Droit du travail ; Gestion budgétaire ; Fiscalité ; Analyse financière.',
                        // },
                        // {
                        //     subtitle:
                        //         'Gestion commerce, économie appliquée 430 H',
                        //     subtitleText:
                        //         'Transit douane ; Economie internationale ; Gestion budgétaire ; Economie des transports ; Logistique ; Marketing des services ; Gestion des stocks ; Gestion des productions ; Statistique appliquée.',
                        // },
                    ],
                },
                // {
                //     title: 'Volume horaire 3ème année',
                //     contenu: [
                //         {
                //             subtitle: 'Langue et sciences humaines 54 H',
                //             subtitleText:
                //                 'Méthodologie de la recherche ; Anglais.',
                //         },
                //         {
                //             subtitle: 'Mathématique, informatique 40 H',
                //             subtitleText: 'Statistiques.',
                //         },
                //         {
                //             subtitle: 'Finance, comptabilité, droit 120 H',
                //             subtitleText:
                //                 'Transit douane ; Assurance des transports ; Droit des transports.',
                //         },
                //         {
                //             subtitle:
                //                 'Gestion commerce, économie appliquée 360 H',
                //             subtitleText:
                //                 "Organisation du marché des transports ; Transport maritime ; Economie des transports ; Economie ivoirienne ; Manutention et emballage ; Création d'entreprise ; Logistique internationale.",
                //         },
                //         {
                //             subtitle: 'Stage 10 semaines minimum',
                //             subtitleText: '',
                //         },
                //     ],
                // },
            ],
        },

        

    ],
    ESA: [
        {
            filiere: 'Techniciens Supérieurs en Agronomie - TSA',
            introduction:
                "Être technicien supérieur en agronomie, c'est pouvoir résoudre des problèmes complexes de la production agricole, de sa transformation et de sa mise en marché. On s'inscrit donc dans des logiques de développement durable, de filière et de territoires ruraux, en prenant en compte les aspects environnementaux, économiques et sociaux. En gros, la formation se résume en 9 rubriques : 1.la production végétale, 2.les productions animales, 3. environnement-agriculture, 4. agroalimentaire, 5. équipement-machinisme, 6. informatique, 7. économie-filière, 8. économie-territoire et 9. communication. En sortant de cette école, beaucoup de fonctions et métiers sont accessibles : technicien dans l'environnement, chef d'exploitation, responsable de production, technicien qualité, chargé d'études économiques, recherche/développement, technico/commercial, conseiller de gestion, etc",
            volume: [
                {
                    title: 'Tronc commun (1ère et 2ème année)',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 85 H ',
                            subtitleText:
                                "Rédaction de rapports d'études, Comptes rendus ; Méthodologie de la recherche documentaire ; Initiation à la technologie audiovisuelle ; Anglais technique.",
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 536 H',
                            subtitleText:
                                "Méthodologie de l'exposé oral ; Méthodologie d'élaboration d'un plan d'action ; Initiation à l'outil informatique ; Diagnostic en milieu rural. Étude de la plante : classification, anatomie, biologie ; Présentation et description du règne animal ; Anatomie-Physiologie de la Digestion ; Anatomie-physiologie de la Reproduction ; Anatomie-physiologie de la lactation ; Zootechnie générale Fertilisation; Entomologie ; Phytopathologie ; Récolte-Conditionnement, Conservation des produits végétaux (denrées stockées) ; Conditionnement-conservation des produits animaux ; Principes d'alimentation des animaux domestiques (élevages) ; Principes de construction des habitats des animaux domestiques ; Principes d'amélioration du cheptel ; Informatique ; Système d'élevage ; Facteurs de production et itinéraires techniques ; Système agraires ; Vulgarisation agricole.",
                        },
                        {
                            subtitle: 'Finance, comptabilité 50 H',
                            subtitleText:
                                'Comptabilité générale ; Comptabilité analytique.',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 235 H',
                            subtitleText:
                                "Monde rural, métiers ruraux et organisations professionnelles agricoles ; Statistiques descriptives (changement d'intitulé) ; Enquêtes et analyse statistiques (changement d'intitulé) ; Socio-démographie ; Flux Migrations; Economie nationale et internationale ; Prix de revient-Commercialisation-Distribution ; Notion d'entreprise et d'exploitation ;  Construction d'un programme de formation agricole ; Gestion des exploitations agricoles.",
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 83 H',
                            subtitleText:
                                "Agroclimatologie ; Pédologie ; Récolte Conditionnement, Conservation des produits forestiers ; Gestion de l'environnement.",
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 150 H',
                            subtitleText:
                                'Topographie ; Hydrologie, hydrographie agricole ; Énergie et travail en agriculture (défrichement) ; Lutte antiérosive; Hydraulique agricole ; Irrigation.',
                        },
                        {
                            subtitle: 'Génie chimique et agroalimentaire 74 H',
                            subtitleText: 'Monde microscopique.',
                        },
                        {
                            subtitle: 'Exploitations villageoises 60 jours',
                            subtitleText:
                                'Mise en place et entretien des productions animales ; Analyse des composantes du milieu rural.',
                        },
                        {
                            subtitle: 'Journées carrières 2 jours',
                            subtitleText: 'Conférences-débats.',
                        },
                        {
                            subtitle: 'Exploitations 40 jours',
                            subtitleText: "Analyse d'exploitation.",
                        },
                        {
                            subtitle: 'Stage 40 jours',
                            subtitleText: "Stage d'orientation.",
                        },
                    ],
                },
                {
                    title: '3ème année Technique des Eaux et Forêts',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 20 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 120 H',
                            subtitleText:
                                'Pêche et Pisciculture ; Pathologies forestières et ennemis des plantes ; Analyse des projets.',
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 480 H',
                            subtitleText:
                                "Pyrologie forestière ; Aménagement des parcs nationaux ; Écologie forestière ; Gestion de l'environnement ; Connaissance de la faune ; Xylologie et séchage du bois ; Pédologie forestière ; Sylviculture générale ; Législation et politique forestière ; Economie forestière et des ressources nationales ; Exploitation forestière ; Reboisement ; Inventaire forestier ; Dendrométrie ; Dendrologie ; Technologie du bois ; Agroforesterie ; Aménagement forestier ; Biométrie.",
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 38 H',
                            subtitleText: 'Topographie.',
                        },
                        {
                            subtitle:
                                'Sciences de la terre et des ressources minières 60 H',
                            subtitleText: 'Télédétection et Cartographie.',
                        },
                        {
                            subtitle: "Stage 40 jours (Stage d'orientation)",
                            subtitleText:
                                "Voyage d'étude 1 sem ; PFE 90 jours.",
                        },
                    ],
                },
                {
                    title: '3ème année Machinisme agricole et aménagement rural',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 20 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 325 H',
                            subtitleText:
                                "Construction Rurale ; Calcul de réseaux par aspersion ; Dessin technique ; Entretien et réparation du matériel-Exploitation d'un prospectus ; Gestion et coûts des opérations mécanisées ; Hydraulique agricole ; Matériels d'entretien Post récolte ; Matériels et travaux agro-mécaniques ; Moteurs et transmissions mécaniques ; Informatique (autocad) ; Marketing et actions commerciales ; Petits barrages en terre ; Organisation et gestion d'un garage ; Planification des irrigations ; Projets d'irrigation ; Relation eau-sol-plantes ; Résolutions numériques ; Riziculture irriguée.",
                        },
                        {
                            subtitle: 'Génie électrique 10 H',
                            subtitleText: 'Électricité automobile',
                        },
                        {
                            subtitle:
                                'Sciences de la terre et des ressources minières 20 H',
                            subtitleText: 'Mécanique des sols',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 70 H',
                            subtitleText:
                                'Analyse économique des projets agricoles ; Programmation linéaire',
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 12 H',
                            subtitleText: 'Résistance des matériaux.',
                        },
                        {
                            subtitle: 'Stage 40 jours',
                            subtitleText:
                                'Stage FS2 + FS3 : maîtrise Comp. ; Gestion Com.',
                        },
                        {
                            subtitle: 'PFE 90 jours',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: '3ème année Machinisme agricole et aménagement rural',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 20 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 325 H',
                            subtitleText:
                                "Construction Rurale ; Calcul de réseaux par aspersion ; Dessin technique ; Entretien et réparation du matériel-Exploitation d'un prospectus ; Gestion et coûts des opérations mécanisées ; Hydraulique agricole ; Matériels d'entretien Post récolte ; Matériels et travaux agro-mécaniques ; Moteurs et transmissions mécaniques ; Informatique (autocad) ; Marketing et actions commerciales ; Petits barrages en terre ; Organisation et gestion d'un garage ; Planification des irrigations ; Projets d'irrigation ; Relation eau-sol-plantes ; Résolutions numériques ; Riziculture irriguée.",
                        },
                        {
                            subtitle: 'Génie électrique 10 H',
                            subtitleText: 'Électricité automobile',
                        },
                        {
                            subtitle:
                                'Sciences de la terre et des ressources minières 20 H',
                            subtitleText: 'Mécanique des sols',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 70 H',
                            subtitleText:
                                'Analyse économique des projets agricoles ; Programmation linéaire',
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 12 H',
                            subtitleText: 'Résistance des matériaux.',
                        },
                        {
                            subtitle: 'Stage 40 jours',
                            subtitleText:
                                'Stage FS2 + FS3 : maîtrise Comp. ; Gestion Com.',
                        },
                        {
                            subtitle: 'PFE 90 jours',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: '3ème année Agriculture générale',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 21 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 448 H',
                            subtitleText:
                                "Amélioration des plantes ; Analyse économique des projets agricoles ; Biométrie agricoles ; Caractérisation des parcours naturels et fourrages cultivés : Cultures de l'anacardier ; Cultures spécifiques (maraichers) ; Entomologie ; Écologie générale ; Fertilisation, formulation des engrais ; Programmation linéaire (cours comm.) ; Lutte intégrée contre les ravageurs ; Les outils de prise décision ; Informatique (cours comm) ;  Malherbologie ; Phytopathologie ; Sériciculture (vers à soie) ; Législation foncière ; Topographie appliquée.",
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 63 H',
                            subtitleText:
                                'Formulation des produits phytosanitaires ; Horticulture et aménagement urbain ; Pyrologie.',
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 42 H',
                            subtitleText:
                                'Planification en Irrigation ; Coût du matériel et opérations agricoles ; Matériels et travaux agricoles mécanisés.',
                        },
                        {
                            subtitle:
                                'Gestion commerce, économie appliquée 13 H',
                            subtitleText: 'Marketing (cours comm).',
                        },
                        {
                            subtitle: 'Stage 70 jours',
                            subtitleText:
                                'FS2 : maîtrise des Compétences techniques-travaux Pratiques ; FS3 : maîtrise Gestion com.',
                        },
                        {
                            subtitle: 'PFE 90 jours',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: '3ème année Eau, forêt et environnement',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 20 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 504 H',
                            subtitleText:
                                "Dendrométrie ; Dendrologie ; Écologie forestière ; Aménagement des parcs nationaux ; Contrôle des feux ; Connaissance de la faune ; Xylologie et séchage du bois ; Agroforesterie ; Aménagement forestier ; Gestion de l'environnement ; Biométrie forestière ; Economie des ressources naturelles ; Pédologie forestière ; Pyrologie forestière ; Reboisement ; Sylviculture générale ; Exploitation forestière ; Cartographie et Télédétection ; Technologie du bois.",
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 190 H',
                            subtitleText:
                                'Micologie pathologie des plantes forestières ; Ennemis des plantes ; Législation et politique forestière ; Inventaire forestier ; Pêche, Aquaculture et Pisciculture ; Informatique.',
                        },
                        {
                            subtitle:
                                'Génie rural et sciences géographiques 30 H',
                            subtitleText: 'Topographie.',
                        },
                        {
                            subtitle: 'Visite de chantiers 4 jours',
                            subtitleText: '',
                        },
                        {
                            subtitle: 'Stage 30 jours',
                            subtitleText:
                                "Stage d'entreprise et Communication.",
                        },
                        {
                            subtitle: 'PFE 90 jours.',
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: '3ème année Élevage',
                    contenu: [
                        {
                            subtitle: 'Langue et sciences humaines 21 H',
                            subtitleText: 'Gestion des ressources humaines.',
                        },
                        {
                            subtitle:
                                'Agriculture et ressources animales 545 H',
                            subtitleText:
                                "Biométrie Agricole ; Programmation linéaire (cours comm) ; Analyse bromatologique des aliments TP ; Caractérisation des formations végétales pâturables ; Parcours naturels et cultures fourragères ; Élevage des abeilles (apiculture) ; Élevage avicole ; Élevage Bovin/viande ; Élevage d'aulacodes ; Élevage de lapin ; Élevage d'escargots ; Élevage du vers à soie ; Élevage ovin/caprin ; Informatique (cours comm) ; Filière Porcine ; Législation foncière ; Législation Zoosanitaire ; Marketing (cours comm) ; Étude de projets ; Génie Piscicole ; Valeur nutritive des aliments du bétail ; Systématique et reconnaissance des plantes fourragères ; Production laitière ; Système d'élevage.",
                        },
                        {
                            subtitle: 'Eaux, forêts et environnement 28 H',
                            subtitleText:
                                'Connaissance et Protection de la faune.',
                        },
                        {
                            subtitle: 'Stage 55 jours',
                            subtitleText:
                                'Visite en entreprise ; Stage FS3 : maîtrise Gestion Communication.',
                        },
                        {
                            subtitle: 'PFE 90 jours.',
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
    ],
    ESI: [
        {
            filiere: 'Sciences et Technologies du Génie des Procédés (STGP)',
            introduction: '',
            volume: [
                {
                    title: 'Semestre 3: Chimie industrielle (CI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_3.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 4: Chimie industrielle (CI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_4.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 5: Chimie industrielle (CI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_5.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 3: Sciences et Technologie des Aliments (STA)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_3STA.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 4: Sciences et Technologie des Aliments (STA)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_4STA.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 5: Sciences et Technologie des Aliments (STA)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_5STA.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
        {
            filiere:
                "Science et technologie de l'information et de la communication (STIC)",
            introduction: (
                <p>
                    L'objectif de cette licence professionnelle est de préparer
                    les étudiants aux fonctions de technicien supérieur dans les
                    secteurs d'activités suivant :<br />
                    <br />
                    <strong>
                        Électronique, Informatique Industrielle, et
                        Télécommunications.
                    </strong>{' '}
                    Ce secteur recouvre les cinq composantes de la spécialité :
                    électronique, électrotechnique, automatique, informatique
                    industrielle, et télécommunications. Les titulaires de cette
                    option sont appelés à collaborer directement avec des
                    Masters. Ils interviennent dans les domaines de la
                    conception, de la réalisation et de la programmation de
                    cartes électroniques ; dans l'automatisation et le contrôle
                    des processus industriels ; dans l'électronique ou encore la
                    robotique. Les débouchés sont nombreux, aussi bien dans les
                    grandes entreprises que dans les PME-PMI et concernent de
                    nombreux secteurs : les industries électriques et
                    électroniques, la production, le transport, la santé,
                    l'agroalimentaire, l'aéronautique et l'espace... <br />
                    <br />{' '}
                    <strong>
                        Génie Logiciel et Système d'Information.
                    </strong>{' '}
                    Les étudiants formés doivent être capables de participer à
                    la conception, la réalisation et la mise en œuvre de
                    systèmes informatiques correspondant aux besoins des
                    utilisateurs. Pour assumer ces responsabilités, les
                    informaticiens doivent être compétents sur le plan
                    technologique, posséder une bonne culture générale et se
                    montrer aptes à la communication. Cette licence dispose de
                    trois options : informatique et génie informatique (cette
                    option prépare à des fonctions de gestion de données),
                    informatique et systèmes industriels (ce technicien
                    participera à la conception de systèmes informatiques
                    industriels), imagerie numérique (cette formation prépare
                    aux métiers de l'imagerie numérique sous toutes ses formes).
                    Les débouchés se trouvent essentiellement au sein des
                    services informatiques industriels, commerciaux ou des
                    administrations. Les constructeurs (ordinateurs,
                    périphériques), les SSII constituent une manne pour les
                    jeunes diplômés. Le titulaire de cette licence peut devenir
                    analyste-programmeur, spécialiste méthode, architecte
                    réseaux, développeur-intégrateur web, spécialiste bases de
                    données, responsable bureautique, spécialiste temps réel,
                    technico-commercial.
                </p>
            ),
            volume: [
                {
                    title: "Semestre 1: Sciences et Technologies de l'Information et de la Communication (STIC)",
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_1STIC.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: "Semestre 2: Sciences et Technologies de l'Information et de la Communication (STIC)",
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_2STIC.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
        {
            filiere: 'Sciences et technologies du génie industriel  (STGI)',
            introduction: (
                <p>
                    Cette licence professionnelle forme aux techniques modernes
                    de conception de production industrielle. La formation
                    débouche sur trois options :<br />
                    <br />{' '}
                    <strong>
                        Électrotechnique et Automatismes Industriels.
                    </strong>{' '}
                    L'objectif de cette licence professionnelle est de préparer
                    les étudiants aux fonctions de technicien supérieur dans les
                    secteurs d'activités recouvrant les quatre composantes de la
                    spécialité : électrotechnique, automatique, et informatique
                    industrielle. Les titulaires de cette licence sont appelés à
                    collaborer directement avec des Masters. Ils interviennent
                    dans les domaines de la conception, de la réalisation et de
                    la programmation de modules électrotechniques, dans
                    l'automatisation et le contrôle des processus industriels,
                    dans l'électrotechnique. Les débouchés sont nombreux, aussi
                    bien dans les grandes entreprises que dans les PME-PMI et
                    concernent de nombreux secteurs : les industries électriques
                    et électroniques, la production, le transport, la santé,
                    l'agroalimentaire, l'aéronautique et l'espace...
                    <br />
                    <br />{' '}
                    <strong>
                        Production et Maintenance des Systèmes Industriels.
                    </strong>{' '}
                    Son titulaire peut participer à la conception,
                    l'organisation, la gestion, le contrôle, la maintenance et
                    la commercialisation de composants ou de machines dans tous
                    les secteurs faisant appel à la mécanique. Les titulaires de
                    cette licence peuvent devenir techniciens supérieurs dans
                    des entreprises de bureaux d'études, de production
                    mécanique, de maintenance, d'organisation de la production
                    ou technico-commercial. Tous les secteurs en rapport avec
                    l'industrie (automobile, mécanique, bâtiment, chimie,
                    informatique, aéronautique...) sont envisageables.
                    <br />
                    <br />
                    <strong>Mécatronique et Automobile.</strong> Son titulaire
                    est formé aux techniques modernes de maintenance automobile
                    et de gros engins. Son titulaire peut participer à la
                    conception, l'organisation, la gestion, le contrôle, la
                    maintenance d'un parc automobile. Les titulaires de cette
                    licence peuvent devenir techniciens supérieurs dans des
                    entreprises de bureaux d'études, de production automobile,
                    de maintenance, d'organisation de la production ou
                    technico-commercial. Tous les secteurs en rapport avec
                    l'industrie (automobile, mécanique, aéronautique...) sont
                    envisageables
                </p>
            ),
            volume: [
                {
                    title: 'Semestre 1 : Sciences et Technologies du Génie Industriel (STGI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_1STGI.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 2 : Sciences et Technologies du Génie Industriel (STGI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_2STGI.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 3 : Électrotechnique et Automatismes Industriels (EAI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_3EAI.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 4 : Électrotechnique et Automatismes Industriels (EAI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_4EAI.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 5 : Électrotechnique et Automatismes Industriels (EAI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_5EAI.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 3 : Production et Maintenance des Systèmes Industriels (PMSI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_3PMSI.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 4 : Production et Maintenance des Systèmes Industriels (PMSI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_4PMSI.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 5 : Production et Maintenance des Systèmes Industriels (PMSI)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_5PMSI.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 3 : Mécatronique et Automobile (MA)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_3MA.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 4 : Mécatronique et Automobile (MA)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_4MA.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Semestre 5 : Mécatronique et Automobile (MA)',
                    contenu: [
                        {
                            subtitle: (
                                <img
                                    src={Concours['semestre_5MA.png']}
                                    alt=""
                                    style={{ width: '100%' }}
                                />
                            ),
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },

    ],
    ESTP: [
        {
            filiere: 'Génie Civil - GC',
            introduction: `BAC C - BAC D - BAC E - BAC F4 - BTCG`,
            volume: [
                {
                    title: 'Batîment et Urbanisme (BT)',
                    contenu: [
                        // {
                        //     subtitle:
                        //         'Enseignements scientifiques de base 578 H',
                        //     subtitleText:
                        //         "Anglais ; Chimie ; Dessin Technique ; Droit ; Economie-Gestion ; Éducation physique et sportive ; Géologie ; Géotechnique ; Informatique ; Mathématiques ; Physique ; Statique-RDM ; Technique d'Expression ; Technique documentaire ; Topographie.",
                        // },
                        // {
                        //     subtitle: 'Enseignements techniques 408 H',
                        //     subtitleText:
                        //         'Aménagement & technique graphique ; Dessin technique du bâtiment ; Géographie urbaine ; Géotechnique ; Hydraulique générale ; Matériaux ; Résistance des matériaux ; Sociologie urbaine ; Topographie ; Urbanisme.',
                        // },
                        // {
                        //     subtitle:
                        //         "Stage d'immersion 6 semaines (en vacances)",
                        //     subtitleText: '',
                        // },
                    ],
                },
                {
                    title: 'Hydraulique et Environnement (HE)',
                    contenu: [
                        // {
                        //     subtitle:
                        //         'Enseignements techniques spécialisés 730 H',
                        //     subtitleText:
                        //         'Aménagement & technique graphique ; Anglais ; Dessin technique du bâtiment ; Éducation physique et sportive ; Géographie urbaine ; Géotechnique ; Hydraulique générale ; Matériaux ; Résistance des matériaux ; Sociologie urbaine ; Topographie ; Urbanisme.',
                        // },
                        // {
                        //     subtitle: 'Projets 400 H',
                        //     subtitleText:
                        //         "Bureau d'études techniques ; mini-projet.",
                        // },
                        // {
                        //     subtitle:
                        //         "Stage d'immersion 6 semaines (en vacances)",
                        //     subtitleText: '',
                        // },
                    ],
                },
                {
                    title: 'Géomètre Topographe',
                    contenu: [
                        // {
                        //     subtitle:
                        //         'Enseignements techniques spécialisés 290 H',
                        //     subtitleText:
                        //         'Aménagement & technique graphique ; Anglais ; Dessin technique du bâtiment ; Éducation physique et sportive ; Géographie urbaine ; Géotechnique ; Hydraulique générale ; Matériaux ; Résistance des matériaux ; Sociologie urbaine ; Topographie ; Urbanisme.',
                        // },
                        // {
                        //     subtitle: "Projet de fin d'étude 440 H",
                        //     subtitleText: '',
                        // },
                    ],
                },
            ],
        },
    ],
    ESMG: [
        {
            filiere: 'Mines et Géologies',
            introduction:
                "Les objectifs de cette formation sont de former des Techniciens supérieurs capables d'accompagner les ingénieurs des domaines des mines et de la géologie, du pétrole et de l'eau. La formation est sanctionnée par la licence professionnelle Option Mines et Géologie.",
            volume: [
                {
                    title: 'Volume horaire 1ère année',
                    contenu: [
                        {
                            subtitle:
                                'Enseignements scientifiques de base 600 H',
                            subtitleText:
                                "Technique d'Expression ; Technique documentaire ; Droit ; Mathématiques ; Physique ; Chimie ; Informatique ; Economie et gestion ; Dessin technique ; Géologie ; Topographie ; Anglais ; Éducation physique et sportive ; RDM ; Géotechnique ; Initiation aux techniques minières ; Hygiène et secourisme ; Géomorphologie.",
                        },
                        {
                            subtitle: 'Enseignements techniques 300 H',
                            subtitleText:
                                'Télédétection ; Sédimentologie ; Technique de forage ; Législation minière ; Pétrographie ; Géostatique minière ; Géochimie appliquée à la prospection ; Géophysique appliquée ; Hydrogéologie ; Valorisation des minerais.',
                        },
                    ],
                },
                {
                    title: 'Volume horaire 2ème année',
                    contenu: [
                        {
                            subtitle:
                                'Enseignements techniques spécialisés 600 H',
                            subtitleText:
                                "Modules services sociales ; Anglais ; Cartographie ; EPS ; Exploration et exploitation des hydrocarbures ; Exploitation minière ; Géologie structurale ; Mécanique des roches ; Métallogénie (gitologie) ; RDM ; Prospection minière ; Technique de forage ; Législation minière ; Micro paléontologie ; Technique d'expression ; Géologie de Côte d'Ivoire ; OGC minier ; Economie minière",
                        },
                        {
                            subtitle: 'Projets 300 H',
                            subtitleText:
                                'Travaux Pratiques sur site et visites de chantiers.',
                        },
                        {
                            subtitle:
                                "Stage d'immersion 6 semaines (en vacances)",
                            subtitleText: '',
                        },
                    ],
                },
                {
                    title: 'Volume horaire 3ème année',
                    contenu: [
                        {
                            subtitle:
                                'Enseignements techniques spécialisés 460 H',
                            subtitleText:
                                "Instruments géophysiques ; Environnement minier ; Traitement des données géologiques ; Informatique appliquée à l'hydrologie ; Minéralogie ; Géologie historique ; Géophysique générale ; Mécanique des sols ; Topographie ; EPS ; Anglais.",
                        },
                        {
                            subtitle: "Projet de fin d'études 440 H",
                            subtitleText: '',
                        },
                    ],
                },
            ],
        },
    ],  

    ESPE:  [

    ],
}

export const concours = {
    bachelier: {
        title: 'bachelier',
        videoIntroduction: Concours['MOYATEST.mp4'],
        
    },
    ingenieur: {
        title: 'ingenieur',
        videoIntroduction: Concours['MOYATEST.mp4'],
    },
}
export const school = {
    cpge: {
        nom: 'CLASSES PRÉPARATOIRES AUX GRANDES ÉCOLES',
        alt: 'CPGE',
        logo: Logo['CPGE.png'],
        menus: [
            {
                text: 'présentation',
                link: '#presentation',
            },
            {
                text: 'mot du directeur',
                link: '#mot_du_directeur',
            },
            {
                text: 'filières',
                link: '#filieres',
            },
            {
                text: 'comment intégrer notre école',
                link: '#comment_integrer',
            },
            {
                text: 'débouchés',
                link: '#debouches',
            },
        ],
        couleurPrincipale: '#096678',
        couleurSecondaire: '#02a2a9',
        plaquette: Ecoles['CPGE.pdf'],
        filiereBachelier: filiereBachelier["CPGE"],
        contacts: ``,
        background: Ecoles['CPGEB.jpg'],
        backgroundAccueil: Ecoles['CPGEB.jpg'],
        presentation: `Les Classes Préparatoires aux Grandes Ecoles sont des
        classes spéciales qui préparent intensivement durant
        deux années, les meilleurs bacheliers ivoiriens et ceux
        de la sous-région aux concours d'intégration des filières
        d'ingénieurs des grandes écoles nationales et
        internationales les plus sélectives. Les classes
        préparatoires ont pour fonction principale d'accroître le
        niveau des connaissances des bacheliers dans différents
        champs disciplinaires de manières à les rendre aptes
        à suivre une formation en grande école dans les filières
        scientifiques et commerciales.`,
        photoDirecteur: Directeur['CPGE.png'],
        motsDirecteur: `L'éducation et la formation étant les fers de lance de la formation de la nation
        ivoirienne, les ivoiriens attendent de l'INP-HB un travail de qualité, des résultats
        capables de booster le développement de la Côte-d'Ivoire et par ricochet celui de la
        sous-région. C'est en cela que les classes préparatoires trouvent leur noble et légitime
        place dans les structures pédagogiques de l'INP-HB. L'ambition des CPGE est de
        préparer les jeunes ivoiriens ou ressortissants de la sous-région aux concours
        nationaux et internationaux les plus sélectifs afin de renforcer l'émulation et le niveau
        général de ses élèves. L’admission à l’ X Polytechnique de 33 élèves de 2016 à 2022 ne
        doit pas constituer une fin en soi, mais doit nous pousser à redoubler d'efforts afin de
        maintenir le bon niveau des élèves et aller au-delà. En d'autres termes, « nous devons
        rester dans la légende ».`,
        nomDirecteur: 'Dr. David KOUAKOU',
        objectifs: ``,
        filieres: Cycle['CPGE.png'],
        debouches: Debouches['CPGE.png'],
        filieresConcoursDutIntroduction: Ecoles['filiereConcoursCPGE.png'],
        filieresConcoursIngIntroduction: Ecoles['filiereConcoursCPGE.png'],
        commentIntegrer: [
            {
                titre: 'Prépa BCPST',
                image: Ecoles['filiere1CPGE.jpg'],
                corps: `La voie BCPST (Biologie, Chimie, Physique, et Sciences
                    de la Terre) est la prépa des sciences du vivant et des
                    géosciences. Cette filière offre une formation équilibrée
                    en mathématiques, physique-chimie et sciences de la
                    vie et de la terre. La voie BCPST s’adresse aux
                    bacheliers C & D, qui ont le goût des sciences de la vie
                    et de la terre (SVT), et qui ont des potentialités dans
                    l’ensemble des disciplines scientifiques (mathématiques,
                    physique-chimie et SVT) et des compétences en rédaction.`,
            },
            {
                titre: 'Prépa ECS',
                image: Ecoles['filiere2CPGE.jpg'],
                corps: `Les Prépas Économiques et Commerciale option
                Scientifique (ECS) forgent durant deux (2) années les
                élèves aux concours des Grandes Écoles supérieures
                de management, de commerce et de gestion ou encore
                des Grandes Écoles d’actuariat et de statistique. Cette
                filière met l’accent sur les mathématiques, les langues,
                l’histoire et la culture générale. Tout candidat à la
                prépa ECS doit avoir à la fois une grande curiosité
                intellectuelle associée à une forte capacité de travail.`,
            },
            {
                titre: 'Prépa MPSI/Techno',
                image: Ecoles['filiere3CPGE.jpg'],
                corps: `Les prépa MPSI (Mathématique, Physique et Science
                    de l’Ingénieur) s’adressent aux élèves passionnés
                    par les les maths et la physique et ceux intéressés par
                    l’informatique. La formation est conçue en fonction de
                    04 objectifs essentiels :
                    - Développer conjointement l’intuition, l’imagination,
                    le raisonnement, et la rigueur ;
                    - Promouvoir la réflexion personnelle des étudiants
                    sur les problèmes et les phénomènes, sur la portée
                    des concepts, des hypothèses, des résultats et des
                    méthodes, au moyen d’exemples et de contre-exemples,
                    développer ainsi une attitude de questionnement et
                    de recherche ;
                    - Exploiter toute la richesse de la démarche scientifique ;
                    - Privilégier les problèmes scientifiques susceptibles
                    de développer la réflexion personnelle des élèves et
                    leur capacité de synthèse.
                    `,
            },
            {
                titre: 'Prépa PCSI',
                image: Ecoles['filiere4CPGE.jpg'],
                corps: `Ouverte à la rentrée académique 2022-2023,
                la prépa PCSI est destinée aux bacheliers généraux (BAC C, D et E).
                Elle s’adresse aux élèves intéressés par une approche des sciences 
                fondamentales fondée sur l’expérimentation et la modélisation,
                désirant comprendre le fonctionnement des différents objets et
                ayant des facilités pour la manipulation. C’est aussi la voie 
                à privilégier pour les élèves attirés par la chimie. L’objectif 
                de cette filière est de préparer en 02 ans, les meilleurs élèves
                pour intégrer une grande école d’ingénieur dans le domaine industriel.`,
            },
        ],
        meilleursEleves: [
            {
                name: 'Lasme Mel',
                picture: esmg['eleve1.jpg'],
                moyenne: '13,69/20',
                filiere: 'Prepa BCPST',
            },
            {
                name: 'Esso Olivia',
                picture: esmg['eleve2.jpg'],
                moyenne: '15,9/20',
                filiere: 'Prepa BCPST',
            },
            {
                name: 'Kakou Johan',
                picture: esmg['eleve3.jpg'],
                moyenne: '13,8/20',
                filiere: 'Prepa MP',
            },
            {
                name: 'Zeba Abdoul',
                picture: esmg['eleve4.jpg'],
                moyenne: '13,69/20',
                filiere: 'Prépa MPSI',
            },
            {
                name: 'Christian Josue',
                picture: esmg['eleve5.jpg'],
                moyenne: '13,5/20',
                filiere: 'Prépa MPSI',
            },
            {
                name: 'Yao Michel',
                picture: esmg['eleve6.jpg'],
                moyenne: '13,10/20',
                filiere: 'Prépa BCPST',
            },
        ],
        partenairesAcademiques: [
            {
                image: partenairesCPGE['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesCPGE['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesCPGE['PA (3).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesCPGE['PA (4).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
        ],
    },
    escae: {
        nom: "ECOLE SUPERIEURE DU COMMERCE ET D'ADMINISTRATION DES ENTREPRISES",
        alt: 'ESCAE',
        background: Ecoles['ESCAEB.jpg'],
        backgroundAccueil: Ecoles['ESCAEB.jpg'],
        logo: Logo['ESCAE.png'],
        menus: [
            {
                text: 'présentation',
                link: '#presentation',
            },
            {
                text: 'mot du directeur',
                link: '#mot_du_directeur',
            },
            {
                text: 'objectifs',
                link: '#objectifs',
            },
            {
                text: 'cycles et filières',
                link: '#cycles_et_filieres',
            },
            {
                text: 'comment intégrer notre école',
                link: '#comment_integrer',
            },
            {
                text: 'débouchés',
                link: '#debouches',
            },
        ],
        plaquette: Ecoles['ESCAE.pdf'],
        contacts: ``,
        couleurPrincipale: '#234d9d',
        couleurSecondaire: '#0095da',
        presentation: `L'ESCAE, née en 1996 de la fusion de l'ex ETT (École
            de Technologie Tertiaire), de l'ex ESCA (École
            Supérieure de Commerce d'Abidjan) et de l'ex INSET,
            est une Business School qui forme depuis plusieurs
            décennies des Ingénieurs et des Techniciens
            Supérieurs de Côte d'Ivoire et de la sous-région
            africaine. Depuis 2016, l'ESCAE est engagée à travers
            le programme du C2D (Contrat de Désendettement et
            de Développement), volet éducation/formation financé
            par l'AFD, dans le processus de rénovation pédagogique
            en vue de sa modernisation avec l'Université Dauphine
            de France, son partenaire institutionnel. Ceci, afin de
            rendre son offre de formation plus attractive et se hisser
            au niveau des standards internationaux.`,
        photoDirecteur: Directeur['ESCAE.png'],
        nomDirecteur: 'Dr. Losseyni BAKAYOKO',
        motsDirecteur: `Depuis sa création l'ESCAE propose des filières de formation innovantes dans les
        cycles d'ingénieurs et de Techniciens Supérieurs qui font sa renommée en Côte d'Ivoire
        et dans la sous-région. Elle dispose d'un corps professoral de qualité tant au niveau
        des enseignants de l'INP-HB qu'au niveau des professionnels issus du secteur privé.
        Nos diplômés, telles des boussoles, nous accompagnent dans la formation de leurs
        cadets à travers leurs réseaux structurés pour l'insertion professionnelle des nouveaux
        diplômés. Forte de l'expertise de ses dirigeants, enseignants et chercheurs, l'ESCAE
        apporte également son savoir-faire aux entreprises qui la sollicitent aux quatre coins
        de la planète pour des formations groupées ou des séminaires de perfectionnement
        dans les domaines spécifiques du management. Si vous rêvez d'une formation de
        pointe dans les domaines de la gestion (Finances, Comptabilité, Audit, Transport
        Logistique…), l'ESCAE vous ouvre ses portes.`,
        objectifs: `Depuis sa création l'ESCAE propose des filières de formation innovantes dans les
        cycles d'ingénieurs et de Techniciens Supérieurs qui font sa renommée en Côte d'Ivoire
        et dans la sous-région. Elle dispose d'un corps professoral de qualité tant au niveau
        des enseignants de l'INP-HB qu'au niveau des professionnels issus du secteur privé.
        Nos diplômés, telles des boussoles, nous accompagnent dans la formation de leurs
        cadets à travers leurs réseaux structurés pour l'insertion professionnelle des nouveaux
        diplômés. Forte de l'expertise de ses dirigeants, enseignants et chercheurs, l'ESCAE
        apporte également son savoir-faire aux entreprises qui la sollicitent aux quatre coins
        de la planète pour des formations groupées ou des séminaires de perfectionnement
        dans les domaines spécifiques du management. Si vous rêvez d'une formation de
        pointe dans les domaines de la gestion (Finances, Comptabilité, Audit, Transport
        Logistique…), l'ESCAE vous ouvre ses portes.`,
        cyclesEtFilieres: Cycle['ESCAE.png'],
        debouches: Debouches['ESCAE.png'],
        filiereBachelier: filiereBachelier["ESCAE"],
        filiereIngenieur: filiereIngenieur["ESCAE"],
        filieresConcoursDutIntroduction:Ecoles['filiereConcoursESCAE.png'],
        filieresConcoursIngIntroduction: Ecoles['filiereConcoursESCAE.png'],
        filieresIngenieures: [
            {
                nom: 'Etude Comptable Supérieur',
                image: Ecoles['compta.png'],
            },
            {
                nom: 'Manager ESCA',
                image: Ecoles['manager.png'],
            },
            {
                nom: 'Hautes Etudes en Assurance',
                image: Ecoles['assurance.png'],
            },
            {
                nom: 'Ingénieur Logistique et Transport',
                image: Ecoles['logistique.png'],
            },
        ],
        commentIntegrer: [
            {
                titre: 'Cycle des Techniciens Supérieurs',
                image: Ecoles['filiere1ESCAE.jpg'],
                corps: ` L'entrée à ESCAE se fait par voie de concours. Ce
                concours permet aux bacheliers de l'année en cours
                d'accéder au cycle technicien supérieur option
                Commerce et Administration des Entreprises (Bac A,
                B, C, D) ou au cycle technicien supérieur option Finance
                Comptabilité (Bac G2 et BTCP).`,
            },
            {
                titre: 'Cycle des Ingénieurs',
                image: Ecoles['filiere2ESCAE.jpg'],
                corps: `Le concours des ingénieurs de Commerce et
                Administration des Entreprises (CAE) option commerce
                et option mathématique est ouvert aux Classes
                préparatoires Technologiques et Biologiques, aux titulaires 
                d'une Licence en Sciences économiques et de gestion et
                au DUT et DTS d'une spécialité du domaine tertiaire.`,
            },
        ],
        meilleursEleves: [
            {
                name: 'Bahintchie',
                picture: esmg['eleve1.jpg'],
                moyenne: '13,69/20',
                filiere: 'Contôle de Gestion',
            },
            {
                name: 'Zogbo Marie',
                picture: esmg['eleve2.jpg'],
                moyenne: '15,9/20',
                filiere: 'Management-Marketing-Finance',
            },
            {
                name: 'Fofana Mory',
                picture: esmg['eleve3.jpg'],
                moyenne: '13,8/20',
                filiere: 'Ingénieur Logistique et Transport',
            },
            {
                name: 'Don Moby',
                picture: esmg['eleve4.jpg'],
                moyenne: '13,69/20',
                filiere: 'Assurance',
            },
            {
                name: 'Christian Josue',
                picture: esmg['eleve5.jpg'],
                moyenne: '13,5/20',
                filiere: 'Etudes Comptables Supérieures',
            },
            {
                name: 'Yao Michel',
                picture: esmg['eleve6.jpg'],
                moyenne: '13,10/20',
                filiere: 'Hautes Etudes en Assurance',
            },
        ],
        partenairesAcademiques: [
            {
                image: partenairesESCAE['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESCAE['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESCAE['PA (3).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESCAE['PA (4).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
        ],
        partenairesEntreprises: [
            {
                image: partenairesESCAE['PE (1).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (2).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (3).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (4).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (5).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (6).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (7).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (8).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (9).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (10).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (11).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (12).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (13).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (14).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (15).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (16).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (17).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (18).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESCAE['PE (19).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
        ],
    },
    esa: {
        nom: "ECOLE SUPERIEURE D'AGRONOMIE",
        alt: 'ESA',
        logo: Logo['ESA.png'],
        couleurPrincipale: '#00a651',
        plaquette: Ecoles['ESA.pdf'],
        contacts: ``,
        couleurSecondaire: '#a7c139',
        background: Ecoles['ESAB.jpg'],
        photoDirecteur: Directeur['ESA.png'],
        filiereBachelier: filiereBachelier["ESA"],
        filiereIngenieur: filiereIngenieur["ESA"],
        filieresConcoursDutIntroduction: Ecoles['filiereConcoursESA.png'],
        filieresConcoursIngIntroduction: Ecoles['filiereConcoursESA.png'],
        menus: [
            {
                text: 'présentation',
                link: '#presentation',
            },
            {
                text: 'mot du directeur',
                link: '#mot_du_directeur',
            },
            {
                text: 'objectifs',
                link: '#objectifs',
            },
            {
                text: 'cycles et filières',
                link: '#cycles_et_filieres',
            },
            {
                text: 'comment intégrer notre école',
                link: '#comment_integrer',
            },
            {
                text: 'débouchés',
                link: '#debouches',
            },
        ],
        backgroundAccueil: Ecoles['esaBg.jpg'],
        presentation: `L'Ecole Supérieure d'Agronomie (ESA) est un
        établissement d'enseignement supérieur et de
        recherche, sous tutelle du Ministère de l'Enseignement
        Supérieur et de la Recherche Scientifique de l'État de
        Côte d'Ivoire. L'ESA est née en 1996 de la fusion de
        deux grandes écoles de formation des cadres
        supérieurs dans le domaine des sciences
        agronomiques que sont l'Ecole Nationale Supérieure
        d'Agronomie (ENSA) et l'Institut Agricole de Bouaké
        (IAB). L'ESA est implantée sur le site nord de l'INP-HB
        de Yamoussoukro à environ 9 km du centre-ville.`,
        nomDirecteur: 'Dr. Siaka KONE',
        motsDirecteur: `En raison du développement rapide de l'agriculture et des nouvelles technologies
        dans les domaines agricoles, l'ESA s'est fixé pour objectif de former des diplômés en
        sciences agronomiques (Ingénieurs Agronomes, Ingénieurs des Techniques Agricoles
        et Techniciens Supérieurs en Agronomie) afin de répondre aux besoins de la
        Côte d'Ivoire. Notre enseignement se base sur un corps professoral permanent de
        qualité et sur une large ouverture vers le monde professionnel par la réalisation de
        projets de fin d'études et de stages de perfectionnement en entreprises. Cette
        ouverture renforce les capacités techniques et pratiques de nos futurs Ingénieurs et
        Techniciens. Par cette formation qui se veut théorique et pratique, l'ESA a pour
        ambition de fournir, au monde agricole, des diplômés de haut niveau ayant acquis les
        bases d'une pluri-compétence dont les qualités professionnelles ne pourront que
        s'étoffer avec l'expérience du terrain.`,
        objectifs: `L'objectif de l'école est de former d'une part des
        ingénieur(e)s généralistes dans le domaine des sciences
        agronomiques, aptes à appréhender l'ensemble des
        facteurs scientifiques, techniques, socio-économiques,
        humains et culturels pour prendre des décisions dans un
        environnement changeant et d'autre part des techniciens
        pratiques, avec des connaissances et un savoir-faire axé
        sur la maitrise des itinéraires techniques des productions
        végétales et animales et des aspects de la chaine des
        valeurs. Le système d'enseignement à l'ESA vise à
        favoriser : la pluridisciplinarité des enseignements, le travail personnel ou en équipe, l'apprentissage de méthodologies et de démarches opératoires et l'esprit d'initiative.`,
        cyclesEtFilieres: Cycle['ESA.png'],
        debouches: Debouches['ESA.png'],
        filieresIngenieures: [
            {
                nom: 'Agroalimentaire',
                image: Ecoles['agroali.png'],
            },
            {
                nom: 'Défense des Cultures',
                image: Ecoles['defense.png'],
            },
            {
                nom: 'Eaux et Forêt',
                image: Ecoles['filiere3ESA.jpeg'],
            },
            {
                nom: 'Agroéconomie',
                image: Ecoles['agroeco.png'],
            },
        ],
        commentIntegrer: [
            {
                titre: 'Cycle des Techniciens Supérieurs',
                image: Ecoles['filiere1ESA.jpeg'],
                corps: `Sont autorisés à postuler les admis au baccalauréat
                des séries C et D de l'année en cours.`,
            },
            {
                titre: 'Cycle des Ingénieurs Agronomes',
                image: Ecoles['filiere2ESA.jpeg'],
                corps: `Sont autorisés à postuler les étudiants ayant terminé
                avec succès les deux années de classes préparatoires
                mathématiques supérieures et mathématiques spéciales
                biologiques à l'INP-HB et les étudiants en provenance
                de l'Université ayant obtenu la Licence2 en Sciences
                de la Nature et en Chimie Biologie Géologie.`,
            },
            {
                titre: 'Cycle Ingénieurs des Techniques Agricoles',
                image: Ecoles['filiere3ESA.jpeg'],
                corps: `Sont autorisés à postuler les étudiants ayant terminé
                avec succès la première année de classes préparatoires
                mathématiques supérieures biologiques à l'INP-HB
                et les étudiants en provenance de l'Université ayant
                obtenu la Licence 1 en Sciences de la nature et en
                Chimie Biologie Géologie.`,
            },
            {
                titre: 'Mastères Spécialisés (Bac+6)',
                image: Ecoles['filiere4ESA.jpeg'],
                corps: `Sont autorisés à postuler, les professionnels ayant un
                diplôme minimum de Bac+4 dans un domaine éligible au
                mastère Spécialisé souhaité et ayant au minimum deux
                années d'expérience professionnelle.`,
            },
        ],
        meilleursEleves: [
            {
                name: 'Ibrahim Kone',
                picture: esmg['eleve1.jpg'],
                moyenne: '13,69/20',
                filiere: 'Agroéconomie',
            },
            {
                name: 'Arsene Ayek',
                picture: esmg['eleve2.jpg'],
                moyenne: '15,9/20',
                filiere: 'Eaux et Forêts',
            },
            {
                name: 'Fofana Mory',
                picture: esmg['eleve3.jpg'],
                moyenne: '13,8/20',
                filiere: 'Agroéconomie',
            },
            {
                name: 'Don Moby',
                picture: esmg['eleve4.jpg'],
                moyenne: '13,69/20',
                filiere: 'Agro-alimentaire',
            },
            {
                name: 'Christian Josue',
                picture: esmg['eleve5.jpg'],
                moyenne: '13,5/20',
                filiere: 'Eaux et Forêts',
            },
            {
                name: 'Yao Michel',
                picture: esmg['eleve6.jpg'],
                moyenne: '13,10/20',
                filiere: 'Production Végétale',
            },
        ],
        partenairesAcademiques: [
            {
                image: partenairesESA['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESA['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESA['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESA['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
        ],
        partenairesEntreprises: [
            {
                image: partenairesESA['PE (1).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESA['PE (2).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESA['PE (3).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESA['PE (4).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESA['PE (5).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESA['PE (6).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
        ],
    },
    esi: {
        logo: Logo['ESI.png'],
        alt: 'ESI',
        nom: "ECOLE SUPERIEURE D'INDUSTRIE",
        plaquette: Ecoles['ESI.pdf'],
        contacts: ``,
        couleurPrincipale: '#74294b',
        couleurSecondaire: '#a91d52',
        background: Ecoles['ESIB.jpg'],
        backgroundAccueil: Ecoles['ESIB.jpg'],
        photoDirecteur: Directeur['ESI.png'],
        cyclesEtFilieres: Cycle['ESI.png'],
        debouches: Debouches['ESI.png'],
        menus: [
            {
                text: 'présentation',
                link: '#presentation',
            },
            {
                text: 'mot du directeur',
                link: '#mot_du_directeur',
            },
            {
                text: 'objectifs',
                link: '#objectifs',
            },
            {
                text: 'cycles et filières',
                link: '#cycles_et_filieres',
            },
            {
                text: 'comment intégrer notre école',
                link: '#comment_integrer',
            },
            {
                text: 'débouchés',
                link: '#debouches',
            },
        ],
        presentation: `L'Ecole Supérieure d'Industrie (ESI) est née en 1996
        lors de la fusion des écoles de formation technique
        de l'ex-Institut National Supérieur de l'Enseignement
        Technique (INSET) que sont : l'Ecole Nationale Superieur
        des Ingénieurs d'Abidjan (ENSIA), l'Ecole Supérieure
        des Ingénieurs en Informatique (ES2I), l'École
        Supérieure d'Ingénieurs (ESI), l'Institut de Technologies
        Industrielles (ITI) et l'École de Technologies Industrielles
        (ETI). Elle a pour mission d'assurer la formation initiale
        d'ingénieurs et de techniciens supérieurs dans les
        secteurs de l'industrie, la recherche scientifique,
        l'innovation technologique, la production, l'expertise
        et l'assistance des entreprises.`,
        nomDirecteur: 'Dr. Aka TANOH',
        motsDirecteur: `La réforme des grandes écoles de Yamoussoukro en 1996 a conduit à la création de
        l'Institut National Polytechnique Félix HOUPOUËT-BOIGNY (INP-HB) qui est un
        consortium d'écoles dont l'Ecole Supérieure de l'Industrie (ESI). Depuis, l'ESI forme
        des techniciens supérieurs et des ingénieurs de conception dans les secteurs de
        l'industrie. Nous mettons un accent particulier sur le renforcement de la professionnalisation de nos formations. De plus, l'ouverture à l'international et le renforcement
        du partenariat avec les entreprises, les organisations professionnelles et les
        associations des diplômés contribuent à l'insertion professionnelle de nos diplômés.
        Dans le cadre du contrat de désendettement et de développement (C2D), financé par
        l'Agence Française de Développement (AFD), notre école bénéficie d'important fonds
        pour la modernisation des offres de formation et le renforcement des capacités
        techniques et humaines.`,
        objectifs: `L' Ecole Supérieure d'industrie (ESI) a pour mission, la formation des futurs cadres du secteur industriel et de l'administration publique. 

        Elle propose deux (02) cycles de formation et trois (03) parcours à savoir les :
        
        - sciences et technologies de l'information et de la communication, les 
        
        - Sciences et Technologies du Génie Industriel et les 
        
        - Sciences et Technologies du Génie des Procédés  (STGP).
        
        La formation est axée sur des cours théoriques, des Travaux Pratiques (TP) et des stages en entreprises qui permettent aux diplômés d'être opérationnels dès la sortie de l'école. 
        
        Ces derniers occupent des postes stratégiques dans les domaines de l'informatique, la télécommunication, l'électronique, la mécanique et l'automatisme industriel, l'énergétique, l'agro-alimentaire, la chimie industrielle, l'électronique,  l'électrotechnique, la mécanique... 
        
        L' ESI intervient également dans la production, la recherche et l'expertise.`,
        filiereBachelier: filiereBachelier["ESI"],
        filiereIngenieur: filiereIngenieur["ESI"],
        filieresConcoursDutIntroduction: Ecoles['filiereConcoursESI.png'],
        filieresConcoursIngIntroduction: Ecoles['filiereConcoursESI.png'],
        filieresIngenieures: [
            {
                nom: 'Sciences et Technologies du Génie des Procédés (STGP)',
                image: Ecoles['stgp.png'],
            },
            {
                nom: "Sciences et Technologies du Génie de l'Industrie (STGI)",
                image: Ecoles['stgi.png'],
            },
            {
                nom: "Sciences et Technologies de l'Information de la Communication (STIC)",
                image: Ecoles['stic.png'],
            },
        ],
        commentIntegrer: [
            {
                titre: 'Cycle des Techniciens Supérieurs',
                image: Ecoles['techESI.png'],
                corps: `L'admission au cycle des techniciens supérieurs des
                nationaux se fait par voie de concours ou sur titre
                après le baccalauréat scientifique (C, D, E, F1, F2,
                F3, F4, F7) ou un BT option STIC, STGP, STGI de
                l'année en cours.`,
            },
            {
                titre: 'Cycle des Ingénieurs',
                image: Ecoles['ingESI.png'],
                corps: `L'admission au cycle ingénieur se fait par voie de
                concours après deux années de classes préparatoires
                et à partir de la licence dans le domaine des sciences
                industrielles (mathématiques, physique,chimie) ou par
                voie de passerelle (meilleurs étudiants) après le Cycle
                technicien supérieur. La formation dure trois années
                avec un tronc commun.`,
            },
        ],
        meilleursEleves: [
            {
                name: 'Kambou Johann',
                picture: esmg['eleve1.jpg'],
                moyenne: '13,69/20',
                filiere: 'STGI',
            },
            {
                name: 'Magassouba Oumou',
                picture: esmg['eleve2.jpg'],
                moyenne: '15,9/20',
                filiere: 'STGP',
            },
            {
                name: 'Fofana Mory',
                picture: esmg['eleve3.jpg'],
                moyenne: '13,8/20',
                filiere: 'Traitement Des Eaux',
            },
            {
                name: 'Don Moby',
                picture: esmg['eleve4.jpg'],
                moyenne: '13,69/20',
                filiere: 'Mines et carrières',
            },
            {
                name: 'Christian Josue',
                picture: esmg['eleve5.jpg'],
                moyenne: '13,5/20',
                filiere: 'Mines et carrières',
            },
            {
                name: 'Yao Michel',
                picture: esmg['eleve6.jpg'],
                moyenne: '13,10/20',
                filiere: 'Traitement Des Eaux',
            },
        ],
        partenairesAcademiques: [
            {
                image: partenairesESI['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESI['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESI['PA (3).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESI['PA (4).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
        ],
        partenairesEntreprises: [
            {
                image: partenairesESI['PE (1).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (2).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (3).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (4).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image:partenairesESI['PE (5).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (6).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (7).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (8).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (9).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (10).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (11).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (12).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (13).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (14).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESI['PE (15).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
        ],
    }, 
    estp: {
        logo: Logo['ESTP.png'],
        alt: 'ESTP',
        nom: 'ECOLE SUPERIEURE DES TRAVAUX PUBLICS',
        couleurPrincipale: '#612c87',
        couleurSecondaire: '#983394',
        background: Ecoles['ESTPB.jpg'],
        backgroundAccueil: Ecoles['ESTPB.jpg'],
        photoDirecteur: Directeur['ESTP.png'],
        cyclesEtFilieres: Cycle['ESTP.png'],
        debouches: Debouches['ESTP.png'],
        menus: [
            {
                text: 'présentation',
                link: '#presentation',
            },
            {
                text: 'mot du directeur',
                link: '#mot_du_directeur',
            },
            {
                text: 'cycles de formation',
                link: '#cycles_de_formation',
            },
            {
                text: 'comment intégrer notre école',
                link: '#comment_integrer',
            },
            {
                text: 'débouchés',
                link: '#debouches',
            },
        ],
        presentation: (
            <>
                Crée en 1963, l'Ecole Nationale Supérieure des Travaux Publics
                (ENSTP) initialement à Abidjan a été transférée à Yamoussoukro
                en 1979. À la faveur de la restructuration des grandes écoles de
                Yamoussoukro en 1996, l'INP-HB a été créé. Il regroupe huit (08)
                grandes écoles dont l'Ecole Supérieure des Travaux Publics
                (ESTP). Transfuge de l'ex-ENSTP, l'ESTP est chargée d'assurer la
                formation initiale dans le domaine du Génie Civil. L'ESTP a pour
                mission : <br />
                <br />
                • la Formation initiale de Techniciens Supérieurs et
                d'Ingénieurs dans les domaines du Génie Civil et de toutes
                spécialités connexes. <br />
                • la Production <br />• la Prestation et expertise pour les
                entreprises et les collectivités locales
            </>
        ),
        nomDirecteur: 'Dr. Dénis KONAN',
        motsDirecteur: `Tout pays ou toute nation, quel que soit son niveau de développement, a besoin de femmes
        et d'hommes compétents dans le domaine du Génie Civil, car cette discipline fait partie
        intégrante de toute civilisation. Á l'instar de la quasi-totalité des pays africains, notre pays la
        Côte d'Ivoire qui est en pleine construction aura toujours besoin de techniciens expérimentés
        dans ce domaine pour asseoir son développement. Le Génie Civil demeure donc un secteur
        d'activité porteur. L'Ecole Supérieure des travaux Publics (ESTP) a une grande expérience
        avérée dans la formation des Techniciens Supérieurs et d'Ingénieurs dans le domaine du
        génie Civil. En effet, l'ESTP a tissé de nombreux partenariats avec des entreprises du monde
        professionnel renforçant ainsi d'une manière efficace la professionnalisation de ses
        formations. Elle a également noué des partenariats avec des structures académiques à
        l'étranger qui lui permettent de jauger le niveau de qualité de ses formations, grâce à la
        mobilité académique de ses étudiants. J'invite donc tous les bacheliers et en l'occurrence,
        les meilleurs à choisir l'ESTP pour un avenir assuré`,
        objectifs: ``,
        plaquette: Ecoles['ESTP.pdf'],
        contacts: ``,
        filiereBachelier: filiereBachelier["ESTP"],
        filiereIngenieur: filiereIngenieur["ESTP"],
        filieresConcoursDutIntroduction: Ecoles['filiereConcoursESTP.png'],
        filieresConcoursIngIntroduction: Ecoles['filiereConcoursESTP.png'],
        commentIntegrer: [
            {
                titre: 'Cycle des Ingénieurs de conception',
                image: Ecoles['estp1.png'],
                sousTitre: "Concours Génie Mécanique, Énergétique et Civil (GMEC)",
                corps:`Le concours est ouvert aux classes préparatoires
                        technologiques 2ème année, aux titulaires d'un DUT ou
                        d'un DTS d'une des spécialités du concours GMEC ou aux
                        titulaires d'un DEUG option Mathématiques et Physique
                        (MP), ou Physique et Chimie (PC), ou Mathématiques
                        Physiques et Technologie (MPT), ou Sciences
                        Fondamentales Appliquées (SFA), ou d'un diplôme admis en
                        équivalence.`
            },
            {
                titre: 'Cycle des Techniciens Supérieurs',
                sousTitre: "Concours Génie Civil (GMEC)",
                image: Ecoles['estp2.png'],
                corps:`Le concours ouvert aux titulaires d'un Baccalauréat série
                        C, D, E, F4 et ou d'un BT de l'année en cours.`
            },
        ],
        cyclesDeFormation: [
            {
                titre: 'Cycle Technicien Supérieur',
                image: Ecoles['estp3.jpg'],
                corps: (
                    <>
                        • Technicien Supérieur spécialité Bâtiment et Urbanisme{' '}
                        <br />
                        • Technicien Supérieur spécialité Hydraulique et
                        Environnement <br />
                        • Technicien Supérieur spécialité Routes et Transports{' '}
                        <br />• Technicien Supérieur spécialité Géomètre
                    </>
                ),
            },
            {
                titre: 'Cycle Ingénieur',
                image: Ecoles['estp2.jpg'],
                corps: (
                    <>
                        • Ingénieur Géomètre-Topographe <br />
                        • Ingénieur Génie Civil option Bâtiment et Urbanisme <br />
                        • Ingénieur Génie Civil option Hydraulique et Environnement <br />
                        • Ingénieur Génie Civil option Infrastructures et Transports <br />
                    </>
                ),
            },
        ],

        meilleursEleves: [
            {
                name: 'Jean Marc',
                picture: esmg['eleve1.jpg'],
                moyenne: '13,69/20',
                filiere: 'BU',
            },
            {
                name: 'Emmanuella Kouassi',
                picture: esmg['eleve2.jpg'],
                moyenne: '15,9/20',
                filiere: 'GC',
            },
            {
                name: 'Fofana Mory',
                picture: esmg['eleve3.jpg'],
                moyenne: '13,8/20',
                filiere: 'GT',
            },
        ],
        partenairesAcademiques: [
            {
                image: partenairesESTP['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESTP['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESTP['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESTP['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
        
        ],
        partenairesEntreprises: [
            {
                image: partenairesESTP['PE (1).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESTP['PE (2).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESTP['PE (3).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESTP['PE (4).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESTP['PE (5).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESTP['PE (6).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESTP['PE (7).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESTP['PE (8).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            
        ],
    },
    esmg:{
        logo: esmg['ESMG.png'],
        alt: 'ESMG',
        nom: 'ECOLE SUPERIEURE DES MINES ET DE GÉOLOGIE',
        couleurPrincipale: '#cc580a',
        couleurSecondaire: '#cd982e',
        background: esmg['ESMG.jpg'],
        plaquette: Ecoles['ESMG.pdf'],
        contacts: ``,
        filiereBachelier: filiereBachelier["ESMG"],
        filiereIngenieur: filiereIngenieur["ESMG"],
        filieresConcoursDutIntroduction: Ecoles['filiereConcoursESMG.png'],
        filieresConcoursIngIntroduction: Ecoles['filiereConcoursESMG.png'],
        backgroundAccueil: esmg['ESMG.jpg'],
        presentation: `L'ESMG a hérité de l'ancienne formation des techniciens
        supérieurs en mines et géologie de l'ex-ENSTP qui a
        débuté en 1973 en même temps que celle des
        ingénieurs des techniques dont la formation sera
        interrompue en 1984. Depuis 1997, l'ESMG forme des
        ingénieurs de conception en plus des techniciens
        supérieurs, dans les secteurs minier et pétrolier, selon le
        décret n°96/678 du 4 septembre 1996 portant création
        de l'INP-HB.
        L'ESMG est chargée de la formation des techniciens
        supérieurs, des ingénieurs des techniques et des
        ingénieurs de conception dans les domaines des
        mines, de la géologie et du pétrole ou de toute autre
        spécialité connexe correspondant aux besoins du secteur
        minier dans la région africaine.`,
        photoDirecteur: Directeur['ESMG.png'],
        motsDirecteur: `L'Ecole Supérieure de Mines et de Géologie (ESMG) est le résultat de la synergie
        d'action des professionnels des domaines des mines de la géologie et du pétrole,
        associés au corps enseignant de l'INP-HB et des universités de Côte d'Ivoire. Depuis
        2015, l'ESMG est un centre d'excellence régional de la Banque Mondiale pour les
        Mines et l'environnement minier. Elle bénéficie pour ce faire d'un appui constant des
        entreprises minières nationales et des partenaires au développement internationaux.
        La formation à l'ESMG est menée selon une approche pédagogique innovante qui met
        à contribution nos partenaires locaux et internationaux. L'École développe avec des
        Grandes Écoles qui évoluent dans le même secteur des activités conjointes, couplées
        avec des visites de chantiers et de plateformes pétrolières. Vous rêvez d'une formation
        supérieure dans le domaine des mines et du pétrole ? Donnez-vous les moyens
        d'intégrer une école d'élite. L'ESMG vous ouvre ses portes, montrez-nous vos capacités,
        laissez- nous les étendre.`,
        cyclesEtFilieres: Cycle['ESMG.png'],
        debouches: Debouches['ESMG.png'],
        menus: [
            {
                text: 'présentation',
                link: '#presentation',
            },
            {
                text: 'mot du directeur',
                link: '#mot_du_directeur',
            },
            {
                text: 'formation',
                link: '#formation',
            },
            {
                text: 'cycles et filières',
                link: '#cycles_et_filieres',
            },
            {
                text: 'comment intégrer notre école',
                link: '#comment_integrer',
            },
            {
                text: 'débouchés',
                link: '#debouches',
            },
        ],
        nomDirecteur: 'Prof. Alphonse YAO',
        formation: (
            <>
                {' '}
                <strong>Ingénieurs mines et carrières</strong> <br />
                • Module mines et carrières <br />
                • Module génie civil <br />
                • Module génie chimique <br />
                • Module simulation informatique et graphique <br />
                • Module économie et gestion <br />
                • Travail de fin d'études ou stage à thème (3 mois) <br />{' '}
                <br />
                <strong>Technicien Supérieur mines et géologie</strong> <br />
                • Complément sur les techniques de base : instruments de
                géophysique, informatique appliquée, organisation et gestion de
                chantiers. <br />
                • Aspects administratifs : économie minière, législation
                minière, rédaction administrative. <br />
                • Projet de fin d'études : 45 à 60 jours en entreprise ou dans
                un département de recherche et de formation de l'INP-HB. <br />
                <br />
                <strong>
                    Ingénieurs Exploitation et raitement des eaux
                </strong>{' '}
                <br />
                • Module eau <br />
                • Module eau en milieu urbain <br />
                • Module simulation informatique et graphique <br />
                • Travaux pratique sur site <br />
                • Module économie et gestion <br />• Travail de fin d'études ou
                stage à thème (3 mois)
            </>
        ),
        filieresIngenieures: [
            {
                nom: 'Mines et Carrières',
                image: Ecoles['mine.png'],
            },
            {
                nom: 'Traitement des Eaux',
                image: Ecoles['eaux.png'],
            },
        ],
        commentIntegrer: [
            {
                titre: 'Cycle des techniciens supérieurs',
                image: esmg['recherche1.jpg'],
                sousTitre: "Mines et géologie",
                corps: `L'admission au cycle des techniciens supérieurs des nationaux se fait
                        par voie de concours ou sur titre après le baccalauréat
                        scientifique (C, D, E et F4) de l'année en cours.`,
            },
            {
                titre: 'Cycle des ingénieurs',
                image: esmg['recherche2.jpg'],
                sousTitre: "Mines et carrière, exploitation et traitement des eaux",
                corps: `L'admission au cycle ingénieur se fait par voie
                        de concours après deux années de classes préparatoires
                        et sur dossier à partir des Licences Scientifiques. La
                        formation dure trois années avec un tronc commun les
                        deux premières années ou par voie de passerelle
                        (meilleurs étudiants) après le cycle technicien
                        supérieur.`,
            },
        ],
        meilleursEleves: [
            {
                name: 'Lasme Mel',
                picture: esmg['eleve1.jpg'],
                moyenne: '13,69/20',
                filiere: 'Mines et carrières',
            },
            {
                name: 'Chantal Tamo',
                picture: esmg['eleve2.jpg'],
                moyenne: '13,3/20',
                filiere: 'Traitement Des Eaux',
            },
            {
                name: 'Ouattara Sinaly',
                picture: esmg['eleve3.jpg'],
                moyenne: '13,8/20',
                filiere: 'Traitement Des Eaux',
            },
            {
                name: 'Don Moby',
                picture: esmg['eleve4.jpg'],
                moyenne: '13,69/20',
                filiere: 'Mines et carrières',
            },
            {
                name: 'Christian Josue',
                picture: esmg['eleve5.jpg'],
                moyenne: '13,5/20',
                filiere: 'Mines et carrières',
            },
            {
                name: 'Yao Michel',
                picture: esmg['eleve6.jpg'],
                moyenne: '13,10/20',
                filiere: 'Traitement Des Eaux',
            },
        ],
        partenairesAcademiques: [
            {
                image: partenairesESMG['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESMG['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESMG['PA (3).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESMG['PA (4).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESMG['PA (5).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESMG['PA (6).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESMG['PA (7).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESMG['PA (8).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESMG['PA (9).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
        ],
        partenairesEntreprises: [
            {
                image: partenairesESMG['PE (1).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (2).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (3).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (4).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (5).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (6).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (7).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (8).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (9).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (10).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (11).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (12).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (13).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (14).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (15).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (16).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (17).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (18).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (19).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (20).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (21).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESMG['PE (22).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
        ],
    },
    espe: {
        nom: "ECOLE SUPERIEURE DU PÉTROLE ET DE L'ÉNERGIE",
        logo: Logo['ESPE.png'],
        alt: 'ESPE',
        couleurPrincipale: '#1d4851',
        couleurSecondaire: '#ffc000',
        background: Ecoles['ESPEB.jpg'],
        backgroundAccueil: Ecoles['ESPEB.jpg'],
        photoDirecteur: Directeur['ESPE.png'],
        cyclesEtFilieres: Cycle['ESPE.png'],
        debouches: Debouches['ESPE.png'],
        filieresConcoursDutIntroduction: Ecoles['filiereConcoursESPE.png'],
        filieresConcoursIngIntroduction: Ecoles['filiereConcoursESPE.png'],
        menus: [
            {
                text: 'présentation',
                link: '#presentation',
            },
            {
                text: 'mot du directeur',
                link: '#mot_du_directeur',
            },
            {
                text: 'cycles et filières',
                link: '#cycles_et_filieres',
            },
            {
                text: 'formation',
                link: '#formation',
            },
            {
                text: 'comment intégrer notre école',
                link: '#comment_integrer',
            },
            {
                text: 'débouchés',
                link: '#debouches',
            },
        ],
        presentation: (
            <>
                L'Ecole Supérieure du Pétrole et de l'Énergie (ESPE) a été créée
                en Conseil des Ministres le mercredi 13 novembre 2019. Elle a
                pour principale mission la formation dans les spécialités du
                pétrole et de l'énergie pour la Côte d'Ivoire, la sous-région et
                le Continent Africain. <br />
                En second lieu, l'ESPE devra également répondre aux besoins en
                formation continue et de perfectionnement des ressources
                humaines des secteurs cibles de la formation initiale. <br />
                <br />
                L'ESPE est implantée sur le site Sud de l'INP-HB, à environ 10
                km du centre-ville de Yamoussoukro.
            </>
        ),
        nomDirecteur: 'Dr. Joachim GBALLOU',
        motsDirecteur: `Les activités pétrolières et énergétiques prennent de l'ampleur en Afrique. La Côte
        d'Ivoire, prévoyante, avait déjà considérablement investi dans la formation de
        nombreux cadres scientifiques et techniques. Mais aujourd'hui, il convient de relever
        que cette expertise risque d'être compromise d'une part, par le vieillissement des
        cadres qualifiés et d'autre part, par l'insuffisance des structures de spécialisation
        adéquates dans les secteurs du Pétrole et de l'Énergie. C'est pour anticiper la
        résolution de ces problèmes que l'Ecole Supérieure du Pétrole et de l'Énergie (ESPE)
        est née. La création d'une telle grande école de spécialisation est une opportunité
        exceptionnelle qui offrira à nos jeunes diplômés, l'assurance d'une insertion rapide
        dans le monde du travail et dans le tissu socio-économique. C'est cela notre
        mission à la tête de l'ESPE. Ce challenge, nous le relèverons ensemble avec nos
        partenaires stratégiques.`,
        formation: (
            <>
                Dans le domaine du pétrole , l'ESPE propose les formations
                suivantes : <br />
                <div className="vert">
                    Economie et Techniques de l'Amont Pétrolier (MS ETAMP)
                </div>
                <div className="vert">
                    Economie et Techniques de l'Aval Pétrolier (MS ETAVP)
                </div>{' '}
                <br /> <br />
                Chaque formation, dispensée en anglais, est sanctionnée par un
                double diplôme :
                <div className="vert">
                    Un Diplôme d'Etudes Supérieures Appliquées (DESA) délivré
                    par l'Institut Français du Pétrole (IFP School). Ce diplôme
                    offre la possibilité de s'inscrire à une thèse de doctorat
                    en France;
                </div>
                <div className="vert">
                    Un Mastère Professionnel (Bac+6) délivré par l'INP-HB.
                </div>{' '}
                <br />
                <br />
                Dans le domaine de l'énergie l'ESPE propose un Mastère
                spécialisé en Energies Renouvelables, Technologies et
                Entrepreneuriat (MS ETRE) dont le diplôme est délivré par
                l'Ecole Polytechnique de Paris et reconnu par la Conférence des
                Grandes Ecoles. La formation délocalisée à Abidjan se fait en
                cours du soir et en ligne. Elle est donc adaptée aux personnes
                ayant une activité professionnelle. <br />
                <br />
                En plus des formations diplomantes, l'ESPE propose également des
                formations qualifiantes de courte durée (voir le catalogue ESPE)
            </>
        ),
        plaquette: Ecoles['ESPE.pdf'],
        contacts: ``,
        formations: [
            {
                nom: 'Ingénieur Pétrolier',
                image: Ecoles['petrole.png'],
            },
            {
                nom: 'Amont pétrolier',
                image: Ecoles['filiere1ESCAE.jpg'],
            },
            {
                nom: 'Aval pétrolier',
                image: Ecoles['filiere2ESCAE.jpg'],
            },
            {
                nom: 'énergie technologie et entrepreuneriat',
                image: Ecoles['filiere4ESMG.jpeg'],
            },
        ],
        commentIntegrerNotreEcole: <>Les conditions pour postuler sont les suivantes <br />
        <div className="vert">
            Être titulaire d'un diplôme Bac +5
        </div>
        <div className="vert">
            Avoir un niveau d'anglais correspondant au niveau B2
            du CFER (Cadre Européen Commun de Reference pour les
            langues)
        </div>
        <div className="vert">
            Avoir des prérequis dans le domaine du pétrole et de
            l'Energie
        </div></>,
        meilleursEleves: [
            {
                name: 'Ouattara Sinaly',
                picture: esmg['eleve1.jpg'],
                moyenne: '13,9/20',
                filiere: 'Amont Pétrolier',
            },
            {
                name: 'Dosso Magnama',
                picture: esmg['eleve2.jpg'],
                moyenne: '13,88/20',
                filiere: 'Amont Pétrolier',
            },
            {
                name: 'Lasme Mel',
                picture: esmg['eleve3.jpg'],
                moyenne: '13,86/20',
                filiere: 'Aval Pétrolier',
            },
        ],
        partenairesAcademiques: [
            {
                image: partenairesESPE['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESPE['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESPE['PA (1).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
            {
                image: partenairesESPE['PA (2).png'],
                alt: 'Partenaire Académique',
                lien: '#',
            },
        ],
        partenairesEntreprises: [
            {
                image: partenairesESPE['PE (1).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (2).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (3).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (4).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (5).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (6).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (7).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (8).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (9).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (10).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (11).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (12).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (13).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (14).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (15).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (16).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (17).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (18).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (19).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (20).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
            {
                image: partenairesESPE['PE (21).png'],
                alt: 'Partenaire Entreprise',
                lien: '#',
            },
        ],
    },
    edp: {
        logo: Logo['EDP.png'],
        alt: 'EDP',
        nom: 'ECOLE DOCTORALE POLYTECHNIQUE',
        couleurPrincipale: '#fba51d',
        couleurSecondaire: '#decb2e',
        background: Ecoles['EDPB.jpg'],
        backgroundAccueil: Ecoles['EDPB.jpg'],
        photoDirecteur: Directeur['EDP.png'],
        filieresEDP: Cycle['EDP.png'],
        debouches: Debouches['EDP.png'],
        filieresConcoursDutIntroduction: Ecoles['filiereConcoursEDP.png'],
        filieresConcoursIngIntroduction: Ecoles['filiereConcoursEDP.png'],
        menus: [
            {
                text: 'présentation',
                link: '#presentation',
            },
            {
                text: 'mot du directeur',
                link: '#mot_du_directeur',
            },
            {
                text: 'missions',
                link: '#missions',
            },
            {
                text: 'filières',
                link: '#filieres',
            },
            {
                text: 'comment intégrer notre école',
                link: '#comment_integrer',
            },
            {
                text: 'cotutelle de thèse',
                link: '#cotutelleDeThese',
            },
        ],
        presentation: `L'Ecole Doctorale Polytechnique est en charge de
        l'organisation de la formation doctorale à l'INP-HB : une
        formation par la recherche , à la recherche et à l'innovation.
        Le doctorat est préparé sous la responsabilité d'un directeur
        de thèse, professeur de rang magistral au sein d'une unité
        ou d'une équipe de recherche rattachée à l'école doctorale.
        La formation, qui constitue une expérience professionnelle,
        a une durée de trois ans. Elle est sanctionnée, après la
        rédaction et la soutenance d'une thèse, par la collation du
        grade de docteur. Elle suit les règles dictées par l'arrêté
        ministériel du N° 250/MESRS/DGES/ du 13 décembre 2011
        et la Charte des thèses de l'INP-HB. L'EDP a pour ambition
        d'organiser la formation des docteurs et de les préparer à la
        poursuite de leur carrière. Elle se propose d'apporter aux
        doctorants une culture pluridisciplinaire dans le cadre d'un
        projet scientifique cohérent. Il s'agira aussi pour cette école
        de concourir à la mise en cohérence et à la visibilité
        internationale de l'offre de formation doctorale de l'INP-HB.`,
        nomDirecteur: 'Prof. Benjamin YAO',
        motsDirecteur: `L'Ecole Doctorale Polytechnique de l'Institut National Polytechnique Félix HOUPHOUËT
        BOIGNY est une école à forte interaction avec le monde industriel, tournée vers
        l'innovation technologique. Par les thématiques qui sont abordées, les chercheurs de
        l'INP-HB, ambitionnent d'être au coeur de l'activité innovante de grandes unités
        industrielles de notre pays. L'EDP couvre trois domaines majeurs de compétences :
        Sciences et Technologie, Sciences Agronomiques, Sciences Économiques et de
        Gestion. L'EDP, s'inscrit dans la vision de l'INP-HB de se positionner comme « un
        creuset scientifique majeur pour soutenir le développement scientifique et technologique
        de la Côte d'Ivoire et de la sous-région », en pilotant des thèses qui devront aboutir à des
        applications concrètes : prototype, essais grandeur nature, validation de concepts
        nouveaux, brevets, etc.`,
        missions: (
            <>
                Nos missions se déclinent en sept (7) points essentiels : <br />
                • La mise en oeuvre d'une politique de choix des doctorants
                fondée sur des critères explicites ; <br />
                • La garantie de la qualité de l'encadrement des doctorants par
                les unités et équipes de recherche ; <br />
                • L'organisation des échanges scientifiques et intellectuels
                entre doctorants ; <br />
                • La proposition de formations utiles aux projets de recherche
                des doctorants et à leurs projets professionnels ; <br />
                • L'établissement d'un dispositif d'appui à la poursuite de
                carrières des docteurs, tant dans les établissements publics que
                dans le secteur privé ; <br />
                • Le suivi des carrières des docteurs et, plus généralement, de
                l'ensemble des doctorants inscrits; <br />• Une ouverture
                internationale, notamment dans le cadre d'actions de coopération
                conduites avec des établissements d'enseignement supérieur ou
                centres de recherche étrangers, en particulier par la promotion
                des cotutelles internationales de thèse.
            </>
        ),
        cotutelleDeThese: `Les doctorants ont la possibilité de présenter une thèse
        en cotutelle (inscription dans deux universités : l'INP-HB
        d'une part et une université d'un autre pays). Cette
        procédure, ouverte aux universités et établissements
        d'enseignement supérieur, vise à instaurer et
        développer une coopération scientifique entre des
        équipes de recherche ivoiriennes et étrangères en
        favorisant la mobilité des doctorants.`,
        plaquette: Ecoles['EDP.pdf'],
        contacts: ``,
        commentIntegrerEDP: [
            {
                titre: '',
                image: '',
                corps: `Être titulaire d'un master de recherche ou d'un DEA ou
                tout autre diplôme jugé équivalent à l'issue d'un
                parcours établissant l'aptitude à la recherche. La note
                moyenne du master calculée à partir des moyennes des
                4 semestres doit être au moins égale à 12/20`,
            },
            {
                titre: '',
                image: '',
                corps: `NB : Exceptionnellement, l'EDP peut admettre des
                candidats ayant obtenu le master de recherche avec une
                moyenne sur 2 ans (M1 ou équivalent + M2) compris
                entre 11 et 11,99 sur examen préalable du dossier par la
                commission pédagogique de l'EDP qui donne un avis
                sur l'inscription.`,
            },
            {
                titre: '',
                image: '',
                corps: `Les candidats titulaires d'un diplôme étranger de
                niveau Master peuvent demander une inscription
                dérogatoire aux conditions de diplôme. La demande (à
                déposer par le candidat au secrétariat) sera évaluée par
                le conseil de l'EDP puis transmise au Directeur général de
                l'INP-HB.`,
            },
            {
                titre: '',
                image: '',
                corps: `Bénéficier d'un financement ou de moyens propres jugés
                suffisants. Des possibilités de financement sont proposées
                par l'EDP dans la mesure de ses disponibilités.`,
            },
            {
                titre: '',
                image: '',
                corps: ` Obtenir l'accord d'un directeur de thèse habilité à diriger
                des recherches et celui du directeur de l'unité de recherche
                dans laquelle la préparation de la thèse est envisagée.`,
            },
            {
                titre: '',
                image: '',
                corps: `Posséder un niveau linguistique suffisant en français ou
                en anglais. Les candidats ayant obtenu le Master dans une
                université de langue française ou dans une université de
                langue anglaise sont réputés avoir ce niveau. Les autres
                candidats devront fournir un document de certification de
                niveau linguistique délivré par un organisme de
                certification.`,
            },
        ],

        meilleursEleves: [
            {
                name: 'Binate Ahmed',
                picture: esmg['eleve1.jpg'],
                moyenne: '13,69/20',
                filiere: 'Sciences et Technologie',
            },
            {
                name: 'Haida Diarra',
                picture: esmg['eleve2.jpg'],
                moyenne: '15,9/20',
                filiere: 'Sciences Agronomiques',
            },
            {
                name: 'Fofana Mory',
                picture: esmg['eleve3.jpg'],
                moyenne: '13,8/20',
                filiere: 'Science Économiques et de Gestion',
            },
        ],
    },
    efcpc: {
        logo: Logo['EFCPC.png'],
        alt: 'EFCPC',
        nom: 'ECOLE DE FORMATION CONTINUE ET DE PERFECTIONNEMENT DES CADRES',
        couleurPrincipale: '#b9002a',
        couleurSecondaire: '#008D36',
        background: Ecoles['EFCPCB.jpg'],
        backgroundAccueil: Ecoles['EFCPCB.jpg'],
        photoDirecteur: Directeur['EFCPC.png'],
        cyclesEtFilieres: Cycle['EFCPC.png'],
        debouches: Debouches['EFCPC.png'],
        filieresConcoursDutIntroduction: Ecoles['filiereConcoursEFCPC.png'],
        filieresConcoursIngIntroduction: Ecoles['filiereConcoursEFCPC.png'],
        menus: [
            {
                text: 'présentation',
                link: '#presentation',
            },
            {
                text: 'mot du directeur',
                link: '#mot_du_directeur',
            },
            {
                text: 'atouts',
                link: '#atouts',
            },
            {
                text: 'cycles et filières',
                link: '#cycles_et_filieres',
            },
            {
                text: 'comment intégrer notre école',
                link: '#comment_integrer',
            },
            {
                text: 'formation',
                link: '#formation',
            },
        ],
        presentation: `L'EFCPC est chargée de toutes les actions de formation
        (diplômante ou qualifiante) continue et de perfectionnement des cadres des secteurs publics et privés dans
        les domaines de l'Industrie, de l'Administration, du
        Commerce, de l'Agronomie, du Génie Civil et des Mines
        et Géologie. La formation continue vise à compléter des
        connaissances et compétences acquises lors de la
        formation initiale, à maîtriser des connaissances ou des
        techniques en vue de s'adapter à l'évolution des
        professions. Quant au perfectionnement des cadres, il
        constitue notre véritable cœur de métier. Pour aider les
        entreprises en donnant aux cadres les compétences
        requises pour être efficaces, nous proposons des
        solutions sur mesure. Nous détenons un réseau
        performant d'enseignants permettant d'offrir des
        programmes compétitifs nationaux et internationaux.
        Nous offrons aussi des sessions d'ateliers au sein de
        vos entreprises.`,
        nomDirecteur: 'Dr. Mohamed SAKO',
        motsDirecteur: `Face à l'accélération des évolutions technologiques et de mondialisation de l'économie,
        l'actualisation des connaissances et le développement de nouvelles compétences
        deviennent incontournables pour tous les acteurs du monde socioéconomique. Pour
        faire face à ces défis, l'EFCPC s'est fixé pour objectif, de contribuer à la sécurisation
        des parcours professionnels en accompagnant tous les publics adultes dans leurs
        trajectoires de vie professionnelle et de les former, à tout moment de leur vie par le
        biais d'un dispositif de formation souple, modulaire et diversifié. Ce dispositif est
        réalisé à travers un partenariat entre l'Institut National Polytechnique Félix
        HOUPHOUËT-BOIGNY (INP-HB) et le Conservatoire national des arts et métiers
        (Cnam) de Paris. Accompagner le professionnel tout au long de sa carrière est
        l'ambition de l'EFCPC. Je remercie tous ceux qui ont choisi, de suivre une formation
        à l'EFCPC. Je leur souhaite la bienvenue et une belle réussite dans cette école.`,
        atouts: (
            <>
                {' '}
                <strong>Se former autrement..</strong> <br />
                En collaboration avec le Conservatoire National des Arts et
                Métiers de Paris (Cnam), l'INP-HB entend mettre sur pied un
                système de formation ouvert à tous. <br /> <br />
                <strong>La formation tout au long de la vie</strong> <br />
                La formation tout au long de la vie est une seconde chance
                offerte à toute personne désireuse de se former et d'apprendre
                un métier, à travers le renforcement des capacités ou le
                recyclage
            </>
        ),
        formation: (
            <>
                Nos différentes formations offertes sont regroupées au sein de
                structures fonctionnelles appelées «centres». Ces centres basés
                à Yamoussoukro et à Abidjan (auditeurs du soir) offrent un large
                éventail de programmes de compétences dans les domaines
                tertiaires et techniques. <br />
                <br />
                • Centre d'Étude des Techniques et Ingénierie de Gestion (CETIG){' '}
                <br />
                • Centre de Formation Continue des informaticiens Professionnels
                (CFCIP) <br />
                • Centre de Formation des Ingénieurs des Techniques Sanitaires,
                de Chimie et de Contrôle de Qualité (CFTS2CQ) <br />
                • Centre de Formation aux Techniques de l'Information et de la
                Communication (CFTIC) <br />
                • Centre de Formation en Gestion des Entreprises et des
                Administrations (CFGEA) <br />
                • Centre de Formation Continue en Ingénierie Industrielle
                (CFC2I) <br />
                • Centre des Ingénieurs et des Techniciens de Travaux Publics et
                des Mines (CI2TPM) <br />
                • Centre de Formation des Ingénieurs des Techniques et des
                Ingénieurs Agronomes (CFITIA) <br />
                • CISCO (formation qualifiante) <br />
                • Centre de Formation des Techniciens, des Ingénieurs des
                Techniques et des Ingénieurs en Informatique (CFT3I) <br />•
                Centre de Formation des Ingénieurs des Techniques du Tourisme
                (CFI2T)
            </>
        ),
        plaquette: Ecoles['EFCPC.pdf'],
        contacts: ``,
        commentIntegrer: [
            {
                titre: 'Formation aux particuliers',
                image: Ecoles['filiere1ESCAE.jpg'],
                corps: `L'EFCPC, assure la formation continue des travailleurs
                du secteur Public et du secteur Privé qui désirent se
                perfectionner dans un domaine précis par des actions
                de formations opérationnelles, répondant aux besoins
                d'évolutions des métiers d'aujourd'hui et de demain.`,
            },
            {
                titre: 'Formation aux entreprises',
                image: Ecoles['filiere2ESCAE.jpg'],
                corps: `L'EFCPC, c'est également un renforcement des
                capacités de vos ressources humaines et l'amélioration
                des compétences de votre personnel avec des
                packages de formation adaptés à votre système
                organisationnel, votre orientation stratégique, votre
                planning de travail (séminaires de formation en entreprise,
                formations en e-Learning ou vidéo par conférence)`,
            },
        ],
        meilleursEleves: [
            {
                name: 'Mohamed Karl',
                picture: esmg['eleve1.jpg'],
                moyenne: '13,69/20',
                filiere: 'CETIG',
            },
            {
                name: 'Mariam Dosso',
                picture: esmg['eleve2.jpg'],
                moyenne: '15,9/20',
                filiere: 'CFCIP',
            },
            {
                name: 'Fofana Mory',
                picture: esmg['eleve3.jpg'],
                moyenne: '13,8/20',
                filiere: 'CFTIC',
            },
        ],
    },
}
export const PortailInformations = {
    imageDirecteur: portail['dg.png'],
    motDuDirecteur: `
                    Depuis 2021, l’INP-HB s’est engagé dans un vaste projet d’amélioration de son fonctionnement et de son rôle en tant qu’institution de formation supérieure. 
                    Ces mutations ont fait l’objet d’un décret depuis le 15 juin 2023 avec l’adoption de nouveaux statuts qui viennent légitimer chaque action et renforcer la vision de porter cet institut parmi les plus grandes de l’Afrique. 
                    La réalisation de cette vision s’appuie sur 5 axes stratégiques.
                    <br/><br/>
                    INP-HB, fleuron du système de l’enseignement supérieur et de la recherche scientifique, est désormais reconnu pour la qualité de sa formation avec les accréditations HCERES (Haut Conseil de 
                    l’évaluation de la recherche et de l’enseignement supérieur), CGE (Conférence des Grandes Ecoles), CTI (Commission des Titres d’Ingénieurs), PRED (Programme Reconnaissance et 
                    Equivalence des Diplômes du Conseil Africain et Malgache pour l’Enseignement Supérieur). L’Institut, particulièrement sensible à son ancrage au sein du monde professionnel, travaille à construire des citoyens 
                    de demain aptes à relever les défis de l’émergence de la Côte d’Ivoire et l’Afrique. 
                    Pour y arriver, un vaste réseau d’alumni organisé par pôle de métier l’accompagne et s’assure de l’adéquation des formations avec les enjeux socio-économiques durables. 
                    <br/><br/>
                    L’une des forces de l’INP-HB depuis sa création réside dans la diversification de son offre de formation qui lui permet de nouer des partenariats stratégiques de niveau international. Ces 2 dernières années, 
                    l’Institut s’est résolument ouvert vers le monde à travers la création de formations conjointes avec des partenaires prestigieux sur tous les continents (l’X, HEC, Ecole des Ponts, l’UQAM, l’Université d’Ottawa, 
                    UM6P, l’Université de Shenzen…).
                    <br/><br/>
                    Au niveau de la coopération nationale, l’INP-HB continue de jouer son rôle de locomotive en collaborant étroitement avec les universités nationales à travers la mise à disposition de son plateau technique pour des travaux dirigés, 
                    des collaborations dans le domaine de la formation, de la recherche et de l’innovation. 
                    <br/>
                    En inscrivant la  Production et l’expertise comme un axe stratégique, l’INP-HB formalise des actions existantes depuis toujours. La concrétisation de cet axe s’appuie sur un écosystème organisé autour des unités mixtes de recherche, 
                    des écoles doctorales, des FabLab, des incubateurs et des unités de productions. L’Institut capitalise ainsi sur son savoir-faire pour dynamiser et accompagner les initiatives entrepreneuriales.
                    <br/><br/>
                    A une échelle plus grande, le Technopole de l’INP-HB s’engage avec plusieurs entreprises pour soutenir l’innovation. Cette « smart city » à 230 km d’Abidjan  dont le rôle est de rapprocher l’industrie et la recherche a déjà accueilli 
                    ses premières entreprises et ouvrira très prochainement la Digital Farming School en collaboration avec l’OCP (Office Chérifin du Phosphate).
                    <br/><br/>
                    L’INP-HB, qui a fait de l’excellence son seul critère d’évaluation, s’engage à continuer à offrir à l’Afrique, ses leaders de demain. Pour cela, l’Institut se dotera, très prochainement d’une fondation pour renforcer son ouverture vers 
                    le monde économique. La mise en place de  cette source de financement complémentaire permettra de consolider les relations de l’institut avec les entreprises, mais au-delà, de contribuer fortement  à sa croissance notamment au niveau de 
                    sa capacité d’accueil, de la diversification de son offre de formation et, dans les domaines de la production et de l’expertise. 
                    <br/><br/><br/><br/>

                    <span style={{textAlign: 'center', fontWeight: 'bolder'}}>Moussa A. K DIABY</span>
                     ` ,
    actualites: [
        {
            titre: `L'INP-HB réalise le rêve de Marcellin Kouakou Kouakou : un jeune inventeur de Tié N'Diékro`,
            date: '30/08/2022',
            heure: '16h30',
            image: portail['actu1.jpg'],
            description: `Suite à un reportage mettant en lumière les initiatives d'invention de Marcellin Kouakou Kouakou, l'auteur de la « IV MAX », véhicule artisanal monté avec du matériel de fortune, une délégation de l'INP-HB conduite par son Directeur Général, s'est rendue à Tié N'Diékro le dimanche 21 août 2022 afin de rencontrer ce « génie » de 29 ans.
            Les échanges se sont poursuivis le lundi 22 août à l'INP-HB au cours d'une rencontre qui a vu la participation du maire de Tie N'Diékro, M. Yao Casimir et du jeune Marcellin Kouakou. Pour le Directeur Général de l'INP-HB, il est en effet impératif d'accompagner ce talent qui est parvenu à réaliser une telle prouesse avec le niveau CP1. Pour ce faire, il bénéficiera d'un accompagnement technique de l'Institut dans les domaines de la mécanique, de l'électrique, de la chimie et de bien d'autres, à l'effet de perfectionner ses inventions. Ce qui contribuera à améliorer sa condition de vie et celle de sa population a-t-il relevé.
            Heureux de cette rencontre qu'il a qualifié d'historique, le maire de Tié N'Diékro a remercié la Direction Générale de l'INP-HB pour ce geste salutaire qui aidera le jeune inventeur à aboutir à un projet qui le valorisera ainsi que sa région. Emboitant le pas au maire, Marcelllin Kouakou Kouakou a exprimé sa gratitude à l'Institut pour avoir entendu son cri de cœur, celui de bénéficier d'un soutien pour concrétiser ses projets.
            La rencontre s'est soldée par une visite de l'atelier Lu Ban, localisé au sein du Département de Formation et de Recherche Génie Electrique et Mécanique (DFR GEE), du centre incubateur du site nord de l'INP-HB et du Centre d'innovations et de Technologies de l'Anacarde (CITA).`,
        },
        {
            titre: `Un partenariat fructueux entre l'INP-HB et l'UNA`,
            date: '30/08/2022',
            heure: '16h06',
            image: portail['actu2.jpg'],
            description: `L'Institut National Polytechnique Félix Houphouët-Boigny (INP-HB) et l'Université Nangui Abrogoua (UNA) ont signé un accord-cadre de coopération pour des échanges dans les domaines de la formation, de l'enseignement, de la recherche, de la gouvernance, de la documentation et de la publication de travaux en commun.
            Ces relations de coopération prévoient l'échange d'enseignants, de chercheurs, d'étudiants et d'expérience en matière de pédagogie et de gouvernance universitaire, l'élaboration et la participation à des projets communs de formation, de recherche et de renforcement de capacités des enseignants et du personnel administratif et technique, l'échange de documents et de publications scientifiques, l'organisation de stages et de visites pour étudiants, des missions croisées d'expertises scientifiques et techniques, l'organisation conjointe de conférences, d'ateliers et de séminaires ainsi que des échanges sportifs et culturels.
            Par ailleurs, les deux institutions s'engagent selon leurs potentialités et leurs possibilités à rechercher les moyens devant permettre la réalisation des échanges et l'exécution des activités programmées, à participer au montage et à l'exécution de programmes spéciaux conjoints de formation ou de recherche et s'assurer un soutien réciproque au développement d'une coopération fructueuse.`,
        },
        {
            titre: `INP-HB : le concours bacheliers lancé au Niger`,
            date: '30/08/2022',
            heure: '16h04',
            image: portail['actu3.jpg'],
            description: `Dans le cadre de sa politique d'ouverture sur l'extérieur, l'Institut National Polytechnique Félix Houphouët-Boigny (INP-HB) a organisé en partenariat avec le Ministère de l'Enseignement Supérieur et de la Recherche du Niger, le concours d'entrée à l'INP-HB des nouveaux bacheliers Nigériens.
            La délégation de l'Institut a été reçue au Niger, le jeudi 25 août 2022 par le Professeur Mounkaïla Abdo.L. Serki Directeur Général de l'office du Baccalauréat, des Equivalences et des Examens et Concours du Supérieur (OBEECS) et le Directeur des Examens et des Concours, M. Karimou Djibo.   
            La composition a eu lieu le vendredi 26 août 2022 de 11H30 à 15 GMT (heure du Niger) au lycée Kassaï de Niamey avec la participation de 151 candidats dont 27 filles et 124 garçons.`,
        },
        {
            titre: `Concours bacheliers 2022 : 1581 candidats affrontent les épreuves du second tour`,
            date: '30/08/2022',
            heure: '14h03',
            image: portail['actu4.jpg'],
            description: `Après la phase 1 du concours d'entrée à l'INP-HB qui s'est tenue du 01 au 20 Août 2022 avec la participation de 6525 candidats, les élèves déclarés admissibles ont pris part aux épreuves écrites le dimanche 28 août 2022 au Lycée Classique d'Abidjan.
            Au nombre de 1581, ces élèves ont affronté durant 03 heures, les épreuves d'anglais, mathématiques et français. A la suite de ce test, l'Institut retiendra dans ses différentes écoles, environ 690 étudiants en cycle court et long.
            Les résultats sont attendus pour le mardi 06 Septembre 2022 sur les différentes plateformes de l'Institut.`,
        },
    ],
    histoires: [
        {
            titre: `Missions`,
            description: (
                <>
                    {' '}
                    <div className="vert">
                        Formation : Formation initiale et continue à travers des
                        formations diplômantes et qualifiantes (recyclage,
                        perfectionnement) des techniciens supérieurs, des
                        ingénieurs des techniques et des ingénieurs de
                        conception dans les domaines de l'industrie, du
                        commerce, de l'administration, du génie civil, des mines
                        et de la géologie.
                    </div>
                    <div className="vert">
                        Innovation : Recherche - développement{' '}
                    </div>
                    <div className="vert">
                        Transformation : Assistance et production au profit des
                        entreprises et administrations.
                    </div>
                </>
            ),
        },
        {
            titre: `Ambitions`,
            description: (
                <>
                    {' '}
                    <div className="vert">
                        Nos principales ambitions sont :{' '}
                    </div>
                    <div className="vert">
                        Le développement de notre leadership tant au plan
                        national qu'à l'échelle continentale dans le domaine de
                        la formation et de la recherche technique et
                        technologique.
                    </div>
                    <div className="vert">
                        Être le premier maillon de la future technopole de
                        Yamoussoukro.
                    </div>
                </>
            ),
        },
        {
            titre: `vision`,
            description: `Faire de l'INP-HB un « creuset scientifique majeur pour soutenir le développement technologique de la Côte d'Ivoire et de la région africaine.`,
        },
        {
            titre: `L'excellence de la formation`,
            description: `L'INP-HB, par la qualité du recrutement de ses élèves et de ses enseignants a toujours été un centre d'excellence dans la sous-région avec pour mission essentielle de fournir au continent des cadres de haut niveau issus de divers secteurs d'activités.`,
        },
        {
            titre: `Le professionnalisme de l'administration`,
            description: `La force de l'organisation de l'Institut réside en partie, dans les capacités de l'administration à se renouveler pour s'adapter à son environnement en vue de répondre aux besoins des enseignants, des étudiants et des partenaires externes.`,
        },
        {
            titre: `Une communauté forte et solidaire`,
            description: `Au sein de sa communauté, l'INP-HB favorise une synergie dans laquelle chacun des membres trouve son avantage à être un acteur dans une institution phare, à laquelle il s'identifie et dont il partage les orientations stratégiques.`,
        },
        {
            titre: `Une politique d'ouverture et de partenariat interinstitutionnel`,
            description: `De nombreux accords de coopération avec des centres de formation et de recherche mondialement reconnus contribuent à améliorer efficacement la qualité de la formation dispensée et à réaliser la mission de veille technologique. Le recrutement d'élèves issus d'autres pays africains, sans barrière linguistique, traduit le caractère panafricaniste de l'Institut et réaffirme sa vocation régionale.`,
        },
        {
            titre: `Le respect de l'environnement`,
            description: `L'INP-HB s'inscrit résolument dans le cadre du développement durable de la ville de Yamoussoukro, capitale politique de la Côte d'Ivoire. Son objectif est de créer un cadre de travail sain et agréable, propice à l'épanouissement de ses personnels et des élèves.`,
        },
        {
            titre: `L'innovation au service de la population`,
            description: `L'INP-HB affirme sa présence citoyenne à travers des actions d'innovation, de valorisation et de transfert technologique en vue d'améliorer les conditions de vie et de travail des populations et de faciliter l'insertion professionnelle des jeunes.`,
        },
    ],
    organisation: [
        {
            titre: `Organigramme`,
            description: (
                <Row className="align-items-center justify-content-center">
                    <Col
                        className="d-flex justify-content-center align-items-center"
                        xxl={3}
                        xl={5}
                        lg={6}
                        md={6}
                    >
                        <img
                            src={portail['organigramme.png']}
                            alt=""
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
            ),
        },
        {
            titre: `La Commission Consultative de Gestion`,
            description: (
                <>
                    {' '}
                    La Commission Consultative de Gestion exerce un contrôle sur
                    l'Institut conformément aux dispositions prévues par les
                    textes portant organisation administrative des
                    Établissements Publics Nationaux (EPN). Elle est composée
                    des entités suivantes:
                    <div className="vert">
                        Ministre de l'Enseignement Supérieur, de la Recherche et
                        de l'Innovation Technologique ou son représentant{' '}
                    </div>
                    <div className="vert">
                        Ministre de l'Agriculture et des Ressources Animales ou
                        son représentant
                    </div>
                    <div className="vert">
                        Ministre de l'Économie et des Finances ou son
                        représentant
                    </div>
                    <div className="vert">
                        Ministre de l'Emploi, de la Fonction Publique et de la
                        Prévoyance Sociale ou son représentant
                    </div>
                    <div className="vert">
                        Ministre Résident du District Autonome de Yamoussoukro
                        ou son représentant
                    </div>
                    <div className="vert">
                        Président de la Chambre de Commerce et d'Industrie de
                        Côte d'Ivoire ou son suppléant
                    </div>
                    <div className="vert">
                        Président de la Chambre d'Agriculture ou son suppléant
                    </div>
                    <div className="vert">
                        Contrôleur Budgétaire et l'Agent Comptable avec voix
                        consultatives
                    </div>
                </>
            ),
        },
        {
            titre: `Le Conseil d'Institut`,
            description: (
                <>
                    Le Conseil est l'organe qui régule la politique de
                    l'Institut. Il est chargé entre autres de :
                    <div className="vert">
                        Définir la politique de formation et de recherche et de
                        veiller à sa bonne application
                    </div>
                    <div className="vert">
                        Veiller à la bonne utilisation des ressources allouées
                        en vue de l'atteinte des objectifs assignés{' '}
                    </div>
                    <div className="vert">
                        Garantir l'application des textes qui régissent l'INP-HB
                    </div>{' '}
                    <br />
                    Le Conseil de l'INP-HB comprend 64 membres dont:
                    <div className="vert">
                        26 membres statutaires exerçant des fonctions de
                        direction au sein de l'INP-HB{' '}
                    </div>
                    <div className="vert">
                        5 émanant de la Direction Générale{' '}
                    </div>
                    <div className="vert">6 Directeurs d'Écoles </div>
                    <div className="vert">15 Directeurs de départements </div>
                    <div className="vert">
                        25 membres élus par les différentes catégories
                        socioprofessionnelles{' '}
                    </div>
                    <div className="vert">15 enseignants </div>
                    <div className="vert">
                        5 personnels administratifs et techniques{' '}
                    </div>
                    <div className="vert">5 élèves </div>
                    <div className="vert">
                        13 membres désignés parmi des personnalités du monde des
                        entreprises et de l'administration.{' '}
                    </div>
                </>
            ),
        },
        {
            titre: `La Direction Générale`,
            description: (
                <>
                    La Direction Générale comprend:
                    <div className="vert">
                        Le Directeur Général ordonnateur du budget de l'INP-HB
                    </div>
                    <div className="vert">
                        Le Directeur Général Adjoint chargé de la planification,
                        de la coordination des enseignements et des activités de
                        recherche
                    </div>
                    <div className="vert">
                        Le Sécrétaire Général chargé de la coordination des
                        services administratifs et techniques.
                    </div>
                    <div className="vert">
                        Le Directeur des Ressources Humaines
                    </div>
                    <div className="vert">Le Directeur des Finances</div>
                </>
            ),
        },
        {
            titre: `Les Sous-Directions`,
            description: (
                <>
                    <div className="vert">
                        la Sous-direction du Patrimoine et de la Maintenance{' '}
                    </div>
                    <div className="vert">
                        la Sous-direction de l'Informatique{' '}
                    </div>
                    <div className="vert">
                        la Sous-direction de la Documentation, de l'Information
                        Scientifique et de l'Edition{' '}
                    </div>
                    <div className="vert">
                        la Sous-direction de l'Extrascolaire{' '}
                    </div>
                    <div className="vert">
                        la Sous-direction de la Scolarité, de l'Accueil et de
                        l'Information{' '}
                    </div>
                    <div className="vert">
                        la Sous-direction des Relations Extérieures et de
                        l'Antenne d'Abidjan{' '}
                    </div>
                    <div className="vert">la Sous-direction du Budget </div>
                    <div className="vert">
                        la Sous-direction de la Comptabilité{' '}
                    </div>
                    <div className="vert">
                        la Sous-direction des Personnels Administratifs et
                        Techniques{' '}
                    </div>
                </>
            ),
        },
        {
            titre: `Les Cellules Spécialisées`,
            description: (
                <>
                    Le Conseil est l'organe qui régule la politique de
                    l'Institut. Il est chargé entre autres de :
                    <div className="vert">la Cellule Contrôle de Gestion </div>
                    <div className="vert">
                        la Cellule Études et Administration Générale{' '}
                    </div>
                    <div className="vert">
                        la Cellule Ingénierie pédagogique{' '}
                    </div>
                    <div className="vert">la Cellule Communication </div>
                    <div className="vert">
                        la Cellule de suivi de la Maintenance, des Conventions
                        et Projets{' '}
                    </div>
                    <div className="vert">le Service Accueil et Missions </div>
                    <div className="vert">
                        le Service de la Planification et de la Programmation
                        des Enseignements{' '}
                    </div>
                    <div className="vert">
                        le Service Recherche, Développement et Coopération{' '}
                    </div>
                    <div className="vert">
                        le Service de la Promotion des Relations avec les
                        Entreprises (SPRE){' '}
                    </div>
                </>
            ),
        },
        {
            titre: "Le Comité d'Enseignement et de Gestion",
            description: (
                <>
                    Le Comité d'Enseignement et de Gestion remplace le Conseil
                    Pédagogique et le Comité de Direction. A ce titre, il veille
                    à:
                    <div className="vert">
                        réalisation effective des charges horaires attribuées
                        aux enseignants
                    </div>
                    <div className="vert">
                        la qualité de la prestation des enseignants.
                    </div>
                    Ce comité conçoit et met en œuvre la politique de gestion
                    quotidienne de l'INP-HB. Il est l'instrument de consultation
                    à la disposition du Directeur Général dans ses fonctions
                    d'ordonnateur, mais également du Directeur Général Adjoint,
                    du Secrétaire Général ainsi que de tous les autres
                    Directeurs. <br />
                    Le Comité d'Enseignement et de Gestion comprend:
                    <div className="vert">le Directeur Général </div>
                    <div className="vert">le Directeur Général Adjoint </div>
                    <div className="vert">le Secrétaire Général </div>
                    <div className="vert">le Directeur des Finances </div>
                    <div className="vert">
                        le Directeur des Ressources Humaines{' '}
                    </div>
                    <div className="vert">les 6 Directeurs d'Écoles </div>
                    <div className="vert">
                        les 14 Directeurs de Départements{' '}
                    </div>
                    <div className="vert">
                        les Responsables des Cellules spécialisées{' '}
                    </div>
                </>
            ),
        },
        {
            titre: `Le Conseil Scientifique`,
            description: (
                <>
                    Le Conseil Scientifique conçoit et met en œuvre la politique
                    scientifique de l'INP-HB à savoir :
                    <div className="vert">La conception</div>
                    <div className="vert">
                        La conduite et évaluation des programmes de recherche
                    </div>
                    <div className="vert">L'instance d'arbitrage</div>
                    <div className="vert">
                        Le développement des laboratoires, etc
                    </div>{' '}
                    <br />
                    Le Conseil Scientifique est composé:
                    <div className="vert">
                        d'enseignants de différents grades choisis dans les
                        départements de l'INP-HB
                    </div>
                    <div className="vert">
                        de personnalités extérieures à l'Institut cooptées parmi
                        des universitaires de renom, des responsables
                        d'entreprises, etc.
                    </div>
                </>
            ),
        },
    ],
    sites: [
        {
            titre: 'Site Sud',
            description: (
                <div>
                    Achevé en 1979, le site Sud a été initialement créé dans le but d'abriter
                    l'ex Ecole Nationale Supérieure des Travaux Publics (ENSTP).
                    Il est reconnaissable par ses imposantes arcades et son
                    architecture futuriste. Il comprend les Écoles, les
                    Directions et Services suivants :{' '}
                </div>
            ),
            image: portail['SiteSud.jpg'],
            images:[
                {
                    image: portail['sudI1.jpg'],
                },
                {
                    image: portail['sudI2.jpg'],
                },
                {
                    image: portail['sudI3.jpg'],
                },
                {
                    image: portail['sudI4.jpg'],
                },
                {
                    image: portail['sudI5.jpg'],
                },
            ],
            ecoles: [
                {
                    nom: 'La Direction Générale Adjointe Chargée de la Pédagogie et de la Recherche (DGA - PR)',
                    lien: '#',
                },
                {
                    nom: "L'Ecole Supérieure des Travaux Publics (ESTP)",
                    lien: '/ecoles/estp',
                },
                {
                    nom: "L'Ecole Supérieure des Mines et Géologie (ESMG)",
                    lien: '/ecoles/esmg,',
                },
                {
                    nom: "L'Ecole Supérieure du Pétrole et de l'Energie (ESPE)",
                    lien: '/ecoles/espe,',
                },
                {
                    nom: 'Le DFR Mathématiques et Informatique (DFR-MI)',
                    lien: '#',
                },
                { nom: 'Le DFR Bâtiments et Urbanisme (DFR-BU)', lien: '#' },
                {
                    nom: 'Le DFR Sciences de la Terre et des Ressources Minières (DFR-STERMI)',
                    lien: '#',
                },
                {
                    nom: 'Le DFR Infrastructures et Transport (DFR-IT)',
                    lien: '#',
                },
                { nom: 'La Direction des Ressources Humaines', lien: '#' },
                {
                    nom: "Le Centre de Promotion et d'Expertise (CPEX)",
                    lien: '#',
                },
                {
                    nom: 'Le Centre Regional de Formation Supérieure en Métrologie (CREFSEM)',
                    lien: '#',
                },
                {
                    nom: `Le Centre d'Excellence Africain en Mines et Environnement Minier (CEA - MEM)`,
                    lien: '#',
                }
            ],
        },
        {
            titre: 'Site Centre',
            description:
                "Bâti en 1983, le site centre a été le siège de l´ex Institut National Supérieur de l´Enseignement Technique (INSET). Aujourd'hui, il abrite :",
            image: portail['SiteCentre.jpg'],
            images:[
                {
                    image: portail['centreI1.jpg'],
                },
                {
                    image: portail['centreI2.jpg'],
                },
                {
                    image: portail['centreI3.jpg'],
                },
                {
                    image: portail['centreI4.jpg'],
                },
            ],
            ecoles: [
                {
                    nom: 'La Direction Générale (DG)',
                    lien: '#',
                },
                {
                    nom: "L'Ecole Supérieure d'Industrie (ESI)",
                    lien: '/ecoles/esi',
                },
                {
                    nom: "L'Ecole Supérieure de Commerce et d'Administration d'Entreprise (ESCAE)",
                    lien: '/ecoles/escae',
                },
                {
                    nom: "L'International Data Science Institute (IDSI)",
                    lien: '/ecole/ESCAE',
                },
                {
                    nom: 'Le Département Génie Mécanique et Energétique (DFR-GME)',
                    lien: '#',
                },
                {
                    nom: 'Le Département Génie Electrique et Electronique (DFR-GEE)',
                    lien: '#',
                },
                {
                    nom: 'Le Département Gestion Commerce et Economie Appliquée (DFR-GCEA)',
                    lien: '#',
                },
                {
                    nom: 'Le Département Finance, Comptabilité, Droit (DFR-FCD)',
                    lien: '#',
                },
                {
                    nom: 'Le Département Langues et Sciences Humaines (DFR-LSH)',
                    lien: '#',
                },
                {
                    nom: 'Le Département Sciences Administratives (DFR-SA)',
                    lien: '#',
                },
                { nom: "L'usine école du Centre", lien: '#' },
            ],
        },
        {
            titre: 'Site Nord',
            description:
                "Bâti en 1983, le site nord a été le siège de l´ex Institut National Supérieur de l´Enseignement Technique (INSET). Il accueille en ce jour :",
            image: portail['SiteNord.jpg'],
            images:[
                {
                    image: portail['nordI1.jpg'],
                },
                {
                    image: portail['nordI2.jpg'],
                },
                {
                    image: portail['nordI3.jpg'],
                },
                {
                    image: portail['nordI4.jpg'],
                },
            ],
            ecoles: [
                {
                    nom: "La Direction Générale Adjointe Chargée de la Coopération Internationale et du Développement du Technopôle (DGA - CIT)",
                    lien: '#',
                },
                {
                    nom: "L'Ecole Supérieure d'Agronomie (ESA)",
                    lien: '/ecoles/esa',
                },
                {
                    nom: "L'Ecole de Formation Continue et de Perfectionnement des Cadres (EFCPC)",
                    lien: '/ecoles/efcpc',
                },
                {
                    nom: "L'Ecole Doctorale Polytechnique (EDP)",
                    lien: '/ecoles/edp',
                },
                { nom: "Le centre de Formation Préparatoire au Diplôme d'Expertise Comptable de UEMOA", lien: 'https://descogef.inphb.ci/' },
                { nom: "Le Centre d'Excellence Africain pour la valorisation des déchets en produits à haute valeur ajoutée (CEA - VALOPRO)", lien: 'https://cea-valopro.inphb.ci/' },
                {
                    nom: 'Le Département Génie Chimique et AgroAlimentaire (DFR-GCAA)',
                    lien: '#',
                },
                {
                    nom: 'Le Département Eaux Forêts et Environnement (DFR-FOREN)',
                    lien: '#',
                },
                {
                    nom: 'Le Département Agriculture Ressources Animales (DFR-ARA)',
                    lien: '#',
                },
                {
                    nom: 'Le Département Génie Rural et Sciences Géographiques (DFR-GRSG)',
                    lien: '#',
                },
                { nom: "La Ferme d'application", lien: '#' },
                { nom: "Lusine école du Nord", lien: '#' },
            ],
        },
        {
            titre: 'Antenne Abidjan',
            description:
                "Basée à Abidjan sise à Cocody Danga, l´antenne d´Abidjan assure la continuité administrative de l´INP-HB au sein de la capitale économique. Elle accueille également certains auditeurs de l’Ecole de Formation Continue et de Perfectionnement des Cadres (EFCPC) ainsi que des élèves de troisième année de l’Institut notamment ceux de l’Ecole Supérieure de Commerce et d’Administration des Entreprises (ESCAE). Sous la coupe de la Sous Direction de l'Antenne d'Abidjan, Il regroupe :",
            image: portail['Antenne.jpg'],
            ecoles: [
                {
                    nom: "Le Centre de Préparation aux Diplômes de l'Expertise Comptable (CPDEC)",
                    lien: 'https://cpdec.inphb.ci/vues/cpdec/index.php',
                },
                {
                    nom: 'Le Service de Promotion et de Relation avec les Entreprises (SPRE)',
                    lien: '#',
                },
            ],
        },
    ],
    grandsProjets: [
        {
            nom: 'REHABILITATION DES INFRASTRUCTURES',
            contenu: ` Depuis 2013, un vaste programme de réhabilitation des infrastructures et d’équipement de 
            l’Institut National Polytechnique Houphouët-Boigny (INP-HB) de Yamoussoukro a été mis en œuvre à travers
            les financements des premier et deuxième Contrats de Désendettement et de Développement (C2D). 
            L'année 2014-2015 a été celle des grands chantiers de rénovation de l'Institut en vue de la mise en place
            du futur technopôle dont l'INP-HB est le 1er maillon. A cet effet, une enveloppe globale de 
            34 milliards de FCFA a permis la réhabilitation et l’équipement de l’INP Centre, Sud et Nord mais également, 
            la rénovation des curricula de l’Ecole Supérieure d’Agronomie (ESA) et de l’Ecole Supérieure d’Industrie (ESI), 
            l’octroi de bourses aux étudiants et aux enseignants ainsi que la modernisation 
            des Classes Préparatoires des Classes Préparatoires aux Grandes Ecoles (CPGE)
            Ces différents investissements du Gouvernement ont contribué à redorer le blason de 
            l’INP-HB en tant que fleuron dans la formation des cadres de la Côte d’Ivoire mais également d’Afrique.              `,
        },
        {
            nom: 'RENOUVELLEMENT DES EQUIPEMENTS',
            contenu: `Grâce aux financements acquis dans le cadre du Contrat de Désendettement et de Développement (C2D) 
            et de certains projets, l’INP-HB s’est doté d’équipements de pointe pour ses laboratoires et salles machines 
            En notre qualité locomotive des établissements d’enseignement supérieur, notre orientation stratégique est 
            résolument tournée vers les Technologies de l'Information et de la Communication (TIC). Nous envisageons 
            équiper toutes nos administrations de matériel dernière génération et d'un système de communication 
            informatisé afin de réduire la pesanteur administrative. Nous allons également doter les laboratoires 
            d'outils de recherche performants permettant de générer des résultats et impacter la production de nos 
            matières agricoles.`,
        },
        {
            nom: 'UNE RECHERCHE APPLIQUEE AUX BESOINS DES ENTREPRISES',
            contenu: `« Faire de l’Institut le fleuron et la locomotive du système de l’enseignement supérieur et de la recherche
            scientifique en Afrique de l’Ouest et dans la sous-région » est l’objectif majeur du Directeur Général. 
            Pour ce faire, une place de choix est accordée à la recherche et à l’innovation. 
            Cela implique : 
            -	Une communication accrue sur le résultat des innovations et recherches développées au sein de l’Institut à travers des forums, foires, expositions, rencontres scientifiques et supports de communication ;
            -	Le développement d’un lien étroit avec les entreprises afin de permettre à l’Institut d’apporter des réponses aux problématiques industrielles rencontrées par les entreprises ;  
            -	L’organisation de grands évènements telle la semaine de la recherche et de l’innovation dans l’optique de favoriser le dialogue entre enseignants-chercheurs, étudiants, industriels et utilisateurs des résultats et innovations issus de la recherche. 
            `,
        },
        {
            nom: 'RENOVATION PEDAGOGIQUE',
            contenu: `Renforcer et diversifier l'offre de formation initiale et professionnelle, réviser les programmes actuels pour les adapter au système Licence-Master-Doctorat (LMD) est l'un de nos objectifs majeurs.
            Dans un souci de compétitivité internationale et face aux enjeux stratégiques des Objectifs du Millénaire pour le Développement (OMD), nous voulons orienter notre offre de formation vers les métiers d'avenir pour favoriser l'emploi et l'émergence de nos pays Africains.
            Nous envisageons également créer une  école doctorale en adéquation avec le système éducatif ivoirien et international, en partenariat avec des établissements du monde entier. Cette Ecole doctorale facilitera le perfectionnement des enseignants à travers le renforcement des capacités et la mobilité internationale.`,
        },
        {
            nom: 'Une ouverture sur l’international ',
            contenu: ` Dans le cadre de sa politique d’ouverture à l’international, 
            l’INP-HB a développé de nombreux partenariats à travers le monde. 
            Ces accords de partenariat donnent lieu à des échanges de professeurs, d’étudiants, 
            de documents scientifiques et d'enseignements et à la mise en œuvre de nouveaux programmes de formation. 
            Ils prévoient en outre un accompagnement des écoles partenaires pour la promotion de l’entreprenariat, 
            des incubateurs et du Technopôle de l’INP-HB.
            Conscient de l’importance des échanges et des collaborations internationales, l’INP-HB s’engage à 
            poursuivre le développement des partenariats stratégiques en Afrique et à l'étranger avec des 
            établissements supérieurs de haute renommée et des sociétés savantes partout dans le monde. 
            `,
        },
        {
            nom: 'REORGANISATION DU MODE DE GOUVERNANCE',
            contenu: `Les mutations de l'environnement technologique économique, et social exposent les limites de nos 
            systèmes d'organisation et de gestion. La concurrence, l'évolution du marché, la compétition internationale 
            montrent que pour s'adapter à cet environnement mouvant, il faut adopter une nouvelle vision de l'organisation 
            fondée sur la bonne gouvernance et sur de nouveaux instruments de pilotage. Adapter notre mode de gouvernance 
            pour accroître nos performances, tel est le défi que nous nous sommes lancés. Pour ce faire, un audit 
            organisationnel a été initié pour relever les dysfonctionnements et apporter les réformes idoines à notre mode 
            de gouvernance.`,
        },
        {
            nom: "EXTENSION DE L’OFFRE DE FORMATION ET AUGMENTATION DE LA CAPACITE D’ACCUEIL",
            contenu: `Face à la concurrence et aux besoins sans cesse croissants des entreprises du secteur privé, 
            l'INP-HB œuvre constamment à l’adaptation et à l’extension de son offre de formation. Par ailleurs, 
            dans le souci de fournir à la Côte d'Ivoire et à l’Afrique, des Ingénieurs et Techniciens Supérieurs de qualité 
            dans tous les secteurs clés de l’économie, l’Institut prévoit l’augmentation de sa capacité d’accueil en termes de 
            résidences universitaires et la reconstruction de son antenne sise à Abidjan Cocody Danga.`,
        },
        {
            nom: 'AMELIORATION DU CADRE DE VIE',
            contenu: `Un environnement de travail sain élimine le stress et favorise une augmentation de la productivité
            des employés. Pour ce faire, l’Institut entend œuvrer au maintien d’un environnement sain de travail, 
            propice à des études supérieures de haut niveau. 
            Au niveau environnemental des actions continuent d’être menées pour l'aménagement de nos espaces verts sur les 
            sites de l’Institut et la bordure du lac attenant aux résidences des Directeurs. D'autres opérations 
            d'envergure dans ce sens seront régulièrement instituées avec toutes les forces vives de l'institut pour 
            donner un éclat particulier à notre environnement de travail et d'études. Au niveau économique et social, 
            des réflexions sont menées pour l'amélioration des conditions de nos salariés. 
            `,
        },
        {
            nom: 'LA MISE EN ŒUVRE DU PROJET DU TECHNOPÔLE',
            contenu: `Le technopôle se définit comme un site urbain où sont rassemblées des activités innovantes de haute technologie. 
            Il est spécialement aménagé pour accueillir des entreprises de production ou de services dans un domaine d'activité ou en favoriser la création.
            Selon le décret 96-678 du 04/09/96, l'INP-HB est le noyau du futur technopôle de Yamoussoukro qui va dans un futur proche, à l'horizon 2025, 
            rassembler des entreprises et des institutions en vue de favoriser l'économie, l'enseignement et la recherche en Côte d'Ivoire et fonder 
            le développement du pays sur la recherche et l'innovation technologique. 
            Le technopôle permettra de développer des spécialités multisectorielles favorisant la recherche.
            Ses objectifs sont les suivants :
            -	Améliorer la capacité compétitive de l'entreprise et de l'industrie ivoirienne ;
            -	Favoriser l'implantation d'entreprises innovantes, par la valorisation des résultats de recherche ;
            -	Inciter les investissements étrangers ;
            -	Développer des compétences de haut niveau capables de gérer les projets innovants ;
            -	Favoriser la recherche scientifique dans les domaines liés aux priorités nationales et aux besoins du monde économique ;
            -	Promouvoir l'innovation technologique et projets innovants à haute valeur ajoutée ;
            -	Polariser les entreprises économiques dont les activités sont basées sur la Recherche-Développement et l'Innovation Technologique ;
            -	Stimuler la création d'emplois, en particulier pour les diplômés de l'enseignement supérieur.
            A travers ce projet ambitieux, l’INP-HB se veut être la « future Silicon Valley d’Afrique ». 
            
            `,
        },
        {
            nom: '1ER MAILLON DU FUTUR TECHNOPÔLE DE YAMOUSSOUKRO',
            contenu: (
                <div>
                    {' '}
                    C'est quoi un technopôle ? Un technopôle est un vaste parc
                    d'activités alliant recherche, formation et production
                    autour d'une ou plusieurs « spécialités » industrielles.{' '}
                    <br />
                    Selon le décret 96-678 du 04/09/96, l'INP-HB est le noyau du
                    futur technopôle de Yamoussoukro qui va dans un futur
                    proche, à l'horizon 2025, rassembler des entreprises et des
                    institutions en vue de favoriser l'économie, l'enseignement
                    et la recherche en Côte d'Ivoire et fonder le développement
                    du pays sur la recherche et l'innovation technologique.
                    Pourquoi un technopôle ?<br /> <br />
                    Autant la zone franche de Grand Bassam est spécialisée dans
                    les technologies de l'Information et de la Communication
                    (TIC), autant le cadre institutionnel et opérationnel que
                    représente le technopôle développera des spécialités
                    multisectorielles favorisant la recherche.
                    <br />
                    Ses objectifs sont les suivants :<br />
                    <br />
                    <div className="vert">
                        Améliorer la capacité compétitive de l'entreprise et de
                        l'industrie ivoirienne.
                    </div>
                    <div className="vert">
                        Favoriser l'implantation d'entreprises innovantes, par
                        la valorisation des résultats de recherche.
                    </div>
                    <div className="vert">
                        Inciter les l'investissement étrangers.
                    </div>
                    <div className="vert">
                        Développer des compétences de haut niveau capables de
                        gérer les projets innovants.
                    </div>
                    <div className="vert">
                        Favoriser la recherche scientifique dans les domaines
                        liés aux priorités nationales et aux besoins du monde
                        économique.
                    </div>
                    <div className="vert">
                        Promouvoir l'innovation technologique et projets
                        innovants à haute valeur ajoutée.
                    </div>
                    <div className="vert">
                        Polariser les entreprises économiques dont les activités
                        sont basées sur la Recherche-Développement et
                        l'Innovation Technologique.
                    </div>
                    <div className="vert">
                        Stimuler la création d'emplois, en particulier pour les
                        diplômés de l'enseignement supérieur.
                    </div>
                    <br />
                    Pour ce faire, ce technopôle sera une vitrine du
                    développement technologique dans le domaine agricole en
                    impulsant le transfert technologique des centres de
                    recherche vers les entreprises. Cette technopole va booster,
                    moderniser notre agriculture.
                    <br />
                    <br />
                    Des pays du continent Africain tels que le Maroc, La Tunisie
                    et Madagascar l'ont déjà expérimenté. En favorisant la
                    création de pôles régionaux de compétitivité, Le futur
                    technopôle de la capitale politique qui va démarrer à partir
                    de l'INPHB va certainement être un moteur de développement
                    sous régional.
                </div>
            ),
        },
      
    ],
    projetsInternationaux: [
        {
            nom: "OUVERTURE DE LA FORMATION SUR L'ESPACE AFRICAIN",
            contenu: (
                <div>
                    {' '}
                    Pour concrétiser le caractère continental de l'Institut,
                    nous mettons en place une politique conquérante en direction
                    des pays de la région. Il s'agira d'accueillir des
                    ressortissants de divers pays africains dans nos programmes
                    sans barrière de langue ni de culture.
                    <br />
                    Nous voulons une saine émulation des compétences Africaines
                    va certainement constituer un grenier de compétences
                    important pour notre continent et impacter positivement
                    notre intégration et notre positionnement face aux enjeux
                    compétitifs et défis mondiaux.
                </div>
            ),
        },
        {
            nom: 'PARTENARIATS INTERNATIONAUX',
            contenu: (
                <div>
                    Dans ce contexte de mondialisation il nous semble primordial
                    de créer des partenariats productifs avec des organisations,
                    universités, entreprises en vue de favoriser la Recherche
                    sur des travaux d'intérêts communs, la mobilité étudiante
                    (stages de perfectionnement, échanges internationaux
                    d'étudiants, concours d'intégration dans des écoles
                    étrangères…) la mobilité enseignante (échanges
                    internationaux d'enseignants ; Recherche. <br />
                    La mobilité internationale est nécessaire à l'excellence
                    scientifique qui suppose des interactions entre étudiants et
                    chercheurs au niveau international. Elle favorise les
                    coopérations entre laboratoires de recherche de différents
                    pays et la formation de réseaux internationaux de
                    chercheurs. <br />
                    Elle participe aussi à la compétitivité internationale des
                    établissements d'enseignement et des laboratoires de
                    recherche publics et privés, compétitivité de plus en plus
                    évaluée au travers des classements internationaux. <br />
                    Dans cette otique l'INP-HB a signé des partenariats et
                    conventions avec de grandes institutions et de grandes
                    universités.
                </div>
            ),
        },
    ],
    evenements: [
        {
            titre: 'Une série lancée par les polytechs',
            description: `L'objectif est de mettre en exergue, le savoir-faire des élèves de l'INP-HB afin de faire converger leurs connaissances.`,
            heure: '08h00-10h00',
            date: '22 Sept',
            lieu: 'INP-Centre',
            image: portail['ev1.png'],
        },
        {
            titre: 'Réuninon de Bienvenue avec les Premières Années',
            description: `Afin d'établir de prendre connaissance du règlement de l'Institut`,
            heure: '10h00-11h20',
            date: '22 Sept',
            lieu: 'Amphi Ezan-Akele',
            image: portail['ev1.jpg'],
        },
        {
            titre: "Formation de 500 Jeunes à l'INP-HB",
            description: `L'annonce a été faite le mardi 21 juin 2022, par le Ministre de la
            Promotion de la Jeunesse, de l'Insertion Professionnelle et du Service
            Civique.`,
            heure: '15h00-16h00',
            date: '23 Sept',
            lieu: 'INP-Sud',
            image: portail['ev2.png'],
        },
        {
            titre: "Création d'un Centre de Formation des Artisans Minier",
            description: `Dans le cadre de la mise en œuvre du projet SECORC.`,
            heure: '16h00-16h40',
            lieu: 'Amphi Adou',
            date: '23 Sept',
            image: portail['ev3.png'],
        },
    ],
}

export const NosConcours = {
    bachelier: [
        {
            titre: 'Présentation',
            corps: (
                <>
                   <strong>Deux (2) concours </strong>  sont ouverts aux <strong>bacheliers et aux BT</strong> de l’année en cours. Il vise à recruter 
                    les meilleurs bacheliers ayant postulé pour intégrer les Cycles Courts en trois années de 
                    formation intensive (Diplômes de Techniciens Supérieurs) ou les Classes Préparatoires aux 
                    Grandes Ecoles pour présenter les concours nationaux et internationaux d’ingénieurs. <br />
                   <strong> Peuvent faire acte de candidature les élèves titulaires du baccalauréat, BT de l’année 
                    en cours </strong> et remplissant les conditions d’âges suivantes à la date du 31 décembre de ladite année : <br />
                   <strong> - Vingt-deux (22) ans au plus pour le cycle long (Classes Préparatoires)</strong> <br /> 
                   <strong>- Vingt-quatre (24) ans au plus pour le cycle court (Techniciens supérieurs, Grade Licence)
                    Depuis quelques années, le concours est également organisé au Congo, au Burkina Faso, au Libéria, 
                    en Guinée Conakry et au Niger, sous la supervision du Service Concours de l’INP-HB. </strong> 
                </>
            )
            //  `
            // Deux concours sont ouverts aux bacheliers et aux BT de l’année en cours. Il vise à recruter les meilleurs bacheliers ayant 
            // postulé pour intégrer les Cycles Courts en trois années de formation intensive (Diplômes de Techniciens Supérieurs) ou les 
            // Classes Préparatoires aux Grandes Ecoles pour présenter les concours nationaux et internationaux d’ingénieurs. 
            // Peuvent faire acte de candidature les élèves titulaires du baccalauréat, BT de l’année en cours et remplissant les conditions
            //  d’âges suivantes à la date du 31 décembre de ladite année :
            //  - Vingt-deux (22) ans au plus pour le cycle long (Classes Préparatoires)

            // - Vingt-quatre (24) ans au plus pour le cycle court (Techniciens supérieurs, Grade Licence)
            // Depuis quelques années, le concours est également organisé au Congo, au Burkina Faso, au Libéria, en Guinée Conakry et au Niger, 
            // sous la supervision du Service Concours de l’INP-HB.
            // `,
        },
        {
            titre: 'Inscription au concours',
            corps: (
                <>
                  <strong> Le concours Bacheliers/BT </strong> d’entrée à l’INP-HB démarre par la pré-inscription en ligne. 
                    Cette phase du concours se déroule du lundi 17 juillet 2023 au samedi 05 août 2023. 
                    Le candidat a donc la possibilité de créer son espace candidat et renseigner les informations 
                    de moyennes de la seconde à la terminale 2023. Les admis au BAC 2023 pourront poursuivre 
                    leur pré-inscription en complétant le formulaire concernant certaines données (notes) du BAC.
                    A l’issue de cette étape, le candidat se rend sur le site Centre de l’INP-HB, à Yamoussoukro 
                    (dépôt de dossiers) muni des éléments suivants : <br /> <br /> 

                    <div className="vert">
                        Les photocopies des bulletins des classes de 2nde, 1ère
                        et Terminale 2023 ;
                    </div>

                    <div className="vert">
                        La photocopie du relevé des notes et attestation de réussite au BAC (ou BT) 2023 ;
                    </div>
                    <div className="vert">
                        La photocopie de la Carte Nationale d’Identité ou de l’Attestation d’Identité ou 
                        de toute pièce équivalente ;
                    </div>
                    <div className="vert">
                        Deux (02) exemplaires de l’imprimé de la préinscription en ligne ;
                    </div>
                    <div className="vert">
                         Une quittance de droit d’inscription d’un montant de 15 000 FCFA par concours 
                         payable  <strong>par TresorMoney </strong> ;
                    </div>
                    <div className="vert">
                        Des frais de dossier d’un montant de 3 000 FCFA payables  <strong>par TresorMoney </strong> 
                        (un seul dossier quel que soit le nombre de concours présentés).
                    </div>
                    <div className="vert">
                         Des frais de photo d’un montant de 1 000 FCFA payables p <strong>par TresorMoney </strong> 
                         (la photo est prise au dépôt des dossiers).
                    </div>
                    <br />
                    <div className="notonsbien">
                        <h3><strong>Nb : </strong>  tous les paiements se font  <strong>par TresorMoney.</strong>  </h3>
                    </div>
                </>
            ),
        },
        {
            titre: 'Déroulement du concours',
            corps: (
                <>
                    Le concours se fait en deux phases. La première concerne
                    l’admissibilité (étude de dossiers) et la seconde est
                    relative à l’admission (test écrit). Sont concernés par la
                    2ème étape, tous les candidats retenus à l’issue de l’étude
                    de dossier. <br />
                    <span
                        className="body-highlight"
                        style={{ color: '#008D36' }}
                    >
                        L’admissibilité
                    </span>
                    <p>
                        Cet exercice méthodologique et minutieux consiste à
                        faire une première sélection des candidats sur la base
                        de certaines moyennes spécifiques obtenues depuis la
                        classe de seconde (2nde) à la Terminale. L’étude de dossier se fait sur
                        la base des choix opérés par les candidats lors de la
                        préinscription en ligne. Trois grands profils sont
                        définis à ce niveau.{' '}
                    </p>{' '}
                    <br />
                    <p>
                        <strong>Le Profil commercial : </strong>
                        Pour ce profil, l’étude de dossier s’appuie sur les
                        moyennes obtenues en mathématiques, français et anglais
                        en classe (seconde à la Terminale) et au BAC.
                    </p>
                    <p>
                        <strong>Le Profil industriel : </strong>
                        Ici interviennent les mathématiques, la physique-chimie,
                        le français et l’anglais.
                    </p>
                    <p>
                        <strong>Le Profil biologique : </strong>
                        Toutes les matières du profil industriel auxquelles
                        s’ajoutent les Sciences de la Vie et de la Terre (SVT).
                    </p>{' '}
                    <br />
                    <br />
                    <span
                        className="body-highlight"
                        style={{ color: '#008D36' }}
                    >
                        Le test écrit
                    </span>
                    <p>
                        A l’issue de l’étude de dossiers, les candidats retenus
                        pour le second tour sont conviés à prendre part au test
                        écrit. L’épreuve se déroule au Lycée Classique
                        d’Abidjan. Durant 03H30, ils affrontent tous, les épreuves de 
                        culture générale (français, actualités etc …), anglais et culture 
                        scientifique (mathématiques, physique, chimie, SVT, logique etc …. <br />
                        Les moyennes de l’étude de
                        dossier et du texte écrit sont par la suite combinées
                        pour donner la moyenne du concours. La liste des
                        candidats déclarés définitivement admis est alors
                        affichée dans les locaux de l’INP-HB et sur le site internet de
                        l’INP-HB (le candidat y accède en introduisant le numéro
                        matricule qui lui est attribué lors du concours).
                    </p>
                </>
            ),
        },
    ],
    ingenieur: [
        {
            titre: 'CONCOURS CAE',
            recap: Concours['CAE.png'],
            corps: [
                {
                    sousTitre: 'Option Commerce',
                    contenu: (
                        <>
                            Il est ouvert aux candidats : <br />
                            <div className="vert">
                                Âgés de 25 ans au plus à la date 
                                du 31 décembre 2024 et respectant l'une des conditions de 
                                candidature si-dessous ;
                            </div>
                            <div className="vert">
                                Régulièrement inscrits, au titre de l’année
                                académique 2023-2024, en 2ème année de Classes
                                préparatoires aux Grandes Ecoles, filières
                                économiques et commerciales option Scientifique
                                ; Physique Chimie (PC); Physique et Sciences Industrielles (PSI); {' '}
                            </div>
                            <div className="vert">
                                Titulaires d’un diplôme de Technicien Supérieur
                                (grade licence) d’une des spécialités du
                                concours CAE ; Titulaires d’une Licence en
                                Sciences Economiques ;{' '}
                            </div>
                            <div className="vert">
                                Titulaires d’un diplôme admis en équivalence.
                            </div>

                            <br />
                            <div className='vert'>
                                EPREUVES :
                                <br />
                                <ul>
                                    <li>Mathématiques 1 (algèbre, analyse, stat & proba, informatique)</li>
                                    <li>Culture générale (français, philosophie)</li>
                                    <li>Economie générale</li>
                                    <li>Histoire, Géographie et Géopolitique du monde contemporain</li>
                                    <li>Comptabilité générale</li>
                                    <li>Comptabilité de gestion</li>

                                </ul>
                            </div>
                            
                        </>
                    ),
                    
                },
                {
                    sousTitre: 'Option Mathématiques',
                    contenu: (
                        <>
                            Il est ouvert aux candidats <br />
                            <div className="vert">
                                Âgés de 25 ans au plus à la date 
                                du 31 décembre 2024 et respectant l'une des conditions de 
                                candidature si-dessous ;
                            </div>
                            <div className="vert">
                                Régulièrement inscrits, au titre de l’année
                                académique 2023-2024, en 2ème année de classes
                                préparatoires aux Grandes Ecoles, filière
                                Mathématiques et Physique, ou filière Biologie
                                Chimie Physique et Sciences de la Terre (BCPST)
                                ; Physique Chimie (PC); Physique et Sciences Industrielles (PSI); {''}
                            </div>
                            <div className="vert">
                                Titulaires d’une Licence en Mathématiques et
                                Physique (MP), ou en Physique et Chimie (PC), ou
                                en Mathématiques, Physique et Technologie (MPT),
                                ou en Mathématiques, Physique, Chimie et
                                Technologie (MPCT), ou en Sciences Fondamentales
                                et Appliquées (SFA) ;
                            </div>
                            <div className="vert">
                                Titulaires d’un diplôme admis en équivalence.
                            </div>
                            <br />
                            <div className='vert'>
                                EPREUVES :
                                <br />
                                <ul>
                                    <li>Mathématiques 1 (algèbre, analyse, stat & proba, informatique)</li>
                                    <li>Culture générale (français, philosophie)</li>
                                    <li>Anglais 1</li>
                                </ul>
                            </div>
                        </>
                    ),
                },
            ],
        },
        {
            titre: 'CONCOURS STIC',
            recap: Concours['STIC.png'],
            description:
                'Sciences et Technologies de l’Information et de la Communication',
            corps: [
                {
                    contenu: (
                        <>
                            Le concours STIC s’adresse aux candidats : <br />
                            <div className="vert">
                                Âgés de 25 ans au plus à la date 
                                du 31 décembre 2024 et respectant l'une des conditions de 
                                candidature si-dessous ;
                            </div>
                            <div className="vert">
                                Régulièrement inscrit, au titre de l’année
                                académique 2023-2024, en 2ème année de classes
                                Préparatoires aux Grandes Ecoles filière
                                Mathématiques et Physique ; Physique Chimie (PC); Physique et Sciences Industrielles (PSI); {''}
                            </div>
                            <div className="vert">
                                Titulaires d’un diplôme de Technicien Supérieur
                                (grade licence) d’une des spécialités du
                                concours STIC ;
                            </div>{' '}
                            <div className="vert">
                                Titulaires d’une Licence en Mathématiques et
                                Physique (MP), ou en Physique et Chimie (PC), ou
                                en Mathématiques, Physique et Technologie (MPT),
                                ou en Mathématiques, Physique, Chimie et
                                Technologie (MPCT), ou en Sciences Fondamentales
                                et Appliquées (SFA) ;
                            </div>{' '}
                            <div className="vert">
                                Titulaires d’un diplôme admis en équivalence.
                            </div>{' '}
                        </>
                    ),
                },
            ],
        },
        {
            titre: 'CANDIDATURE GIC',
            recap: Concours['GIC.png'],
            description: 'Génie Industriel et Civil',
            corps: [
                {
                    contenu: (
                        <>
                            Il est ouvert aux candidats : <br />
                            <div className="vert">
                                Âgés de 25 ans au plus à la date 
                                du 31 décembre 2024 et respectant l'une des conditions de 
                                candidature si-dessous ;
                            </div>
                            <div className="vert">
                                Régulièrement inscrit, au titre de l’année
                                académique 2023-2024, en 2ème année de classes
                                Préparatoires aux Grandes Ecoles filière
                                Mathématiques et Physique ; Physique Chimie (PC); Physique et Sciences Industrielles (PSI); {''}
                            </div>
                            <div className="vert">
                                Titulaires d’un diplôme de Technicien Supérieur
                                (grade licence) d’une des spécialités du
                                concours GIC ;
                            </div>{' '}
                            <div className="vert">
                                Titulaires d’une licence en Mathématiques et
                                Physique (MP), ou en Physique et Chimie (PC), ou
                                en Mathématiques, Physique et Technologie (MPT),
                                ou en Mathématiques, Physique, Chimie et
                                Technologie (MPCT), ou en Sciences Fondamentales
                                et Appliquées (SFA) ;
                            </div>{' '}
                            <div className="vert">
                                Titulaires d’un diplôme admis en équivalence.
                            </div>{' '}
                        </>
                    ),
                },
            ],
        },
        {
            titre: 'CONCOURS A2GP',
            recap: Concours['A2GP.png'],
            description: 'Agronomie, Géo-Ressources et Génie des Procédés',
            corps: [
                {
                    sousTitre: 'Option Biologie',
                    contenu: (
                        <>
                            <div className="vert">
                                Le candidat doit être âgé de 25 ans au plus à la date 
                                du 31 décembre 2024 et respecter l'une des conditions de 
                                candidature si-dessous ;
                            </div>
                            <div className="vert">
                                Être régulièrement inscrit, au titre de l’année
                                académique 2023-2024, en 2ème année des classes
                                préparatoires aux Grandes Ecoles filière
                                Biologie Chimie Physique et Sciences de la Terre
                                (BCPST) ; Physique Chimie (PC); Physique et Sciences Industrielles (PSI); {''}
                            </div>
                            <div className="vert">
                                Titulaires d’un diplôme de Technicien Supérieur
                                (grade licence) d’une des spécialités du
                                concours A2GP ;
                            </div>
                            <div className="vert">
                                Titulaires d’une Licence en Chimie Biologie et
                                Géologie (CBG) ou en Sciences de la Nature (SN)
                                ;
                            </div>
                            <div className="vert">
                                Titulaires d’un diplôme admis en équivalence.
                            </div>

                            <br />
                            <div className='vert'>
                                EPREUVES :
                                <br />
                                <ul>
                                    <li>Mathématiques 3 (algèbre, analyse, stat & proba)</li>
                                    <li>Informatique 2 </li>
                                    <li>Français 2</li>
                                    <li>Anglais 2 </li>
                                    <li>Physique 3 (Mécanique, thermodynamique électricité, optique)</li>
                                    <li>Chimie 2 (Générale et organique)</li>
                                    <li>Biologie 1 (Végétale, animale)</li>
                                </ul>
                            </div>
                        </>
                    ),
                },
                {
                    sousTitre: 'Option Mathématiques',
                    contenu: (
                        <>
                            Pour présenter ce concours, le candidat doit :{' '}
                            <br />
                            <div className="vert">
                                Être âgé de 25 ans au plus à la date 
                                du 31 décembre 2024 et respecter l'une des conditions de 
                                candidature si-dessous ;
                            </div>
                            <div className="vert">
                                Être régulièrement inscrit, au titre de l’année
                                académique 2023-2024, en 2ème année de classes
                                Préparatoires aux Grandes Ecoles filière
                                Mathématiques et Physique ; Physique Chimie (PC); Physique et Sciences Industrielles (PSI); {''}
                            </div>
                            <div className="vert">
                                Être titulaire d’une Licence en Mathématiques et
                                Physique (MP), ou en Physique et Chimie (PC), ou
                                en Mathématiques, Physique et Technologie (MPT),
                                ou en Mathématiques, Physique, Chimie et
                                Technologie (MPCT), ou en Sciences Fondamentales
                                et Appliquées (SFA) ;
                            </div>
                            <div className="vert">
                                Être titulaire d’un diplôme admis en
                                équivalence.
                            </div>
                            <br/>
                            <div className='vert'>
                                EPREUVES :
                                <br />
                                <ul>
                                    <li>Mathématiques 3 (algèbre, analyse, stat & proba)</li>
                                    <li>Informatique 2 </li>
                                    <li>Français 2</li>
                                    <li>Anglais 2 </li>
                                    <li>Physique 3 (Mécanique, thermodynamique électricité, optique)</li>
                                    <li>Chimie 3 (Générale)</li>
                                </ul>
                            </div>
                        </>
                    ),
                },
                {
                    sousTitre: 'Option Chimie',
                    contenu: (
                        <>
                            Pour présenter ce concours, le candidat doit :{' '}
                            <br />
                            <div className="vert">
                                Être âgé de 25 ans au plus à la date 
                                du 31 décembre 2024 et respecter l'une des conditions de 
                                candidature si-dessous ;
                            </div>
                            <div className="vert">
                                Être régulièrement inscrit, au titre de l’année
                                académique 2023-2024, en 2ème année de classes
                                Préparatoires aux Grandes Ecoles filière
                                Physique Chimie (PC); Physique et Sciences Industrielles (PSI); {''}
                            </div>
                            <div className="vert">
                                Être titulaire d’une Licence en Physique et Chimie (PC), 
                                ou en Physique et Technologie (MPT),
                                ou en Mathématiques, Physique, Chimie et
                                Technologie (MPCT) ;
                            </div>
                            <div className="vert">
                                Être titulaire d’un diplôme admis en
                                équivalence.
                            </div>
                            <br />
                            <div className='vert'>
                                EPREUVES :
                                <br />
                                <ul>
                                    <li>Mathématiques 3 (algèbre, analyse, stat & proba)</li>
                                    <li>Informatique 2 </li>
                                    <li>Français 2</li>
                                    <li>Anglais 2 </li>
                                    <li>Physique 3 (Mécanique, thermodynamique électricité, optique)</li>
                                    <li>Chimie 2 (Générale et organique)</li>
                                </ul>
                            </div>
                        </>
                    ),
                },
            ],
        },
        // {
        //     titre: 'CONCOURS ITA',
        //     description: 'Ingénieurs des Techniques Agricoles',
        //     corps: [
        //         {
        //             contenu: (
        //                 <>
        //                     <div className="vert">
        //                         Être titulaire d’un baccalauréat scientifique C
        //                         ou D d’un diplôme équivalent ;
        //                     </div>
        //                     <div className="vert">
        //                         Être inscrit dans une université en Chimie
        //                         Biologie et Géologie ou Sciences de la Nature ;
        //                     </div>{' '}
        //                     <div className="vert">
        //                         Avoir régulièrement suivi les cours de la 1ère
        //                         ou de la 2ème année des classes préparatoires
        //                         aux Grandes Ecoles filière Biologie Chimie
        //                         Physique et Sciences de la Terre (BCPST).
        //                     </div>{' '}
        //                 </>
        //             ),
        //         },
        //     ],
        // },
    ],
}
// const cheminA2gpfr = ''
export const EpreuvesConcours = {
    a2gp: [
        {
            titre: 'Mathématiques 3 ',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                    <button onClick={() => window.open(A2GP1)}>Contenu 1</button>,
                    <button onClick={() => window.open(A2GP2)}>Contenu 2</button>
                
                ],
                },
            ],
        },
        {
            titre: 'Informatique 2',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(A2GP3)}>Contenu</button>
                        
                    ],
                },

            ],
        },
        
        {
            titre: 'Français 2',
            sousTitre: [
                {
                    enTete: "",
                    contenu: [
                        <button onClick={() => window.open(A2GP4)}>Contenu</button>

                    ],
                },

            ],
        },

        {
            titre:'Anglais 2',
            sousTitre: [
                {
                    enTete:'',
                    contenu: [
                        <button onClick={() => window.open(ANG)}>Contenu </button>,

                    ],
                },
        ]
        },
        // {
        //     titre: 'Anglais 2',
        //     sousTitre: [
        //         {
        //             enTete: 'VERBS AND TENSES',
        //             contenu: [
        //                 'Present tenses',
        //                 'Present perfect',
        //                 'Past tenses',
        //                 'Future forms.',
        //             ],
        //         },
        //         {
        //             enTete: 'MODAL VERBS',
        //             contenu: [
        //                 'Ability (Can could, be able to)',
        //                 'Permission (can, may, could, be allowed to)',
        //                 'Possibility and certainty (may, might, could, must, etc.)',
        //                 'Necessity (+must, have to, - needn’t, don’t have to)',
        //                 'Interdiction (mustn’t)',
        //                 'Advice (should, ought to, had better, be supposed to)',
        //                 'Suggestion invitations and offers.',
        //             ],
        //         },
        //         {
        //             enTete: 'NOUNS',
        //             contenu: [
        //                 'Countable and uncountable',
        //                 'Determiners: quantifiers, articles.',
        //             ],
        //         },
        //         {
        //             enTete: 'ADJECTIVES',
        //             contenu: [
        //                 'Comparative and superlative forms',
        //                 'Order of adjectives.',
        //             ],
        //         },
        //         {
        //             enTete: 'VERB FORMS AND STRUCTURE',
        //             contenu: [
        //                 'Questions, negatives and answers',
        //                 'Infinitive and ing forms',
        //                 'The passive (present and past, future and modal passives)',
        //                 'Direct and indirect forms',
        //                 'Prepositions: Place, IN-ON-AT (place), IN-ON-AT (time), Preposition + noun, Noun+ preposition, Adjective + preposition.',
        //             ],
        //         },
        //         {
        //             enTete: 'TOPICS OF STUDY',
        //             contenu: ["Family life","Hobbies and pastimes","Holidays","Work and job","Shopping","Education."],
        //         },
        //         {
        //             enTete: 'UNITS',
        //             contenu: ["Friends","Media","Lifestyle","Wealth","Spare time","Holidays","Education","Change","Jobs.","Memories."],
        //         },
        //     ],
        // },
        {
            titre: 'Chimie 2 & 3',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(A2GP5)}>Contenu 1</button>,
                        <button onClick={() => window.open(A2GP6)}>Contenu 2</button>
                    ],
                },
            ],
        },

        {
            titre: 'Biologie 1',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(A2GP7)}>Contenu 1</button>,
                        <button onClick={() => window.open(A2GP8)}>Contenu 2</button>

                        
                    ],
                },

            ],
        },    
        
        {
            titre: 'Geologie 1',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(A2GP7)}>Contenu 1</button>,
                        <button onClick={() => window.open(A2GP8)}>Contenu 2</button>

                        
                    ],
                },

            ],
        },
    ],
    // ita: [
    //     {
    //         titre: 'Mathématiques 4 (algèbre, analyse)',
    //         sousTitre: [
    //             {
    //                 enTete: '',
    //                 contenu: ["Nombres complexes et polynômes","Polynômes à coefficients réels ou complexes","Espaces vectoriels","Applications linéaires","Matrices","Systèmes d'équations linéaires","Valeurs propres, vecteurs propres","Géométrie","Limites et continuité","Dérivées, développement limités","Fonctions usuelles","Procédé d'intégration","Intégrale généralisée","Equations différentielles à variable réelle","Fonctions réelles de plusieurs variables réelles","Probabilités","Notion d'algèbre élémentaire","Concepts de base des probabilités","Variables aléatoires réelles."
    //             ],
    //             },
    //         ],
    //     },
    //     {
    //         titre: 'Français 3',
    //         sousTitre: [
    //             {
    //                 enTete: '',
    //                 contenu: ["Principes de la communication","Exigences et méthodes de la composition","Techniques du résumé et de la note de synthèse","Qualité de la langue."
    //             ],
    //             },
    //         ],
    //     },
    //     {
    //         titre: 'Anglais 3',
    //         sousTitre: [
    //             {
    //                 enTete: '',
    //                 contenu: ["Compréhension de textes écrits"," Analyse de textes écrits (résumé, synthèse)."
    //             ],
    //             },
    //         ],
    //     },
    //     {
    //         titre: 'Physique-Chimie',
    //         sousTitre: [
    //             {
    //                 enTete: 'ELECTROCINÉTIQUE',
    //                 contenu: ["Régimes indépendants du temps"," Régime transitoire."
    //             ],
    //             },
    //             {
    //                 enTete: 'MÉCANIQUE NEWTONIENNE DU POINT MATERIEL',
    //                 contenu: ["Espace et temps d'un observateur, repères","Lois de Newton et théorèmes dérivés","Equilibre et approche de l'équilibre."
    //             ],
    //             },
    //             {
    //                 enTete: 'THERMODYNAMIQUE',
    //                 contenu: ["Les états de la matière","Définitions fondamentales","Les deux premiers principes de la thermodynamique","Le modèle du gaz parfait","Applications des deux principes."
    //             ],
    //             },
    //             {
    //                 enTete: 'OPTIQUE GÉOMETRIQUE',
    //                 contenu: ["Sources lumineuses ponctuelles, rayons lumineux, limites du modèle","Lois de la réflexion","Lois de la réfraction","Lentilles sphériques minces dans l'approximation de Gauss."
    //             ],
    //             },
    //             {
    //                 enTete: 'ATOMES ET EDIFICES CHIMIQUES',
    //                 contenu: ["Structure électronique de l'atome","Classification périodique des éléments","Molécules diatomiques et polyatomiques","Liaisons covalentes délocalisées","Existence de forces intermoléculaires."
    //             ],
    //             },
    //             {
    //                 enTete: 'CINÉTIQUE CHIMIQUE',
    //                 contenu: ["Vitesse de réaction globale en système fermé","Notion de mécanisme. Catalyse."
    //             ],
    //             },
    //             {
    //                 enTete: 'SOLUTIONS AQUEUSES',
    //                 contenu: ["Couples acido-basiques, pH des solutions aqueuses","Complexation, précipitation de composés ioniques."
    //             ],
    //             },
    //             {
    //                 enTete: 'CHIMIE ORGANIQUE',
    //                 contenu: ["Formules brutes, formules développées","Structure stérique des molécules","Alcènes","Dérivés mono-halogènes des alcanes","Alcools","Amines"," Aldéhydes et cétones."
    //             ],
    //             },
    //         ],
    //     },
    //     {
    //         titre: 'Géologie 2',
    //         sousTitre: [
    //             {
    //                 enTete: 'GÉNÉRALITÉS',
    //                 contenu: ["Définition et présentation de la géologie","Echelle et temps géologiques","Branches et disciplines géologie fondamentale"," Intérêts de la géologie."
    //             ],
    //             },
    //             {
    //                 enTete: 'LA TERRE',
    //                 contenu: ["Composition de l’univers : la galaxie","Les systèmes stellaires","Le système solaire","Forme de la terre","Structure de la terre","Composition chimique de la terre","Le champ thermique de la terre."
    //             ],
    //             },
    //             {
    //                 enTete: 'GEODYNAMIQUE INTERNE',
    //                 contenu: ["Chronologie relative, chronologie absolue","La dérive des continents","Tectonique des plaques."
    //             ],
    //             },
    //             {
    //                 enTete: 'GEODYNAMIQUE EXTERNE',
    //                 contenu: ["Mode de formation des sédiments","Les principaux milieux de sédimentation","La sédimentation en milieu marin","Sédimentation en milieux mixtes","La diagenèse","Classification des roches sédimentaires."
    //             ],
    //             },
                
    //         ],
    //     },
    //     {
    //         titre: 'Biologie 2',
    //         sousTitre: [
    //             {
    //                 enTete: 'ZOOLOGIE',
    //                 contenu: ["Généralités sur le règne animal","Histoire des animaux et théorie de l’évolution"," Classification des animaux."
    //             ],
    //             },
    //             {
    //                 enTete: 'BIOLOGIE DE LA REPRODUCTION',
    //                 contenu: ["Généralités sur la reproduction","Appareils reproducteurs","Gamétogénèse","Fécondation."
    //             ],
    //             },
    //             {
    //                 enTete: 'BIOLOGIE DU DEVELOPPEMENT',
    //                 contenu: ["Généralités sur le développement embryonnaire","Etapes du développement embryonnaire chez amphibiens","Etapes du développement embryonnaire chez les oiseaux","Etapes du développement embryonnaire chez les mammifères","Croissance et développement."
    //             ],
    //             },
    //             {
    //                 enTete: 'BIOLOGIE ET PHYSIOLOGIE VEGETALES',
    //                 contenu: ["La plante : sa structure et sa croissance","Croissance et morphogénèse végétales","Développement des spermaphytes","Les semences et leur germination","Multiplications végétales."
    //             ],
    //             },
                
    //         ],
    //     },

    // ],
    stic: [
        {
            titre: 'Français 2 ',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(A2GP4)}>Contenu 1</button>

                    ],
                },

            ],
        },
        {
            titre: 'Mathématiques 2   ',
            sousTitre: [
                {
                    enTete: '(Algèbre, Analyse)',
                    contenu: [
                        <button onClick={() => window.open(GIC1)}>Contenu 1</button>,
                        <button onClick={() => window.open(GIC2)}>Contenu 2</button>


                    ],
                },

            ],
        },
        {
            titre: 'Informatique 1',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(GIC3)}>Contenu 1</button>,
                        

                    ],
                },

            ],
        },
        {
            titre: 'Anglais 2 ',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(ANG)}>Contenu </button>,
                        

                    ],
                },

            ],
        },
        {
            titre: 'Physique 2 ',
            sousTitre: [
                {
                    enTete: 'Electricité, mécanique, optique',
                    contenu: [
                        <button onClick={() => window.open(GIC4)}>Contenu 1</button>,
                        <button onClick={() => window.open(GIC4b)}>Contenu 2</button>


                    ],
                },

            ],
        },
        {
            titre: 'Sciences Industrielles ',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(GIC5)}>Contenu 1</button>,
                        <button onClick={() => window.open(GIC6)}>Contenu 2</button>


                    ],
                },

            ],
        },

    ],
    gic: [
        {
            titre: 'Français 2 ',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(A2GP4)}>Contenu 1</button>

                    ],
                },

            ],
        },
        {
            titre: 'Mathématiques 2 ',
            sousTitre: [
                {
                    enTete: '(Algèbre, Analyse)',
                    contenu: [
                        <button onClick={() => window.open(GIC1)}>Contenu 1</button>,
                        <button onClick={() => window.open(GIC2)}>Contenu 2</button>


                    ],
                },

            ],
        },
        {
            titre: 'Informatique 1',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(GIC3)}>Contenu 1</button>,
                        

                    ],
                },

            ],
        },
        {
            titre: 'Anglais 2 ',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(ANG)}>Contenu </button>,
                        
                        
                    ],
                },

            ],
        },
        {
            titre: 'Physique 1 ',
            sousTitre: [
                {
                    enTete: 'Mécanique, Thermodynamique, Electricité, , Optique',
                    contenu: [
                        <button onClick={() => window.open(GIC4)}>Contenu 1</button>,
                        <button onClick={() => window.open(GIC4b)}>Contenu 2</button>


                    ],
                },

            ],
        },
        {
            titre: 'Chimie 1 ',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(GIC4)}>Contenu 1</button>,
                        <button onClick={() => window.open(GIC4b)}>Contenu 2</button>


                    ],
                },

            ],
        },
        {
            titre: 'Sciences Industrielles ',
            sousTitre: [
                {
                    enTete: '',
                    contenu: [
                        <button onClick={() => window.open(GIC5)}>Contenu 1</button>,
                        <button onClick={() => window.open(GIC6)}>Contenu 2</button>


                    ],
                },

            ],
        },

    ],
    cae: [
        {
            titre:'Mathématiques 1',
            sousTitre: [
                {
                    enTete:'algèbre, analyse, statistique, probabilité, informatique',
                    contenu: [      
                        <button onClick={() => window.open(CAE1)}>Contenu 1</button>,
                        <button onClick={() => window.open(CAE2)}>Contenu 2</button>,
                        <button onClick={() => window.open(CAE3)}>Contenu 3</button>,
                        <button onClick={() => window.open(CAE4)}>Contenu 4</button>,
                        <button onClick={() => window.open(CAE10)}>Contenu 5</button>,
                        <button onClick={() => window.open(CAE11)}>Contenu 6</button>,


                ],
                },
            ]
        },
        {
            titre:'Culture Générale',
            sousTitre: [
                {
                    enTete:'Français, philosophie',
                    contenu: [
                        <button onClick={() => window.open(CAE5)}>Contenu 1</button>,
                        <button onClick={() => window.open(CAE6)}>Contenu 2</button>,

                    ],
                },
            ]
        },
        {
            titre:'Anglais 1',
            sousTitre: [
                {
                    enTete:'',
                    contenu: [
                        <button onClick={() => window.open(ANG)}>Contenu </button>,

                    ],
                },
            ]
        },
        {
            titre:'économie générale',
            sousTitre: [
                {
                    enTete:'',
                    contenu: [
                        <button onClick={() => window.open(CAE7)}>Contenu 1</button>,

                    ],
                },
            ]
        },
        {
            titre:'histoire , Géographie et Géopolitique du monde contemporain',
            sousTitre: [
                {
                    enTete:'',
                    contenu: [
                        <button onClick={() => window.open(CAE8)}>Contenu 1</button>,
                        <button onClick={() => window.open(CAE9)}>Contenu 2</button>,


                    ],
                },
            ]
        },
        {
            titre: 'Comptabilité  générale, Comptabilité analytique de gestion)',
            sousTitre: [
                {
                    enTete: "ROLE DE LA COMPTABILITE. PRINCIPES DE BASE DU MODELE COMPTABLE",
                    contenu: [" Analyse des documents", "Les principes comptables", "Analyse des opérations flux et stocks", "Création des comptes", "Mécanisme de la partie double", "Contrôles comptables : la balance.", "Les trois problèmes fondamentaux."]
                },
                {
                    enTete: "LA REGLEMENTATION COMPTABLE",
                    contenu: ["Etude sommaire des différentes sources du droit comptable et de leur hiérarchie", "Le P.C.I."]
                },
                {
                    enTete: "ANALYSE DES PRINCIPAUX CYLES D’OPERATIONS",
                    contenu: ["Relations avec les clients et fournisseurs", "Relations avec les prestations diverses", "Relations avec le personnel et les organismes sociaux", "Relations avec l’Etat", "Opération de trésorerie", "Relations avec l’étranger."]
                },
                {
                    enTete: "ANALYSE DES TRAVAUX MENANT A L’ETABLISSEMENT DES COMPTES ANNUELS",
                    contenu: ["Nécessité de l’inventaire", "Amortissements et provisions", "Regroupement des comptes et balance de fin d’exercice", "Prise en compte de l’impôt sur les FIC", "Etablissement des états financiers."]
                },
                {
                    enTete: "ANALYSE DES OPERATIONS DE PASSAGE D’UN EXERCICE A L’AUTRE",
                    contenu: ["Clôture et réouverture des comptes", "Affectation du résultat."]
                },
                {
                    enTete: "ORGANISATION PRATIQUE DE LA COMPTABILITE",
                    contenu: ["Systèmes, procédés et procédures d’enregistrements comptables", "Les contrôles comptables."]
                },
                {
                    enTete: "ANALYSE FINANCIERE",
                    contenu: ["L’analyse des comptes annuels", "Etudes des ratios", "Fonds de roulement, besoin en fonds de roulement, tableau de financement."]
                },
                {
                    enTete: "COMPTABILITE ANALYTIQUE D’EXPLOITATION",
                    contenu: ["De la comptabilité générale à la comptabilité analytique, méthodes et démarches", "Coûts complets", "Coûts partiels", "Coûts préétablis."]
                },
            ] 
        },
        // {
        //     titre:'comptabilité générale',
        //     sousTitre: [
        //         {
        //             enTete:'',
        //             contenu: [""],
        //         },
        //     ]
        // },
        // {
        //     titre:'comptabilité de gestion',
        //     sousTitre: [
        //         {
        //             enTete:'',
        //             contenu: [""],
        //         },
        //     ]
        // },

    ],
}

export const sujets = {
    // 2013: [
    //     {
    //         nom: "Anglais 1",
    //         fichier: S2013["Anglais1.pdf"],
    //     },
    //     {
    //         nom: "Anglais 2",
    //         fichier: S2013["Anglais2.pdf"],
    //     },
    //     {
    //         nom: "Anglais 3",
    //         fichier: S2013["Anglais3.pdf"],
    //     },
    //     {
    //         nom: "Biologie",
    //         fichier: S2013["Biologie.pdf"],
    //     },
    //     {
    //         nom: "Biologie_animale",
    //         fichier: S2013["Biologie_animale.pdf"],
    //     },
    //     {
    //         nom: "Biologie_vegetale",
    //         fichier: S2013["Biologie_vegetale.pdf"],
    //     },
    //     {
    //         nom: "Chimie générale",
    //         fichier: S2013["Chimie_generale.pdf"],
    //     },
    //     {
    //         nom: "Chimie_organique",
    //         fichier: S2013["Chimie_organique.pdf"],
    //     },
    //     {
    //         nom: "Chimie 1",
    //         fichier: S2013["Chimie1.pdf"],
    //     },
    //     {
    //         nom: "Chimie 2",
    //         fichier: S2013["Chimie2.pdf"],
    //     },
    //     {
    //         nom: "Comptabilite",
    //         fichier: S2013["Comptabilite.pdf"],
    //     },
    //     {
    //         nom: "Droit",
    //         fichier: S2013["Droit.pdf"],
    //     },
    //     {
    //         nom: "Économie générale",
    //         fichier: S2013["Economie_generale.pdf"],
    //     },
    //     {
    //         nom: "Français 1",
    //         fichier: S2013["Francais1.pdf"],
    //     },
    //     {
    //         nom: "Français 2",
    //         fichier: S2013["Francais2.pdf"],
    //     },
    //     {
    //         nom: "Français 3",
    //         fichier: S2013["Francais3.pdf"],
    //     },
    //     {
    //         nom: "Geologie 1",
    //         fichier: S2013["Geologie1.pdf"],
    //     },
    //     {
    //         nom: "Geologie 2",
    //         fichier: S2013["Geologie2.pdf"],
    //     },
    //     {
    //         nom: "Gestion",
    //         fichier: S2013["Gestion.pdf"],
    //     },
    //     {
    //         nom: "Informatique 1",
    //         fichier: S2013["Informatique1.pdf"],
    //     },
    //     {
    //         nom: "Informatique 2",
    //         fichier: S2013["Informatique2.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 1",
    //         fichier: S2013["Mathematiques1.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 2",
    //         fichier: S2013["Mathematiques2.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 3",
    //         fichier: S2013["Mathematiques3.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 4",
    //         fichier: S2013["Mathematiques4.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 5",
    //         fichier: S2013["Mathematiques5.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 6",
    //         fichier: S2013["Mathematiques6.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 7",
    //         fichier: S2013["Mathematiques7.pdf"],
    //     },
    //     {
    //         nom: "Organisation",
    //         fichier: S2013["Organisation.pdf"],
    //     },
    //     {
    //         nom: "Physique 1",
    //         fichier: S2013["Physique1.pdf"],
    //     },
    //     {
    //         nom: "Physique 2",
    //         fichier: S2013["Physique2.pdf"],
    //     },
    //     {
    //         nom: "Physique 3",
    //         fichier: S2013["Physique3.pdf"],
    //     },
    //     {
    //         nom: "Physique 4",
    //         fichier: S2013["Physique4.pdf"],
    //     },
    //     {
    //         nom: "Physique 5",
    //         fichier: S2013["Physique5.pdf"],
    //     },
    //     {
    //         nom: "Physique 6",
    //         fichier: S2013["Physique6.pdf"],
    //     },
    //     {
    //         nom: "Physique 7",
    //         fichier: S2013["Physique7.pdf"],
    //     },
    //     {
    //         nom: "Sciences industrielles",
    //         fichier: S2013["Sciences_industrielles.pdf"],
    //     },
    // ],
    // 2014: [
    //     {
    //         nom: "Anglais 1",
    //         fichier: S2014["Anglais1.pdf"],
    //     },
    //     {
    //         nom: "Anglais 2",
    //         fichier: S2014["Anglais2.pdf"],
    //     },
    //     {
    //         nom: "Anglais 3",
    //         fichier: S2014["Anglais3.pdf"],
    //     },
    //     {
    //         nom: "Biologie",
    //         fichier: S2014["Biologie.pdf"],
    //     },
    //     {
    //         nom: "Biologie_animale",
    //         fichier: S2014["Biologie_animale.pdf"],
    //     },
    //     {
    //         nom: "Biologie_vegetale",
    //         fichier: S2014["Biologie_vegetale.pdf"],
    //     },
    //     {
    //         nom: "Chimie générale",
    //         fichier: S2014["Chimie_generale.pdf"],
    //     },
    //     {
    //         nom: "Chimie_organique",
    //         fichier: S2014["Chimie_organique.pdf"],
    //     },
    //     {
    //         nom: "Chimie 1",
    //         fichier: S2014["Chimie1.pdf"],
    //     },
    //     {
    //         nom: "Chimie 2",
    //         fichier: S2014["Chimie2.pdf"],
    //     },
    //     {
    //         nom: "Comptabilite",
    //         fichier: S2014["Comptabilite.pdf"],
    //     },
    //     {
    //         nom: "Droit",
    //         fichier: S2014["Droit.pdf"],
    //     },
    //     {
    //         nom: "Économie générale",
    //         fichier: S2014["Economie_generale.pdf"],
    //     },
    //     {
    //         nom: "Français 1",
    //         fichier: S2014["Francais1.pdf"],
    //     },
    //     {
    //         nom: "Français 2",
    //         fichier: S2014["Francais2.pdf"],
    //     },
    //     {
    //         nom: "Français 3",
    //         fichier: S2014["Francais3.pdf"],
    //     },
    //     {
    //         nom: "Geologie 1",
    //         fichier: S2014["Geologie1.pdf"],
    //     },
    //     {
    //         nom: "Geologie 2",
    //         fichier: S2014["Geologie2.pdf"],
    //     },
    //     {
    //         nom: "Gestion",
    //         fichier: S2014["Gestion.pdf"],
    //     },
    //     {
    //         nom: "Informatique 1",
    //         fichier: S2014["Informatique1.pdf"],
    //     },
    //     {
    //         nom: "Informatique 2",
    //         fichier: S2014["Informatique2.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 1",
    //         fichier: S2014["Mathematiques1.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 2",
    //         fichier: S2014["Mathematiques2.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 3",
    //         fichier: S2014["Mathematiques3.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 4",
    //         fichier: S2014["Mathematiques4.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 5",
    //         fichier: S2014["Mathematiques5.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 6",
    //         fichier: S2014["Mathematiques6.pdf"],
    //     },
    //     {
    //         nom: "Mathématiques 7",
    //         fichier: S2014["Mathematiques7.pdf"],
    //     },
    //     {
    //         nom: "Organisation",
    //         fichier: S2014["Organisation.pdf"],
    //     },
    //     {
    //         nom: "Physique 1",
    //         fichier: S2014["Physique1.pdf"],
    //     },
    //     {
    //         nom: "Physique 2",
    //         fichier: S2014["Physique2.pdf"],
    //     },
    //     {
    //         nom: "Physique 3",
    //         fichier: S2014["Physique3.pdf"],
    //     },
    //     {
    //         nom: "Physique 4",
    //         fichier: S2014["Physique4.pdf"],
    //     },
    //     {
    //         nom: "Physique 5",
    //         fichier: S2014["Physique5.pdf"],
    //     },
    //     {
    //         nom: "Physique 6",
    //         fichier: S2014["Physique6.pdf"],
    //     },
    //     {
    //         nom: "Physique 7",
    //         fichier: S2014["Physique7.pdf"],
    //     },
    //     {
    //         nom: "Sciences industrielles",
    //         fichier: S2014["Sciences_industrielles.pdf"],
    //     },
    // ],
    // 2015: [
    //     {
    //         nom: "SUJETS 2015",
    //         fichier: S2015["sujets2015.pdf"],
    //     },
    //     {
    //        nom: "CORRIGES 2015",
    //        fichier: S2015["corrigé2015.pdf"],
    //      },

    // ],
    // 2016: [
    //     {
    //         nom: "SUJETS 2016",
    //         fichier: S2016["sujet2016.pdf"],
    //     },
    //     {
    //         nom: "CORRIGES 2016",
    //         fichier: S2016["corrigé2016.pdf"],
    //     },

    // ],
    // 2017: [
    //     {
    //         nom: "SUJETS 2017",
    //         fichier: S2017["sujet2017.pdf"],
    //     },
    //     {
    //         nom: "CORRIGES 2017",
    //         fichier: S2017["corrigé2017.pdf"],
    //     },

    // ],
    // 2018: [
    //     {
    //         nom: "SUJETS 2018",
    //         fichier: S2018["sujet2018.pdf"],
    //     },
    //     {
    //         nom: "CORRIGES 2018",
    //         fichier: S2018["corrigé2018.pdf"],
    //     },

    // ],
    2019: [
        {
            nom: "SUJETS 2019",
            fichier: S2019["sujet2019.pdf"],
        },
        {
            nom: "CORRIGES 2019",
            fichier: S2019["corrigé2019.pdf"],
        },

    ],
    2022: [
        {
            nom: "ANGLAIS 2 ING",
            fichier: S2022["2022_Anglais2_S01_Epreuve.pdf"],
        },
        {
            nom: "CHIMIE 1 ING",
            fichier: S2022["2022_Chimie1_S03_Epreuve.pdf"],
            fichier: S2022["2022_Chimie2_S02_Chimie_Générale_Epreuve.pdf"]
        },
        {
            nom: "CHIMIE 2 ING",
            fichier: S2022["2022_Chimie2_S02_Chimie_Générale_Epreuve.pdf"],
        },
        {
            nom: "CULTURE ING",
            fichier: S2022["2022_Culture générale_203_Epreuve.pdf"],
        },
        {
            nom: "ECONOMIE ING",
            fichier: S2022["2022_Economie générale_S00_Epreuve.pdf"],
        },
        {
            nom: "GEOLOGIE ING",
            fichier: S2022["2022_Géologie1_S02_Epreuve.pdf"],
        },
        {
            nom: "INFORMATIQUE 1 ING",
            fichier: S2022["2022_Informatique1_S02_Epreuve.pdf"],
        },
        {
            nom: "INFORMATIQUE 2 ING",
            fichier: S2022["2022_Informatique2_S03_Epreuve.pdf"],
        },
        {
            nom: "MATHS 1 ING",
            fichier: S2022["2022_Mathématiques3_S01 -Annexe.pdf"],
        },
        {
            nom: "MATHS 2 ING",
            fichier: S2022["2022_Mathématiques3_S01_Epreuve.pdf"],
        },
        {
            nom: "PHYSIQUE 1 ING",
            fichier: S2022["2022_Physique1_S02_Epreuve.pdf"],
        },
        {
            nom: "SCIENCES IND. ING",
            fichier: S2022["2022_Sciences industrielles_S03_Epreuve.pdf"],
        },
        {
            nom: "ANGLAIS 2 ING",
            fichier: S2022B["2022_Anglais1_S02_Epreuve.pdf"],
        },
        {
            nom: "ANGLAIS 3 ING",
            fichier: S2022B["2022_Anglais2_S01_Epreuve.b8e25f9bb3a81f3326f1.pdf"],
        },
        {
            nom: "BIOLOGIE 2 ING",
            fichier: S2022B["2022_Biologie1_A2GP_S02_Epreuve.pdf"],
        },
        {
            nom: "CHIMIE 1 ING",
            fichier: S2022B["2022_Chimie2_S02_Chimie_Orga_Epreuve.pdf"],
        },
        {
            nom: "COMPTABILITE ING",
            fichier: S2022B["2022_Comptabilité_S00_Epreuve.pdf"],
        },
        {
            nom: "FRANçAIS ING",
            fichier: S2022B["2022_Français2_S02_Epreuve.pdf"],
        },
        {
            nom: "HIST GEO ING",
            fichier: S2022B["2022_HG et GMC_S02_Epreuve.pdf"],
        },
        {
            nom: "MATHS 3 ING",
            fichier: S2022B["2022_Mathématiques1_S01_Epreuve.pdf"],
        },
        {
            nom: "PHYSIQUE 2 ING",
            fichier: S2022B["2022_Physique2_S01_Epreuve.pdf"],
        },
        {
            nom: "PHYSIQUE 3 ING",
            fichier: S2022B["2022_PHYSIQUE3_S02_Epreuve.pdf"],
        },
        {
            nom: "SCIENCES IND 2 ING",
            fichier: S2022B["2022_Sciences industrielles_S01_Epreuve.pdf"],
        },
    ]
}